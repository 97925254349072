import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import * as reservationActions from './reservation'

const initialState = {
  error: null,
  loading: false,
  selectedCharterTypeId: '',
  data: []
}

const charterTypesSlice = createSlice({
  initialState,
  name: 'charterTypes',
  reducers: {
    QUERY_CHARTER_TYPES: (state, action) => {
      state.loading = true
      state.error = null
    },
    QUERY_CHARTER_TYPES_SUCCESS: (state, action) => {
      state.loading = false
      state.data = action.payload.data.filter(ct => !ct.hideOnExternalWeb)
    }
  }
})

export const { QUERY_CHARTER_TYPES, QUERY_CHARTER_TYPES_SUCCESS } = charterTypesSlice.actions

export default charterTypesSlice.reducer


// thunks
export const queryCharterTypes = travelType => {
  return (dispatch, getState) => {
    dispatch(QUERY_CHARTER_TYPES())
    let query = api.query('market/charterType/charterTypes', { travelType })
    query.then(res => {
      dispatch(QUERY_CHARTER_TYPES_SUCCESS(res))

      const state = getState()
      const search = state.reservation.search
      const market = state.market.market
      const visibleData = res.data.filter(x => !x.hideOnExternalWeb)[0]
      const charterTypeId = res.data.some(ct => ct.id === search.charterTypeId)
        ? search.charterTypeId
        : visibleData?.id || res.data[0].id

      dispatch(
        reservationActions.setSearchValue('charterTypeId', charterTypeId)
      )
      const epiServerId = res.data.find(
        ct => ct.id === charterTypeId
      ).epiServerId
      dispatch(
        reservationActions.queryQuikbook(market.id, travelType, epiServerId)
      )
    })
    return query
  }
}

export const setCharterType = charterTypeId => {
  return (dispatch, getState) => {
    const state = getState()
    const market = state.market.market
    dispatch(
      reservationActions.setSearch({
        ...state.reservation.search,
        charterTypeId: charterTypeId,
        airportId: '',
        destinationId: '',
        charterPeriodId: ''
      })
    )
    const epiServerId = state.charterTypes.data.find(
      ct => ct.id === charterTypeId
    ).epiServerId
    dispatch(
      reservationActions.queryQuikbook(
        market.id,
        state.reservation.search.travelType,
        epiServerId
      )
    )
  }
}
