import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import api from '../api'

const initialState = {
  error: null,
  loading: false,
  data: []
}

const travelersSlice = createSlice({
  name: 'travelers',
  initialState,
  reducers: {
    LOADING: state => {
      state.loading = true
    },
    QUERY_TRAVELERS_BY_ROOM: (state, action) => {
      action.payload.forEach(room => {
        room.travelers.forEach(traveler => {
          if (!traveler.genderCode) traveler.genderCode = 'M'
          if (!traveler.birthDate) {
            traveler.birthDate = ''
            traveler.year = ''
            traveler.month = ''
            traveler.day = ''
          } else {
            traveler.year = moment(traveler.birthDate).format('YYYY')
            traveler.month = moment(traveler.birthDate).format('MM')
            traveler.day = moment(traveler.birthDate).format('DD')
          }
        })
      })

      state.loading = false
      state.data = action.payload
    },
    QUERY_TRAVELERS: (state, action) => {
      action.payload.forEach(traveler => {
        if (!traveler.genderCode) traveler.genderCode = 'M'
        if (!traveler.birthDate) {
          traveler.year = ''
          traveler.month = ''
          traveler.day = ''
        } else {
          traveler.year = moment(traveler.birthDate).format('YYYY')
          traveler.month = moment(traveler.birthDate).format('MM')
          traveler.day = moment(traveler.birthDate).format('DD')
        }
      })

      state.loading = false
      state.data = action.payload
    },
    SET_ROOM_TRAVELER_VALUE: (state, action) => {
      state.data = state.data.map((room, i) =>
        i === action.payload.roomIndex
          ? {
              ...room,
              travelers: room.travelers.map((traveler, j) =>
                j === action.payload.travelerIndex
                  ? { ...traveler, [action.payload.name]: action.payload.value }
                  : traveler
              )
            }
          : room
      )
    },
    SET_TRAVELER_VALUE: (state, action) => {
      state.data = state.data.map((traveler, i) =>
        i === action.payload.travelerIndex
          ? { ...traveler, [action.payload.name]: action.payload.value }
          : traveler
      )
    }
  }
})

export const {
  LOADING,
  QUERY_TRAVELERS_BY_ROOM,
  QUERY_TRAVELERS,
  SET_ROOM_TRAVELER_VALUE,
  SET_TRAVELER_VALUE
} = travelersSlice.actions

export default travelersSlice.reducer

// thunks
export const queryTravelersByRoom = reservationId => {
  return dispatch => {
    dispatch(LOADING())
    return api
      .query('travelBooking/travelersByRooms', { reservationId })
      .then(res => dispatch(QUERY_TRAVELERS_BY_ROOM(res.data)))
  }
}

export const queryTravelers = reservationId => {
  return dispatch => {
    dispatch(LOADING())
    return api
      .query('travelBooking/travelers', { reservationId })
      .then(res => dispatch(QUERY_TRAVELERS(res.data)))
  }
}

export const setRoomTravelerValue = (value, name, travelerIndex, roomIndex) => {
  return dispatch => {
    dispatch(SET_ROOM_TRAVELER_VALUE({ value, name, travelerIndex, roomIndex }))
  }
}

export const setTravelerValue = (value, name, travelerIndex) => {
  return dispatch => {
    dispatch(SET_TRAVELER_VALUE({ value, name, travelerIndex }))
  }
}
