import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Select from '../../components/select'
import * as charterTypeActions from '../../store/ducks/charter-types'

const mapStateToProps = state => ({
  travelType: state.reservation.search.travelType,
  charterTypeId: state.reservation.search.charterTypeId,
  charterType: state.charterTypes
})

const mapDispatchToProps = dispatch => ({
  charterTypeActions: bindActionCreators(charterTypeActions, dispatch)
})

class CharterTypeSelect extends Component {
  componentDidMount () {
    this.props.charterTypeActions.queryCharterTypes(this.props.travelType)
  }

  renderDisabledSelect () {
    return <Select hideLabel label='Type' disabled value='' />
  }

  render () {
    if (this.props.charterType.loading) return this.renderDisabledSelect()

    return (
      <Select
        label='Type'
        hideLabel
        value={this.props.charterTypeId}
        onChange={this.props.charterTypeActions.setCharterType}
        cy-id='charter-select'
      >
        {this.props.charterType.data.map(ct => (
          <option key={ct.id} value={ct.id}>
            {ct.description}
          </option>
        ))}
      </Select>
    )
  }
}

CharterTypeSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CharterTypeSelect)
export default CharterTypeSelect
