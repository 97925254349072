import React, { Component } from 'react'
import moment from 'moment-timezone'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Switch from 'react-switch'
import { Row, Box } from 'jsxstyle'
import DestinationAddonDialog from './destination-addon-dialog'
import Button from '../../components/button'
import { Card, CardTitle, CardHeader } from '../../components/card'
import { List, ListItem } from '../../components/list'
import Currency from '../../containers/currency'
import Y from '../../y18n'
import * as addonsActions from '../../store/ducks/addons'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  addonsState: state.addons.items,
  market: state.market.market
})
const mapDispatchToProps = dispatch => ({
  addonsActions: bindActionCreators(addonsActions, dispatch)
})

class DestinationAddonList extends Component {
  state = { selected: {} }

  componentDidMount () {
    this.props.addonsActions.queryAddons(
      this.props.reservationId,
      this.props.type
    )
  }

  reQuery (shouldReload) {
    if (shouldReload) {
      this.props.addonsActions.queryAddons(
        this.props.reservationId,
        this.props.type
      )
    }
  }

  handleSelectAddon (
    addonType,
    value,
    articleId,
    travelerId,
    travelerIndex,
    generalId
  ) {
    const addons = this.props.addonsState.find(a => a.type === addonType)
    const shouldReload =
      addons &&
      addons.travelers.some(t =>
        t.articles
          .filter(a => a.generalId === generalId)
          .some(a => a.discountWithAdult > 0)
      )

    let reservationId = this.props.reservationId
    if (value) {
      // Remove existing articles, this should be done on server
      // becuase we should check if we are allowed to have multiple
      // addons per traveler or npt
      let traveler = this.props.addonsState
        .find(addon => addon.type === addonType)
        .travelers.find(x => x.id === travelerId)

      if (!this.props.multipleChoises) {
        let existingArticle = traveler.articles.find(x => x.reserved === true)
        if (existingArticle) {
          this.props.addonsActions.submitRemoveAddon({
            addonType,
            reservationId,
            articleId: existingArticle.articleId,
            travelerId
          })
        }
      }
      this.props.addonsActions
        .submitAddAddon({
          addonType,
          reservationId,
          articleId,
          travelerId,
          dontBlock: traveler.articles.every(a => !a.blocked)
        })
        .then(() => {
          if (travelerIndex !== 0) {
            this.reQuery(shouldReload)
          }
        })

      // add to other travelers if first traveler
      if (travelerIndex === 0) {
        const otherTravelers = this.props.addonsState
          .find(addon => addon.type === addonType)
          .travelers.filter(x => x.id !== travelerId)

        let doneCount = 0

        otherTravelers.forEach(t => {
          if (
            t.articles &&
            t.articles.length === 1 &&
            t.articles.find(x => !x.reserved)
          ) {
            let tArticleId = t.articles[0].articleId
            this.props.addonsActions
              .submitAddAddon({
                addonType,
                reservationId,
                articleId: tArticleId,
                travelerId: t.id,
                dontBlock: t.articles.every(a => !a.blocked)
              })
              .then(() => {
                doneCount++
                if (doneCount >= otherTravelers.length) {
                  this.reQuery(shouldReload)
                }
              })
          }
        })
      }
    } else {
      this.props.addonsActions
        .submitRemoveAddon({ addonType, reservationId, articleId, travelerId })
        .then(() => this.reQuery(shouldReload))
    }
  }

  isReserved (articles) {
    return articles.some(x => x.reserved)
  }

  renderButtonText (articles) {
    const firstSelected = articles.find(x => x.reserved)
    const totalSelected = articles.filter(x => x.reserved).length
    return firstSelected
      ? `${firstSelected.description}${
          totalSelected > 1 ? ` +${totalSelected - 1}` : ''
        }`
      : Y`Select`
  }

  render () {
    let addons = this.props.addonsState
    if (!addons.length) return null
    const currentLocale = moment.locale()

    return addons
      .filter(addon => addon.type === this.props.type)
      .map(addon => {
        let item = {}
        if (addon.type === 'SkiPass') {
          item.description = Y`SkiPass text`
          item.title = Y`SkiPass`
        } else if (addon.type === 'SkiRental') {
          item.description = Y`SkiRental text`
          item.title = Y`SkiRental`
        } else if (addon.type === 'SkiSchool') {
          item.description = Y`SkiSchool text`
          item.title = Y`SkiSchool`
        } else if (addon.type === 'Excursion') {
          item.description = Y`Excursions text`
          item.title = Y`Excursions`
        } else if (addon.type === 'Hiking') {
          item.description = Y`Hikes text`
          item.title = Y`Hikes`
        }
        return { ...addon, ...item }
      })
      .map((addon, i) => {
        return (
          <Card key={i} id={this.props.type}>
            <CardHeader>
              <CardTitle>{addon.title}</CardTitle>
              <p>{addon.description}</p>
            </CardHeader>
            <Row
              mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
              smFlexDirection='column'
            >
              <List className='no-odd border'>
                {addon.travelers.map((traveler, k) => (
                  <ListItem key={traveler.id}>
                    <Row justifyContent='space-between' alignItems='center'>
                      <Box whiteSpace='nowrap'>
                        <Row width='100%'>
                          <h3 className='nomargin'>
                            {/^\s+$/.test(traveler.name)
                              ? traveler.age !== null && traveler.age < 18
                                ? `${Y`Child`} ${traveler.age} ${Y`year`}`
                                : Y`Adult` + ' ' + traveler.id
                              : traveler.name}
                          </h3>
                          <p style={{ margin: 0 }}>
                            {traveler.articles.length === 1 ||
                            this.isReserved(traveler.articles)
                              ? ' - '
                              : ''}
                            {traveler.articles.length === 1 ? (
                              <React.Fragment>
                                {traveler.articles[0].priceAmount === 0 ? (
                                  Y`Included`
                                ) : (
                                  <Currency>
                                    {traveler.articles[0].priceAmount}
                                  </Currency>
                                )}
                                {traveler.articles[0].depositFee ? (
                                  <span className='caption'>
                                    {' '}
                                    ({`${Y`Including`} ${Y`deposit`}`}{' '}
                                    <Currency>
                                      {traveler.articles[0].depositFee}
                                    </Currency>
                                    )
                                  </span>
                                ) : (
                                  ''
                                )}
                              </React.Fragment>
                            ) : this.isReserved(traveler.articles) ? (
                              traveler.articles
                                .filter(x => x.reserved)
                                .map(x => x.priceAmount)
                                .reduce((a, b) => a + b) === 0 ? (
                                Y`Included`
                              ) : (
                                <Currency>
                                  {traveler.articles
                                    .filter(x => x.reserved)
                                    .map(x => x.priceAmount)
                                    .reduce((a, b) => a + b)}
                                </Currency>
                              )
                            ) : (
                              ''
                            )}
                          </p>
                        </Row>
                        {traveler.articles.length === 1 && (
                          <p className='caption'>
                            {traveler.articles[0].description} -{' '}
                            {`${moment
                              .parseZone(traveler.articles[0].date)
                              .format('dddd')} ${
                              traveler.articles[0].numberOfDays > 1
                                ? ` ${Y`to`} ${
                                    traveler.articles[0].endDayOfWeek
                                      ? moment()
                                          .locale('en')
                                          .day(
                                            traveler.articles[0].endDayOfWeek
                                          )
                                          .locale(currentLocale)
                                          .format('dddd')
                                      : moment
                                          .parseZone(traveler.articles[0].date)
                                          .add(
                                            traveler.articles[0].numberOfDays -
                                              1,
                                            'days'
                                          )
                                          .format('dddd')
                                  }  (${
                                    traveler.articles[0].numberOfDays
                                  } ${Y`days`})`
                                : ''
                            }`}
                          </p>
                        )}
                      </Box>

                      {traveler.articles.length > 1 ? (
                        <div className='text-right'>
                          <Button
                            key={k}
                            small
                            link={this.isReserved(traveler.articles)}
                            nomargin
                            style={{
                              color: this.isReserved(traveler.articles)
                                ? '#47a404'
                                : 'rgb(8, 154, 208)',
                              borderColor: 'rgb(8, 154, 208)'
                            }}
                            onClick={() =>
                              this.setState({
                                ...this.state,
                                showDialog: `${addon.type}_${traveler.id}`
                              })
                            }
                          >
                            {this.renderButtonText(traveler.articles)} »
                          </Button>
                          {this.isReserved(traveler.articles) && (
                            <p className='caption'>
                              {traveler.articles.find(x => x.reserved).info}
                            </p>
                          )}
                          {this.state.showDialog ===
                            `${addon.type}_${traveler.id}` && (
                            <DestinationAddonDialog
                              key={k + 'd'}
                              multipleChoises={this.props.multipleChoises}
                              title={addon.title}
                              type={addon.type}
                              languageId={this.props.market.defaultLanguageId}
                              onClose={() =>
                                this.setState({
                                  ...this.state,
                                  showDialog: null
                                })
                              }
                              traveler={traveler}
                              onSelect={(value, articleId, generalId) => {
                                this.handleSelectAddon(
                                  addon.type,
                                  value,
                                  articleId,
                                  traveler.id,
                                  generalId
                                )
                              }}
                            />
                          )}
                        </div>
                      ) : (
                        <Switch
                          disabled={
                            traveler.articles[0].inPackage ||
                            traveler.articles[0].blocked
                          }
                          uncheckedIcon={false}
                          checkedIcon={false}
                          offColor='#b4b4b4'
                          onChange={value =>
                            this.handleSelectAddon(
                              addon.type,
                              value,
                              traveler.articles[0].articleId,
                              traveler.id,
                              k,
                              traveler.articles[0].generalId
                            )
                          }
                          checked={traveler.articles[0].reserved}
                          onColor='#47a404'
                          id='normal-switch'
                          height={22}
                          width={48}
                          boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                          activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                        />
                      )}
                    </Row>
                  </ListItem>
                ))}
              </List>
            </Row>
          </Card>
        )
      })
  }
}

DestinationAddonList = connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinationAddonList)

export default DestinationAddonList
