import React from 'react'
import { Box, Row } from 'jsxstyle'
import Y from '../../../y18n'
import './hotel-info.css'

const HotelInfo = ({ hotel, flight }) => (
  <Row justifyContent='space-between' className='hinfo'>
    <Box
      width='50%'
      lgWidth='33%'
      mediaQueries={{ lg: 'screen and (min-width:992px)' }}
    >
      {hotel.distanceToLift !== null ? (
        <Row justifyContent='space-between'>
          <span className='caption'>{Y`Lift`}</span>
          <span className='caption'>{`${hotel.distanceToLift} m`}</span>
        </Row>
      ) : null}
      {hotel.distanceToTownCentre !== null ? (
        <Row justifyContent='space-between'>
          <span className='caption'>{Y`Centre`}</span>
          <span className='caption'>{`${hotel.distanceToTownCentre} m`}</span>
        </Row>
      ) : null}
      {hotel.distanceToSkibus !== null ? (
        <Row justifyContent='space-between'>
          <span className='caption'>{Y`Skibus`}</span>
          <span className='caption'>{`${hotel.distanceToSkibus} m`}</span>
        </Row>
      ) : null}
    </Box>
  </Row>
)

export default HotelInfo
