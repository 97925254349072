import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'
import SelectRoomButton from '../select-room-button'
import './hotel-from-price.css'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelFromPrice extends PureComponent {
  handleGoToRooms () {
    document.querySelector('#rooms').scrollIntoView(true)
    let scrolledY = window.scrollY
    if (scrolledY) {
      window.scroll(0, scrolledY - 120)
    }
  }

  render () {
    const { proposal } = this.props
    if (!proposal) return null

    return [
      <div key={1} className='hfromprice'>
        <p>
          {Y`From`}
          {' '}
          <span className={`price ${proposal.ordinaryPrice !== proposal.price ? 'warn' : 'green'} nomargin`}>
            <Currency>{proposal.price}</Currency>
          </span>
        </p>
        <p>{Y`Including`} {proposal.package.shortInfo}</p>
      </div>,
      <SelectRoomButton key={2} />
    ]
  }
}

HotelFromPrice = connect(mapStateToProps)(HotelFromPrice)
export default HotelFromPrice
