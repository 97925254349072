import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class ScrollToHash extends Component {
  hashFragment = ''
  observer = null
  asyncTimerId = null
  headerSize = 78

  componentDidMount () {
    this.headerSize = document.querySelector('.summary.sticky').scrollHeight

    this.hashFragment = this.props.location.hash
    if (this.hashFragment) {
      this.hashLinkScroll()
    }
  }

  componentDidUpdate (prevProps) {
    this.hashFragment = this.props.location.hash
    if (this.hashFragment && this.hashFragment !== prevProps.location.hash) {
      this.hashLinkScroll()
    }
  }

  componentWillUnmount () {
    this.reset()
  }

  hashLinkScroll () {
    window.setTimeout(() => {
      if (this.getElAndScroll() === false) {
        if (this.observer === null) {
          this.observer = new MutationObserver(this.getElAndScroll)
        }
        this.observer.observe(document, {
          attributes: true,
          childList: true,
          subtree: true
        })

        // if the element doesn't show up in 8 seconds, stop checking
        this.asyncTimerId = window.setTimeout(() => {
          this.reset()
        }, 8000)
      }
    }, 0)
  }

  getElAndScroll = () => {
    const element = document.querySelector(this.hashFragment)
    if (element !== null) {
      element.scrollIntoView()
      let scrolledY = window.scrollY

      if (scrolledY) {
        window.scroll(0, scrolledY - this.headerSize)
      }
      return true
    }
    return false
  }

  reset () {
    if (this.observer !== null) this.observer.disconnect()
    if (this.asyncTimerId !== null) {
      window.clearTimeout(this.asyncTimerId)
      this.asyncTimerId = null
    }
  }

  render () {
    return this.props.children
  }
}

export default withRouter(ScrollToHash)
