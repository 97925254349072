import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import './button.css'

const Button = ({
  primary = false,
  secondary = false,
  link = false,
  small = false,
  iconLeft = false,
  iconRight = false,
  fullWidth = false,
  to,
  noBorder = false,
  children,
  nomargin,
  className = '',
  staticContext,
  ...rest
}) => {
  if (to) {
    return (
      <Link
        className={`btn${primary ? ' primary' : ''}${small ? ' small' : ''}${
          noBorder ? ' noborder' : ''
        }${fullWidth ? ' fullwidth' : ''}${link ? ' link' : ''}${
          secondary ? ' secondary' : ''
        }${iconLeft ? ' icon-left' : ''}${iconRight ? ' icon-right' : ''}${
          nomargin ? ' nomargin' : ''
        } ${className}`}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={`btn${primary ? ' primary' : ''}${small ? ' small' : ''}${
          fullWidth ? ' fullwidth' : ''
        }${link ? ' link' : ''}${secondary ? ' secondary' : ''}${
          iconLeft ? ' icon-left' : ''
        }${iconRight ? ' icon-right' : ''}${
          nomargin ? ' nomargin' : ''
        } ${className}`}
        {...rest}
      >
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  link: PropTypes.bool,
  small: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  nomargin: PropTypes.bool,
  className: PropTypes.string
}

export default Button
