import React from 'react'
import ReactDOM from 'react-dom'
import Button from '../../components/button'
import Y from '../../y18n'

const MyTripHeader = ({ linkTo, onClick }) => {

  if (!linkTo) {
    return false
  }
  const element = (
      <Button
        onClick={onClick}
        style={{ textDecoration: 'none' }}
        link
        to={linkTo}
      >
        {Y`Back`} {Y`to`} {Y`My trip`} {'>>'}
      </Button>
    )
  return ReactDOM.createPortal(element, document.querySelector('.header-container'))
}

export default MyTripHeader
