import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Page, Layout, Content } from '../../components/layout'
// import NextButton from '../../containers/next-button'
import ExcursionList from './excursion-list'
import CancellationInsuranceList from './cancellation-insurance-list'
import TravelInsuranceList from './travel-insurance-list'
import FullRefundInsuranceList from './full-refund-insurance-list/full-refund-insurance-list'
import Summary from '../../components/summary'
import ShortReservationSummary from '../../containers/short-reservation-summary'
import Progress from '../../containers/progress'
import ScrollToHash from '../../components/scroll-to-hash'
import { setRedirectUrl } from '../../components/redirect-service'
import withReservationId from '../../containers/with-reservation-id'
import MyTripHeader from '../../containers/my-trip-header'
import NextButton from './next-button'
import Y from '../../y18n'
import './addons-page.css'
import HalfboardList from './halfboard-list'

const mapStateToProps = state => ({
  summary: state.reservation.summary,
  reservationId: state.reservation.reservationId
})

class AddonsPage extends Component {
  componentDidMount () {
    setRedirectUrl(this.props.location.search)
  }

  handleBackClick = () => {
    this.props.history.push(`/travelers/${this.props.reservationId}`)
  }

  handleNextClick = () => {
    this.props.history.push(`/confirm/${this.props.reservationId}`)
  }

  render () {
    const { summary } = this.props

    const editMode =
      summary &&
      (summary.status === 'ConfirmedWithChanges' ||
        summary.status === 'Confirmed')
    return (
      <ScrollToHash>
        <Page>
          <Layout>
            <Summary sticky>
              <ShortReservationSummary />
            </Summary>
            <Content>
              {editMode ? (
                <MyTripHeader
                  onClick={e => {
                    if (summary.status === 'ConfirmedWithChanges') {
                      e.preventDefault()
                      window.alert(
                        Y`Please confirm or delete your selected extensions before going back to 'My trip'`
                      )
                    }
                  }}
                  linkTo={`/reservation/${summary.id}`}
                />
              ) : (
                <Progress active={2} />
              )}
              <div className='addons'>
                <ExcursionList />
                <CancellationInsuranceList />
                <FullRefundInsuranceList />
                <TravelInsuranceList />
                {editMode ? (
                  <HalfboardList reservationId={this.props.reservationId} />
                ) : (
                  false
                )}
                <NextButton />
              </div>
            </Content>
          </Layout>
        </Page>
      </ScrollToHash>
    )
  }
}

AddonsPage = withReservationId(AddonsPage)
export default connect(mapStateToProps)(AddonsPage)
