import React, { useEffect } from 'react'

import { Card, CardHeader, CardTitle } from '../../../components/card'
import { useQuery, useCommand } from '../../../store/api'
import Y from '../../../y18n'
import { List, ListItem } from '../../../components/list'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { queryReservationSummary } from '../../../store/ducks/reservation'
import { useDispatch } from 'react-redux'
import isAdult from '../../../utils/is-adult'

const HalfboardList = ({ reservationId }) => {
  const dispatch = useDispatch()
  const [{ data: halfboardTravelers }, fetchHalfboard] = useQuery({
    path: `travelbooking/halfboardPerTraveler`,
    initParams: { reservationId: reservationId },
    manual: true
  })

  const [, sendAddHalfboardCommand] = useCommand('travelBooking/addHalfboard')
  const [, sendRemoveHalfboardCommand] = useCommand(
    'travelBooking/removeHalfboard'
  )

  useEffect(() => {
    if (reservationId) {
      fetchHalfboard()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId])

  const handleHalfboardToggle = async (value, travelerId, index) => {
    if (index === 0) {
      if (value) {
        const promises = []
        halfboardTravelers
          .filter(t => !t.halfboard.reserved)
          .forEach(traveler => {
            promises.push(
              sendAddHalfboardCommand({
                reservationId,
                travelerId: traveler.id
              })
            )
          })
        await Promise.all(promises)
      } else {
        await sendRemoveHalfboardCommand({
          reservationId,
          travelerId
        })
      }
    } else {
      if (value) {
        await sendAddHalfboardCommand({
          reservationId,
          travelerId
        })
      } else {
        await sendRemoveHalfboardCommand({
          reservationId,
          travelerId
        })
      }
    }

    await fetchHalfboard()
    dispatch(queryReservationSummary(reservationId))
  }

  return halfboardTravelers && halfboardTravelers.length > 0 ? (
    <Card id='Halfboard'>
      <CardHeader>
        <CardTitle>{Y`Halfboard`}</CardTitle>
      </CardHeader>
      <List className='no-odd border'>
        {halfboardTravelers.map((traveler, index) => (
          <ListItem key={traveler.id}>
            <Row justifyContent='space-between' alignItems='center'>
              <div>
                <Row width='100%'>
                  <h3 className='nomargin'>
                    {/^\s+$/.test(traveler.name)
                      ? isAdult(traveler.age)
                        ? Y`Adult` + ' ' + traveler.id
                        : Y`Child` + ' ' + traveler.id
                      : traveler.name}
                  </h3>
                </Row>
                <Row>
                  <p className='caption'>{traveler.halfboard.info}</p>
                </Row>
              </div>
              <Switch
                disabled={
                  traveler.halfboard.blocked || traveler.halfboard.mandatory
                }
                uncheckedIcon={false}
                checkedIcon={false}
                offColor='#b4b4b4'
                onChange={value =>
                  handleHalfboardToggle(value, traveler.id, index)
                }
                checked={traveler.halfboard.reserved}
              ></Switch>
            </Row>
          </ListItem>
        ))}
      </List>
    </Card>
  ) : (
    false
  )
}

export default HalfboardList
