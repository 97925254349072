import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  market: state.market.market
})

class Currency extends Component {
  state = {}

  render () {
    const { market } = this.props

    let currencyFormat = new Intl.NumberFormat(market.culture, {
      style: 'currency',
      currency: market.currencyId,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    return currencyFormat.format(this.props.children)
  }
}

Currency.propTypes = {
  children: PropTypes.number
}

Currency = connect(mapStateToProps)(Currency)
export default Currency
