import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Box, Col } from 'jsxstyle'
import Currency from '../../../containers/currency'
import Button from '../../../components/button'
import BackButton from '../../../containers/back-button'
import ReservationSummaryDialog from '../../../containers/reservation-summary-dialog'
import Y from '../../../y18n'
import './price-and-next.css'

const smallWidth = { sm: 'screen and (max-width: 992px)' }

const mapStateToProps = state => ({
  reservation: state.reservation.summary,
  proposal: state.roomProposal.item
})

class PriceAndNext extends PureComponent {
  render () {
    const { reservation, proposal } = this.props

    if (!reservation || !proposal) return null

    const summaryElement = document.querySelector('.summary')

    return (
      <Row
        marginTop='.9375rem'
        alignItems='center'
        justifyContent='space-between'
        smJustifyContent='center'
        smFlexDirection='column'
        mediaQueries={smallWidth}
        position='relative'
        className='price-next'
      >
        <BackButton className='back' />

        {/* Used for scrolling down to when selecting a room */}
        <div
          id='next'
          style={{
            width: 0,
            height: 0,
            position: 'absolute',
            top: `-${summaryElement ? summaryElement.offsetHeight + 10 : 88}px`
          }}
        />
        <Col mediaQueries={smallWidth} smFlexDirection='column' smAlignItems='center' textAlign='center'>
          <Box>
            <Box component='p' className='nomargin'>
              {Y`Including`} {reservation.packages[0].shortInfo}
            </Box>
            <Box component='h3' className='nomargin' smDisplay='none' mediaQueries={smallWidth}>
              {Y`Total price`}{' '}
              <span className='price green'>
                <Currency>{reservation.price}</Currency>
              </span>
            </Box>
          </Box>
          <Box flex='1' smOrder={2} mediaQueries={smallWidth} smMarginLeft='0'>
            <ReservationSummaryDialog />
          </Box>
        </Col>

        <Button cy-id='select-room' to={`/travelers/${reservation.id}`} primary className='next'>
          {Y`Go ahead and book`} »
        </Button>
      </Row>
    )
  }
}

PriceAndNext = connect(mapStateToProps)(PriceAndNext)
export default PriceAndNext
