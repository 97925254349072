import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'jsxstyle'
import './info.css'

const Info = ({ label, caption, children, border = false, responsive = false }) => {
  if (typeof children === 'string') {
    return (
      <Col
        className={`info text${responsive ? ' resp' : ''}`}
        alignItems='center'
        borderRight={border ? '1px solid #ebebeb' : ''}
      >
        <h5>{label}</h5>
        <h4>{children}</h4>
        <p>{caption}</p>
      </Col>
    )
  } else {
    return (
      <Col className='info' alignItems='center'>
        <h5>{label}</h5>
        {children}
        <p>{caption}</p>
      </Col>
    )
  }
}

Info.propTypes = {
  children: PropTypes.node.isRequired
}

export default Info
