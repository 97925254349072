import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { Row, Box } from 'jsxstyle'
import { Icon } from '../../../components/icon'
import Button from '../../../components/button'
import { withRouter } from 'react-router-dom'
import FlightCard from '../flight-card'
import Y from '../../../y18n'

const size = 992

const mapStateToProps = state => ({
  loading: state.proposal.loading,
  altPeriodLoading: state.proposal.altPeriodLoading,
  period: state.proposal.period,
  reservation: state.reservation,
  proposals: state.proposal.period.proposals,
  soldOutDate: state.proposal.soldOutDate,
  alternativePeriods: state.proposal.alternativePeriods
})

class HotelList extends Component {
  componentDidUpdate (prevProps, prevState) {
    // if we dont get any result, try to show for another period instead
    const length = window.innerWidth > size ? 3 : 2
    const outDate = this.props.charterPeriodId.split(';')[0]

    if (!this.props.soldOutDate && !this.props.loading && this.props.proposals.length === 0) {
      if (!this.props.altPeriodLoading && Object.keys(this.props.alternativePeriods).length >= length) {
        let alternative = null
        for (let key in this.props.alternativePeriods) {
          let period = this.props.alternativePeriods[key]
          if (period.proposals.length > 0) {
            if (!alternative) {
              alternative = period
            } else {
              let diff = Math.abs(moment(outDate).diff(moment(period.out), 'days'))
              if (diff <= Math.abs(moment(outDate).diff(moment(alternative.out), 'days'))) {
                alternative = period
              }
            }
          }
        }
        if (alternative) {
          this.props.onSoldOut(alternative.out, alternative.home, outDate)
        }
      }
    }
  }

  render () {
    if (!this.props.proposals) return null

    return (
      <div className='hotel-list'>
        {this.props.soldOutDate && (
          <Row className='yellow-bg' padding='.9375rem' margin='.9375rem 0'>
            <Icon style={{ marginRight: '.9375rem' }}>error</Icon>
            <Box>
              <Box component='h5' margin='0'>
                {Y`Sorry, we did not find any travels`} {moment(this.props.soldOutDate).format('dddd D MMM')}
              </Box>
              <p className='nomargin'>
                {Y`Below are available trips on the nearest date instead`},{' '}
                {moment(this.props.period.out).format('dddd D MMM')}.{' '}
                <Button link onClick={() => this.props.onNewSearch()}>
                  {Y`Change`} {Y`searching`}
                </Button>
              </p>
            </Box>
          </Row>
        )}

        {this.props.proposals.map((proposal, i) => (
          <FlightCard key={i} proposal={proposal} />
        ))}
        {!this.props.proposals.length && (
          <p>{Y`No proposals found. Try changing filtering or searching for another date`}</p>
        )}
      </div>
    )
  }
}

HotelList = connect(mapStateToProps)(HotelList)
export default withRouter(HotelList)
