import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './summary.css'

class Summary extends Component {
  render () {
    return (
      <div
        className={`summary${this.props.sticky ? ' sticky' : ''}`}
        style={
          this.props.backgroundImage
            ? {
              background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${this.props.backgroundImage})`,
              backgroundSize: 'cover'
            }
            : { background: 'linear-gradient(to top, #FFEC78, #FFDC0D)', color: '#333' }
        }
      >
        <div className='summary-content'>
          {this.props.children}
        </div>
      </div>
    )
  }
}

Summary.propTypes = {
  sticky: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  backgroundImage: PropTypes.string
}

export default Summary
