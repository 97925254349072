import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Box, Row } from 'jsxstyle'
import { CardContent } from '../../../components/card'
import { Dialog, DialogContent } from '../../../components/dialog'
import Select from '../../../components/select'
import Button from '../../../components/button'
import Y from '../../../y18n'
import './flight-travelers-dialog.css'
import * as reservationActions from '../../../store/ducks/reservation'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  flightTravelers: state.reservation.search.flightTravelers
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class FlightTravelersDialog extends Component {
  handleSelectTraveler = cc => number => {
    number = parseInt(number, 10)
    let flightTravelers = this.props.flightTravelers
    let travelersCount = flightTravelers.filter(ft => (cc.code === 'ADULT' ? isAdult(ft.age) :!isAdult(ft.age))).length
    let nextId = Math.max(...flightTravelers.map(ft => parseInt(ft.id, 10))) + 1

    if (number > travelersCount) {
      Array.from(Array(number - travelersCount)).forEach(traveler => {
        flightTravelers.push({
          id: nextId++,
          age: cc.code === 'ADULT' ? null : 0
        })
      })
    } else {
      let otherCategoryTravelers = flightTravelers.filter(ft =>
        cc.code === 'ADULT' ? !isAdult(ft.age) : isAdult(ft.age)
      )
      let selectedCategoryTravelers = flightTravelers.filter(ft =>
        cc.code === 'ADULT' ? isAdult(ft.age) : !isAdult(ft.age)
      )
      selectedCategoryTravelers.length = number
      flightTravelers = otherCategoryTravelers.concat(selectedCategoryTravelers)
    }
    this.props.reservationActions.setSearchValue('flightTravelers', [...flightTravelers])
  }

  handleSelectAge = traveler => age => {
    let flightTravelers = this.props.flightTravelers
    flightTravelers[traveler.travelerIndex].age = age
    this.props.reservationActions.setSearchValue('flightTravelers', [...flightTravelers])
  }

  handleSubmit = e => {
    e.preventDefault()
    this.handleClose(true)
  }

  handleClose = isSubmit => {
    if (this.props.flightTravelers.filter(t => isAdult(t.age)).length === 0) {
      alert(
        `${Y`Enter at least one adult traveler`}.\n\n${Y`Would you like to book a trip without adult travelers? Call us on ${Y`AlpInfoPhone`} and we will help you`}.`
      )
    } else {
      this.props.onClose(isSubmit)
    }
  }

  renderAgeSelect (customerCategory, traveler, travelerIndex) {
    if (customerCategory.insertAge) {
      return (
        <Select
          key={travelerIndex}
          required
          style={{ width: 'auto' }}
          label=''
          value={traveler.age}
          onChange={this.handleSelectAge({ traveler, travelerIndex })}
        >
          {customerCategory.yearOptions.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      )
    }
    return null
  }

  render () {
    const { show, customerCategories, flightTravelers } = this.props

    return show ? (
      <Dialog className='cc-dialog' show={show} onClose={this.handleClose} title=''>
        <DialogContent>
          <CardContent>
            <Row className='flex-margin'>
              {customerCategories.map((cc, i) => {
                let options = Array.from(Array(cc.max)).map((e, i) => i + cc.min)

                return (
                  <Box key={cc.code} width='100%'>
                    <Select
                      value={
                        flightTravelers.filter(t => (cc.code === 'CHILD' ? !isAdult(t.age) : isAdult(t.age))).length
                      }
                      label={cc.code === 'CHILD' ? Y`Child/Youth (0-17 years)` : cc.description}
                      onChange={this.handleSelectTraveler({ code: cc.code, roomIndex: i })}
                    >
                      {options.map(option => (
                        <option key={option} value={option}>
                          {option} {cc.description}
                        </option>
                      ))}
                    </Select>

                    {flightTravelers.filter(t => t.age !== null).length > 0 && i === 1 && (
                      <Row className='select-wrapper'>
                        <label className='age-label'>{Y`Age (at time of return travel)`}</label>
                      </Row>
                    )}

                    <Row className='flex-margin' flexWrap='wrap'>
                      {flightTravelers.map((t, j) => {
                        return t.age !== null ? this.renderAgeSelect(cc, t, j) : null
                      })}
                    </Row>
                  </Box>
                )
              })}
            </Row>
            <Button onClick={this.handleSubmit} primary style={{ width: '100%' }}>
              {Y`Search trip`}
            </Button>
          </CardContent>
        </DialogContent>
      </Dialog>
    ) : null
  }
}

FlightTravelersDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(FlightTravelersDialog)
export default FlightTravelersDialog
