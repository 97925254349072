import React from 'react'
import { connect } from 'react-redux'
import { Row } from 'jsxstyle'
import Y from '../../../y18n'

const mapStateToProps = state => ({
  facilities: state.roomProposal.facilities
})

const HotelWifi = ({ facilities }) => {
  if (!facilities || !facilities.find(f => f.id === 'WIFI')) return null
  return (
    <Row alignItems='center' justifyContent='center'>
      <img style={{ marginRight: '0.4687rem' }} height='30px' src='/img/wifi.svg' alt='wifi' />
      <p style={{ fontWeight: 'bold' }}>{Y`Free Wi-Fi`}</p>
    </Row>
  )
}

const Connect = connect(mapStateToProps)(HotelWifi)
export default Connect
