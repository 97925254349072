import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row } from 'jsxstyle'
import Input from '../../components/input'
import Y from '../../y18n'
import { Icon } from '../../components/icon'
import * as reservationActions from '../../store/ducks/reservation'

const mapStateToProps = state => ({
  offerCode: state.reservation.search.offerCode
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class OfferCodeInput extends Component {
  state = { showInput: this.props.offerCode }

  handleToggleShowInput () {
    if (this.state.showInput) {
      this.props.reservationActions.setSearchValue('offerCode', '')
    }
    this.setState({ ...this.state, showInput: !this.state.showInput })
  }

  render () {
    const { offerCode } = this.props

    return (
      <Row className='offer-code' width='100%' marginTop={1}>
        <button style={{ padding: 0, marginTop: 0 }} type='button' onClick={() => this.handleToggleShowInput()}>
          {Y`Offer code`}: <Icon>chevron_right</Icon>
        </button>
        {this.state.showInput && (
          <Input
            value={offerCode || ''}
            onChange={val => this.props.reservationActions.setSearchValue('offerCode', val)}
            autoFocus
            style={{ marginTop: 0, marginLeft: '.9375rem', cursor: 'default', maxWidth: '160px' }}
          />
        )}
      </Row>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfferCodeInput)
