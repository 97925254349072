import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Box } from 'jsxstyle'
import * as Sentry from '@sentry/browser'
import Button from '../../components/button'
import * as customerActions from '../../store/ducks/customer'

const mapStateToProps = state => ({
  customer: state.customer.details
})

const mapDispatchToProps = dispatch => ({
  customerActions: bindActionCreators(customerActions, dispatch)
})

class UserDetails extends Component {
  componentDidMount () {
    if (this.props.customer) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: this.props.customer.id, type: 'agent' })
      })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.customer) {
      Sentry.configureScope(scope => {
        scope.setUser({ id: nextProps.customer.id, type: 'agent' })
      })
    }
  }

  render () {
    const { customer } = this.props
    if (!customer) return null

    return ReactDOM.createPortal(
      <Box textAlign='right'>
        <a
          href='/mypage'
          style={{
            color: '#000',
            display: 'flex',
            flexDirection: 'column',
            margin: 'initial',
            textDecoration: 'none'
          }}
        >
          <span className='nomargin' style={{ fontWeight: 'bold' }}>
            {customer.name}
          </span>
          <span className='nomargin caption'>{customer.agent}</span>
        </a>
        <Button
          onClick={() => {
            this.props.customerActions.submitLogout()
            window.location.href = '/mypage'
          }}
          style={{ fontSize: '12px', marginTop: 0 }}
          link
        >
          Log out
        </Button>
      </Box>,
      document.querySelector('#header')
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails)
