import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Button from '../../../components/button'
import Y from '../../../y18n'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class SelectRoomButton extends PureComponent {
  handleGoToRooms () {
    document.querySelector('#rooms').scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render () {
    const { proposal } = this.props

    return (
      <Button
        disabled={!proposal}
        onClick={() => this.handleGoToRooms()}
        style={{ marginTop: 0, marginBottom: 0 }}
        primary
        fullWidth
      >
        {Y`Choose room`}
      </Button>
    )
  }
}

SelectRoomButton = connect(mapStateToProps)(SelectRoomButton)
export default SelectRoomButton
