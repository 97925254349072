import React from 'react'
import PropTypes from 'prop-types'
import './card-subtitle.css'

export const CardSubtitle = ({ children, ...rest }) => {
  return (
    <p className='card-subtitle' {...rest}>
      {children}
    </p>
  )
}

CardSubtitle.propTypes = {
  children: PropTypes.node.isRequired
}

export default CardSubtitle
