import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Box } from 'jsxstyle'
import Button from '../../../components/button'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import './price-info.css'
import { Icon } from '../../../components/icon'

class PriceInfo extends Component {
  renderPriceDescription (pricePerPerson, rooms) {
    if (pricePerPerson) {
      return (
        <React.Fragment>
          <Currency>{pricePerPerson}</Currency> /{Y`person`}
        </React.Fragment>
      )
    } else {
      const beds = rooms.map(r => r.bedsWithTraveler)
      const travelers = [].concat(...beds)

      if (travelers.some(t => t.travelerAge < 40)) {
        return (
          <React.Fragment>
            {Y`Family price`} {Y`from`}:
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            {Y`Total price`} {Y`from`}:
          </React.Fragment>
        )
      }
    }
  }

  render () {
    const { price, pckage, ordinaryPrice, pricePerPerson, rooms, onSelect } =
      this.props

    return (
      <Box
        className='grey-bg hprice'
        padding='.4687rem .9375rem .4687rem .9375rem'
      >
        <Row alignItems='center'>
          <Box flex='1' className='pckge'>
            <Box
              component='p'
              className='subtitle nomargin'
              mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
              smTextAlign='right'
              smPaddingRight='20px'
              color='rgb(175, 175, 175)'
            >
              {this.renderPriceDescription(pricePerPerson, rooms)}
            </Box>

            <Row
              alignItems='center'
              justifyContent='flex-end'
              mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
              lgJustifyContent='flex-start'
            >
              {ordinaryPrice !== price && (
                <Box
                  component='p'
                  margin='0 .4687rem'
                  className='old-price'
                  order={2}
                  smOrder={1}
                  smPaddingRight='20px'
                  mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
                >
                  <Currency>{ordinaryPrice}</Currency>
                </Box>
              )}
              <Box
                order={1}
                smOrder={2}
                component='p'
                mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
                smTextAlign='right'
                smPaddingRight='20px'
                className={`price ${
                  ordinaryPrice !== price ? 'warn' : 'green'
                } nomargin`}
              >
                <Currency>{price}</Currency>
              </Box>
            </Row>
          </Box>
          <Button
            cy-id='select-hotel-button'
            style={{ marginBottom: '-21px' }}
            primary
            disabled={pckage.forbidden}
            onClick={onSelect}
          >
            {Y`Select`} »
          </Button>
        </Row>
        <Box
          component='p'
          mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
          smTextAlign='right'
          smPaddingRight='20px'
          className='caption nomargin'
          fontWeight='700'
          color='rgb(0,0,0,0.65)'
        >
          {pckage.shortInfo}
        </Box>
        {pckage.forbidden ? (
          <Box
            component='p'
            mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
            smTextAlign='right'
            smPaddingRight='20px'
            className='warn nomargin'
            fontWeight='700'
            color='rgb(0,0,0,0.65)'
            display='flex'
          >
            <Icon size='18px' color='orange' className='warning-icon'>
              warning
            </Icon>
            {Y`This package is not allowed for children under the age of ${12}`}
          </Box>
        ) : null}
      </Box>
    )
  }
}

PriceInfo.propTypes = {
  price: PropTypes.number.isRequired,
  pckage: PropTypes.object,
  ordinaryPrice: PropTypes.number,
  rooms: PropTypes.array,
  pricePerPerson: PropTypes.number,
  flight: PropTypes.object,
  onSelect: PropTypes.func.isRequired
}

export default PriceInfo
