import React, { Component } from 'react'
import { connect } from 'react-redux'
import Slider from 'rc-slider'
import Select from '../../../components/select'
import Y from '../../../y18n'
import { IconButton } from '../../../components/icon'
import Button from '../../../components/button'
import { Row, Box, InlineBlock } from 'jsxstyle'
import Sidenav from '../../../components/sidenav'
import { CardContent } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import CheckBox from '../../../components/checkbox'
import Stars from '../../../components/stars'
import './hotel-filter-sort.css'
import Currency from '../../../containers/currency'

const starColor = 'rgb(255, 220, 13)'
const maxStars = 5

const mapStateToProps = state => ({
  period: state.proposal.period,
  market: state.market.market
})

const smallWidth = { sm: 'screen and (max-width:992px)' }

const initialFilterState = {
  stars: [],
  guestRatingFrom: 1,
  guestRatingTo: 5,
  distanceToLiftTo: 5000,
  distanceToSkiBusTo: 5000,
  distanceToTownCenterTo: 5000,
  facilities: [],
  priceTo: null
}

class HotelFilterSort extends Component {
  state = {
    show: false,
    sort: sessionStorage.getItem('sortOrder') || '',
    filter: sessionStorage.getItem('filter')
      ? JSON.parse(sessionStorage.getItem('filter'))
      : initialFilterState
  }

  handleClearFilter = () => {
    this.setState({ ...this.state, filter: initialFilterState })
    sessionStorage.removeItem('filter')
    this.props.onChange()
  }

  handleSortChange = value => {
    this.setState({ ...this.state, sort: value })
    sessionStorage.setItem('sortOrder', value)
    this.props.onChange(value)
  }

  handleFilterChange (name, value) {
    let filter = { ...this.state.filter, [name]: value }
    this.setState({ ...this.state, filter: filter })
    sessionStorage.setItem('filter', JSON.stringify(filter))
  }

  getFilterCount (filter) {
    let count = 0
    if (filter.guestRatingFrom !== 1 || filter.guestRatingTo !== 5) count++
    if (filter.stars.length) count += filter.stars.length
    if (filter.facilities.length) count += filter.facilities.length
    if (filter.distanceToTownCenterTo < 5000) count++
    if (filter.distanceToLiftTo < 5000) count++
    if (filter.distanceToSkiBusTo < 5000) count++
    if (filter.priceTo) count++
    return count
  }

  render () {
    if (!this.props.period) return null
    const { sort, filter, show } = this.state
    const { period, market } = this.props
    if (!period) return null

    let { facilities, minPrice, maxPrice } = period

    if (maxPrice) {
      maxPrice = Math.ceil(maxPrice / 1000) * 1000
      minPrice = Math.ceil(minPrice / 1000) * 1000
    }
    const filterCount = this.getFilterCount(filter)
    const label = (
      <Box textAlign='left'>
        <Box>{Y`Filter`}</Box>
        {filterCount > 0 && (
          <Box className='caption'>
            {filterCount} {Y`active`}
          </Box>
        )}
      </Box>
    )

    let currencyFormat = new Intl.NumberFormat(market.culture, {
      style: 'currency',
      currency: market.currencyId,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })

    return (
      <div className='hotel-filter-sort'>
        <Row alignItems='center' justifyContent='space-between'>
          <IconButton
            onClick={() => this.setState({ show: true })}
            style={{ marginTop: '.9375rem' }}
            label={label}
          >
            filter_list
          </IconButton>
          <InlineBlock
            mediaQueries={smallWidth}
            smWidth='100%'
            smMarginLeft='.9375rem'
          >
            <Select
              label='Sort'
              hideLabel
              value={sort}
              style={{ display: 'inline-block' }}
              onChange={this.handleSortChange}
            >
              <option value=''>{Y`Alpresor recommends`}</option>
              <option value='lowestPrice'>{Y`Lowest price`}</option>
              <option value='highestPrice'>{Y`Highest price`}</option>
              <option value='distanceToLift'>{Y`Distance to lift`}</option>
              <option value='distanceToTownCentre'>{Y`Distance to centre`}</option>
              <option value='stars'>{Y`Hotel standard`}</option>
              <option value='guestRating'>{Y`Customer rating`}</option>
            </Select>
          </InlineBlock>
        </Row>
        {show && (
          <Sidenav
            title={Y`Filter`}
            show={show}
            onClose={() => {
              this.props.onChange()
              this.setState({ ...this.state, show: false })
            }}
          >
            <CardContent>
              <h5>{Y`Hotel standard`}</h5>
              <List className='dense no-odd'>
                {Array.from(Array(maxStars))
                  .sort(e => e)
                  .map((e, i) => (
                    <ListItem key={maxStars - i} className='clickable'>
                      <CheckBox
                        name='stars'
                        onChange={value => {
                          if (value) {
                            this.handleFilterChange('stars', [
                              ...this.state.filter.stars,
                              maxStars - i
                            ])
                          } else {
                            this.handleFilterChange(
                              'stars',
                              this.state.filter.stars.filter(
                                star => star !== maxStars - i
                              )
                            )
                          }
                        }}
                        value={this.state.filter.stars.some(
                          star => star === maxStars - i
                        )}
                        label={
                          <Stars color={starColor} size='18'>
                            {maxStars - i}
                          </Stars>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </CardContent>
            <CardContent>
              <h5>{Y`Price`}</h5>
              <Box component='p' textAlign='center' className='nomargin'>
                <Currency>{filter.priceTo || maxPrice}</Currency>
              </Box>
              <Slider
                value={filter.priceTo || maxPrice}
                onChange={value =>
                  this.handleFilterChange(
                    'priceTo',
                    value === maxPrice || value === 0 ? null : value
                  )
                }
                marks={{
                  [minPrice]: currencyFormat.format(minPrice),
                  [maxPrice]: currencyFormat.format(maxPrice)
                }}
                min={minPrice}
                max={maxPrice}
                step={1000}
              />
            </CardContent>
            <CardContent>
              <h5>{Y`Customer rating`}</h5>
              <Box component='p' textAlign='center' className='nomargin'>{`${
                filter.guestRatingFrom
              } ${Y`to`} ${filter.guestRatingTo}`}</Box>
              <Slider
                range
                value={[filter.guestRatingFrom, filter.guestRatingTo]}
                onChange={value => {
                  console.log(value)
                  this.handleFilterChange('guestRatingFrom', value[0])
                  setTimeout(() =>
                    this.handleFilterChange('guestRatingTo', value[1])
                  )
                }}
                marks={{ 1: '1', 5: '5' }}
                min={1}
                max={5}
                step={1}
              />
            </CardContent>
            <CardContent>
              <h5>{Y`Distance to lift`}</h5>
              <Box
                component='p'
                textAlign='center'
                className='nomargin'
              >{`${filter.distanceToLiftTo} m`}</Box>
              <Slider
                value={filter.distanceToLiftTo}
                onChange={value =>
                  this.handleFilterChange('distanceToLiftTo', value)
                }
                marks={{ 0: '0 m', 5000: '5000 m' }}
                min={0}
                max={5000}
                step={100}
              />
            </CardContent>

            <CardContent>
              <h5>{Y`Distance to ski bus`}</h5>
              <Box
                component='p'
                textAlign='center'
                className='nomargin'
              >{`${filter.distanceToSkiBusTo} m`}</Box>
              <Slider
                value={filter.distanceToSkiBusTo}
                onChange={value =>
                  this.handleFilterChange('distanceToSkiBusTo', value)
                }
                marks={{ 0: '0 m', 5000: '5000 m' }}
                min={0}
                max={5000}
                step={100}
              />
            </CardContent>

            <CardContent>
              <h5>{Y`Distance to centre`}</h5>
              <Box
                component='p'
                textAlign='center'
                className='nomargin'
              >{`${filter.distanceToTownCenterTo} m`}</Box>
              <Slider
                value={filter.distanceToTownCenterTo}
                onChange={value =>
                  this.handleFilterChange('distanceToTownCenterTo', value)
                }
                marks={{ 0: '0 m', 5000: '5000 m' }}
                min={0}
                max={5000}
                step={100}
              />
            </CardContent>

            <CardContent>
              <h5>{Y`Hotel fact`}</h5>
              <List className='dense no-odd'>
                {facilities &&
                  facilities.map(gf => (
                    <ListItem key={gf.id} className='clickable'>
                      <CheckBox
                        name='facilities'
                        onChange={value => {
                          if (value) {
                            this.handleFilterChange('facilities', [
                              ...this.state.filter.facilities,
                              gf.id
                            ])
                          } else {
                            this.handleFilterChange(
                              'facilities',
                              this.state.filter.facilities.filter(
                                f => f !== gf.id
                              )
                            )
                          }
                        }}
                        value={this.state.filter.facilities.some(
                          f => f === gf.id
                        )}
                        label={gf.description}
                      />
                    </ListItem>
                  ))}
              </List>
            </CardContent>
            <CardContent className='filter-sticky'>
              <Row
                justifyContent='space-between'
                alignItems='inherit'
                paddingBottom='8px'
              >
                <Button
                  small
                  className='nomargin'
                  onClick={this.handleClearFilter}
                  disabled={
                    JSON.stringify(initialFilterState) ===
                    JSON.stringify(filter)
                  }
                >{Y`Clear filter`}</Button>
                <Button
                  primary
                  small
                  className='nomargin'
                  onClick={() => {
                    this.props.onChange()
                    this.setState({ ...this.state, show: false })
                  }}
                >{Y`Show proposal`}</Button>
              </Row>
            </CardContent>
          </Sidenav>
        )}
      </div>
    )
  }
}

HotelFilterSort = connect(mapStateToProps, null)(HotelFilterSort)
export default HotelFilterSort
