import React from 'react'
import { Col, Box } from 'jsxstyle'
import { Page, Layout, Content } from '../../components/layout'
import Button from '../../components/button'
import Y from '../../y18n'

const NotFoundPage = () => (
  <Page>
    <Layout>
      <Content>
        <Col alignItems='center'>
          <h2>404 - Not found</h2>
          <Box
            component='p'
            textAlign='center'
          >{Y`The page you were looking for has been moved or no longer exists`}</Box>
          <Button link to='/'>{Y`Back to start page`}</Button>
        </Col>
      </Content>
    </Layout>
  </Page>
)

export default NotFoundPage
