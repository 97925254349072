import React from 'react'
import PropTypes from 'prop-types'

export const CarouselButton = ({ children, active = false, disabled = false, onClick }) => {
  return (
    <button onClick={onClick} disabled={disabled} className={`carousel-button ${active ? 'active' : ''}`}>
      {children}
    </button>
  )
}

CarouselButton.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}
