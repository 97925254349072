import React, { Component } from 'react'
import TagManager from 'react-gtm-module'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Box } from 'jsxstyle'
import config from '../../config'
import { Card, CardContent } from '../../components/card'
import { getRedirectUrl } from '../../components/redirect-service'
import { Icon } from '../../components/icon'
import Button from '../../components/button'
import Checkbox from '../../components/checkbox'
import Currency from '../../containers/currency'
import Y from '../../y18n'
import * as paymentActions from '../../store/ducks/payment'
import * as reservationActions from '../../store/ducks/reservation'
import * as customerActions from '../../store/ducks/customer'
import PaymentAlternativesDialog from '../payment-alternatives-dialog'
import moment from 'moment'

import './box-payment-form.css'

const smallWidth = { sm: 'screen and (max-width:992px)' }

const mapStateToProps = state => ({
  market: state.market.market,
  paymentState: state.payment,
  reservationId: state.reservation.reservationId,
  reservation: state.reservation.summary,
  payingCustomer: state.payingCustomer.data
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch),
  customerActions: bindActionCreators(customerActions, dispatch),
  paymentActions: bindActionCreators(paymentActions, dispatch)
})

class ConfirmBox extends Component {
  state = {
    checked: false,
    clicked: false,
    showPaymentDialog: false,
    iframeDisplayed: false
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.paymentState.embeddedUrl && !this.state.iframeDisplayed) {
      // Inject AltaPay script to body, to show payment iframe
      // Potential Threat Modeling candidate
      const abortController = new AbortController()
      const script = document.createElement('script')
      script.src = nextProps.paymentState.embeddedUrl
      document.body.appendChild(script)
      this.setState({
        ...this.state,
        showPaymentDialog: false,
        iframeActivated: true
      })
      // When close button is pressed in the iframe. this listener should remove the script
      window.addEventListener(
        'message',
        e => {
          if (
            e.isTrusted &&
            e.origin.includes('altapay') &&
            e.data === 'close'
          ) {
            document.body.removeChild(script)
            this.setState({
              ...this.state,
              iframeActivated: false,
              showPaymentDialog: false
            })
            // Dismiss this listener and create a new one when iframe opens again
            abortController.abort()
            this.props.paymentActions.resetCurrentTransaction()
            this.props.onCancel()
          }
        },
        { signal: abortController.signal }
      )
      return
    }
    // redirect to app after payment succeded if we come from it
    if (
      nextProps.paymentState.paymentSucceded !== null &&
      this.props.paymentState.paymentSucceded
    ) {
      const redirectUrl = getRedirectUrl()
      if (redirectUrl) {
        window.location = redirectUrl
      }
    }
    return null
  }

  handleConfirm () {
    const { reservation } = this.props
    // trigger validation if form not finished
    if (!this.props.ignoreCustomerForm) {
      if (!document.querySelector('#customer-form').checkValidity()) {
        document.querySelector('#submit').click()
        return
      }
    }

    const numberOfTravelers = reservation.travelers.length
    const numberOfTravelersAbove18 = reservation.travelers.filter(traveler => {
      const birthDate = new moment(traveler.birthDate)
      const ageInYears = moment().diff(birthDate, 'years')
      return ageInYears > 18
    })?.length
    const weekNumber = moment(reservation?.firstTravelDate).week() ?? ''
    const firstTravelDate = moment(reservation.firstTravelDate).format(
      'YYYY-MM-DD'
    )

    this.setState({ ...this.state, clicked: true }, () => {
      if (this.props.payingCustomer.registerAccount) {
        this.props.customerActions.submitCreateCustomer(
          this.props.payingCustomer
        )
      }

      const reservationId =
        this.props.reservationId || this.props.reservation.id
      let changes = null

      this.props.reservationActions
        .submitConfirmReservation({
          reservationId
        })
        .then(() => this.props.reservationActions.queryChanges(reservation.id))
        .then(res => {
          changes = res.data
          return this.props.reservationActions.queryReservationSummary(
            reservation.id
          )
        })
        .then(res => {
          if (res.data.numberOfConfirmations <= 1) {
            TagManager.dataLayer({
              dataLayer: {
                transactionId: res.data.reservationCode,
                customerEmail: reservation.payingCustomer.emailAddress,
                transactionAffiliation: '',
                transactionTotal: reservation.price,
                transactionTax: '',
                transactionShipping: '',
                transactionPax: numberOfTravelers,
                transactionAdults: numberOfTravelersAbove18,
                transactionChildren:
                  numberOfTravelers - numberOfTravelersAbove18,
                transactionDepartureDate: firstTravelDate,
                transactionDepartureAirport: reservation.search.airportId,
                transactionWeekNumber: weekNumber,
                transactionSeason: reservation.seasonDescription,
                transactionProducts: [
                  {
                    sku: reservation.hotels.length
                      ? reservation.hotels[0].name
                      : reservation.charter
                      ? reservation.charter.flights
                          .filter(f => f.direction === 'Outward')
                          .slice(-1)
                          .pop().destinationAirport
                      : '',
                    name: reservation.hotels.length
                      ? reservation.hotels[0].destinationDescription
                      : reservation.charter
                      ? reservation.charter.flights
                          .filter(f => f.direction === 'Outward')
                          .slice(-1)
                          .pop().destinationAirport
                      : '',
                    category:
                      reservation.packages && reservation.packages.length
                        ? reservation.packages[0].description
                        : 'Unknown',
                    price: reservation.price,
                    quantity: 1,
                    brand: reservation.seasonDescription,
                    coupon: reservation.offerCode
                  }
                ]
              }
            })
          }

          // if we are comming from pwa-app, set new datalayer with changes from previous confirm
          if (getRedirectUrl() && changes && changes.length) {
            TagManager.dataLayer({
              dataLayer: {
                transactionId: res.data.reservationCode,
                transactionAffiliation: '',
                transactionTotal: changes
                  .map(a => a.price * a.numberOfReserved)
                  .reduce((a, b) => a + b),
                transactionTax: '',
                transactionShipping: '',
                transactionPax: numberOfTravelers,
                transactionAdults: numberOfTravelersAbove18,
                transactionChildren:
                  numberOfTravelers - numberOfTravelersAbove18,
                transactionDepartureDate: firstTravelDate,
                transactionDepartureAirport: reservation.search.airportId,
                transactionWeekNumber: weekNumber,
                transactionSeason: reservation.seasonDescription,
                transactionProducts: changes.map(a => {
                  return {
                    sku: a.destinationDescription,
                    name: a.description,
                    category: a.type,
                    price: a.price,
                    quantity: a.numberOfReserved,
                    brand: reservation.seasonDescription,
                    coupon: 'rabattKod'
                  }
                })
              }
            })
          }

          if (reservation.requirePaymentOnConfirm) {
            this.setState({ ...this.state, showPaymentDialog: true })
          } else {
            this.props.history.push(
              `/reservation/${reservationId}?status=confirmed`
            )
          }
        })
        .catch(() => {
          this.setState({ ...this.state, clicked: false })
        })
    })
  }

  handleClickText = e => {
    if (!e.target.classList.contains('link')) {
      this.handleSetChecked()
    }
  }

  handleSetChecked () {
    this.setState({ ...this.state, checked: !this.state.checked })
  }

  handleDownloadTravelConditions () {
    this.props.reservationActions.downloadTravelConditions(
      this.props.reservation.marketId
    )
  }

  handlePaymentClick (type, amount, id) {
    let reservation = this.props.reservation
    const customerInfo = {
      phone: reservation.payingCustomer.cellPhoneNumber,
      email: reservation.payingCustomer.emailAddress
    }
    this.props.paymentActions
      .submitCardTransaction(
        reservation.id,
        amount,
        reservation.currency,
        type,
        reservation.marketId,
        reservation.reservationCode,
        customerInfo,
        id
      )
      .then(() => {
        this.setState({
          ...this.state,
          iframeActivated: true
        })
      })
  }

  handleClosePaymentDialog () {
    this.setState({ ...this.state, showPaymentDialog: false })
    window.location = `/reservation/${this.props.reservationId}?status=paymentFailed`
  }

  renderFinnishText (reservation, market) {
    return (
      <React.Fragment>
        {Y`I have read and approved`} {' STS Alppimatkojen '}
        <Button
          style={{ fontSize: '.9rem' }}
          link
          to={`${config.pdfUrl}/reservations/travelConditions/${reservation.marketId}.pdf`}
          target='_blank'
        >
          {Y`the travel conditions`}
        </Button>{' '}
        {Y`and2`}{' '}
        <a
          className='btn link'
          style={{ fontSize: '.9rem' }}
          href={Y`AlpInfoTravelCondition`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {Y`personal data policy`}
        </a>
        .{' '}
        {Y`I have also checked that all names are correctly spelled`}
        .
      </React.Fragment>
    )
  }

  renderNormalText (reservation, market) {
    return (
      <React.Fragment>
        {Y`I have read and approved`}{' '}
        <Button
          style={{ fontSize: '.9rem' }}
          link
          to={`${config.pdfUrl}/reservations/travelConditions/${reservation.marketId}.pdf`}
          target='_blank'
        >{Y`the travel conditions`}</Button>{' '}
        {Y`and2`} {market.description}
        <a
          className='btn link'
          style={{ fontSize: '.9rem' }}
          href={Y`AlpInfoTravelCondition`}
          target='_blank'
          rel='noopener noreferrer'
        >
          {Y`personal data policy`}
        </a>
        .{' '}
        {Y`I have also checked that all names are correctly spelled`}
        .
      </React.Fragment>
    )
  }

  render () {
    const { reservation, market, style = {} } = this.props
    if (!reservation) return null

    return (
      <div>
        <Card style={{ ...style, background: 'rgb(245, 245, 245)' }}>
          <CardContent>
            <Row
              mediaQueries={smallWidth}
              smFlexDirection='column'
              smAlignItems='center'
              alignItems='flex-start'
              justifyContent='space-between'
            >
              <Row alignItems='center' marginRight='24px'>
                <Checkbox
                  style={{ marginRight: 8 }}
                  value={this.state.checked}
                  onChange={() => this.handleSetChecked()}
                  name='accept'
                />
                <p className='caption' onClick={this.handleClickText}>
                  {market.id === 'FI'
                    ? this.renderFinnishText(reservation, market)
                    : this.renderNormalText(reservation, market)}
                </p>
              </Row>
              <Col
                alignItems='flex-end'
                mediaQueries={smallWidth}
                smAlignItems='center'
              >
                <Row alignItems='center'>
                  <h5 className='nomargin'>
                    {reservation.requirePaymentOnConfirm
                      ? Y`Upsells`
                      : Y`Total price`}
                  </h5>
                  <Box marginLeft='.9375rem' className='price nomargin green'>
                    <Currency>
                      {reservation.requirePaymentOnConfirm
                        ? reservation.price - reservation.paid
                        : reservation.price}
                    </Currency>
                  </Box>
                </Row>
                <Button
                  disabled={!this.state.checked || this.state.clicked}
                  primary
                  onClick={() => this.handleConfirm()}
                  cy-id='confirm-booking-button'
                >
                  <Icon style={{ marginRight: '.4687rem' }}>lock</Icon>{' '}
                  {reservation.requirePaymentOnConfirm
                    ? Y`Pay`
                    : Y`Confirm my booking`}
                </Button>
              </Col>
            </Row>
          </CardContent>
        </Card>

        {reservation.requirePaymentOnConfirm &&
          this.state.showPaymentDialog && (
            <PaymentAlternativesDialog
              marketId={reservation.marketId}
              currency={reservation.currency}
              amount={reservation.price - reservation.paid}
              minAmountToPay={reservation.maxPayment}
              maxAmountToPay={reservation.maxPayment}
              onClose={() => this.handleClosePaymentDialog()}
              onPay={(type, amount, id) =>
                this.handlePaymentClick(type, amount, id)
              }
            />
          )}
      </div>
    )
  }
}

ConfirmBox = connect(mapStateToProps, mapDispatchToProps)(ConfirmBox)
export default withRouter(ConfirmBox)
