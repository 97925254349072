import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const travelInsurancesSlice = createSlice({
  name: 'travelInsurances',
  initialState,
  reducers: {
    QUERY_TRAVEL_INSURANCE: state => {
      state.loading = true
      state.error = null
    },
    QUERY_TRAVEL_INSURANCE_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_TRAVEL_INSURANCE: (state, action) => {
      state.items = state.items.map(traveler =>
        traveler.id === action.payload.travelerId
          ? {
              ...traveler,
              travelInsurances: traveler.travelInsurances.map(travelInsurance =>
                travelInsurance.id === action.payload.inventoryId
                  ? {
                      ...travelInsurance,
                      reserved: action.payload.reserved,
                      blocked: action.payload.blocked
                    }
                  : travelInsurance
              )
            }
          : traveler
      )
    }
  }
})

export const {
  QUERY_TRAVEL_INSURANCE,
  QUERY_TRAVEL_INSURANCE_SUCCESS,
  SET_TRAVEL_INSURANCE
} = travelInsurancesSlice.actions

export default travelInsurancesSlice.reducer

// thunks
export const queryTravelInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/travelInsurance', { reservationId })
      .then(res => dispatch(QUERY_TRAVEL_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddTravelInsurance = command => {
  return (dispatch, getState) => {
    const status = getState().reservation.summary.status
    dispatch(
      SET_TRAVEL_INSURANCE({
        ...command,
        reserved: true,
        blocked: status === 'Confirmed' || status === 'ConfirmedWithChanges'
      })
    )
    let cmd = api.sendCommand('travelBooking/selectTravelInsurance', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}

export const submitRemoveTravelInsurance = command => {
  return dispatch => {
    dispatch(
      SET_TRAVEL_INSURANCE({ ...command, reserved: false, blocked: false })
    )
    let cmd = api.sendCommand('travelBooking/removeTravelInsurance', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
