import React from 'react'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import './fade-in.css'

export const FadeIn = ({ children }) => (
  <ReactCSSTransitionGroup
    transitionName='fade-in'
    transitionAppear
    transitionAppearTimeout={200}
    transitionEnter={false}
    transitionLeave={false}
    component='div'
  >
    {children}
  </ReactCSSTransitionGroup>
)

export default FadeIn
