import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Block } from 'jsxstyle'
import { Icon } from '../../../components/icon'
import Callouts from '../../../components/callouts'
import ImageCarousel from '../../../components/image-carousel'
import Y from '../../../y18n'
import './hotel-images.css'

const maxHeight = 500
const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelImages extends PureComponent {
  state = {
    imageHeight: 0,
    current: 1
  }

  componentDidMount () {
    this.setImageSize()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = e => this.setImageSize()

  handleChange = i => this.setState({ ...this.state, current: i + 1 })

  setImageSize () {
    let windowWidth = window.innerWidth
    if (windowWidth < 992) {
      this.setState({
        imageHeight: `${Math.round(window.innerWidth * 0.56)}px`
      })
    } else {
      let height = Math.round(window.innerWidth * 0.37)
      height = height > maxHeight ? maxHeight : height
      this.setState({ imageHeight: `${height}px` })
    }
  }

  renderImageSkeleton () {
    return (
      <Block
        className='skeleton-img hotel-images'
        width='66.6%'
        smWidth='100%'
        mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
      >
        <Icon color='rgba(133, 133, 133, .25)' size='80px'>
          photo_library
        </Icon>
      </Block>
    )
  }

  render () {
    const { proposal } = this.props
    if (!proposal) {
      return this.renderImageSkeleton()
    }
    const hotel = proposal.hotel

    return (
      <Block
        position='relative'
        className='hotel-images'
        width='66.6%'
        smWidth='100%'
        mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
      >
        <ImageCarousel afterChange={this.handleChange}>
          {hotel.info.imageUrls
            ? hotel.info.imageUrls.map((img, index) => (
                <img
                  style={{ height: this.state.imageHeight }}
                  key={index}
                  src={`${img}&w=1200&h=930&fit=cover`}
                  alt=''
                />
              ))
            : this.renderImageSkeleton()}
        </ImageCarousel>
        <Block
          position='absolute'
          right='0'
          bottom='0'
          padding='.2rem .4687rem'
          background='rgba(0,0,0,0.7)'
          color='#fff'
          fontSize='0.8rem'
        >
          {Y`Image`} {this.state.current}/
          {hotel.info.imageUrls ? hotel.info.imageUrls.length : 1}
        </Block>
        <Callouts src={hotel.info.callOuts} />
      </Block>
    )
  }
}

HotelImages = connect(mapStateToProps)(HotelImages)
export default HotelImages
