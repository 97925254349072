import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import moment from 'moment-timezone'

const initialState = {
  error: null,
  loading: false,
  data: {
    nordicChoiceClubDetails: {
      memberId: null,
      email: null,
      phoneNumber: null,
      contactDetailsType: 'email',
      validPhoneNumber: true
    }
  }
}

const payingCustomerSlice = createSlice({
  name: 'payingCustomer',
  initialState,
  reducers: {
    QUERY_CUSTOMER: state => {
      state.loading = true
      state.error = null
    },
    QUERY_CUSTOMER_SUCCESS: (state, action) => {
      if (!action.payload) return

      const data = {
        ...state.data,
        ...action.payload,
        nordicChoiceClubDetails: {
          ...state.data.nordicChoiceClubDetails,
          ...action.payload.nordicChoiceClubDetails
        }
      }
      let birthDate = moment(data.birthDate)
      data.year = birthDate.year()
      data.month = birthDate.format('MM')
      data.day = birthDate.format('DD')
      state.loading = false
      state.data = data
    },
    QUERY_CUSTOMER_ERROR: (state, action) => {
      let error = action.payload || { message: action.payload.message }
      state.error = error
      state.loading = false
      state.data = initialState.data
    },

    SET_VALUE: (state, action) => {
      state.data[action.payload.name] = action.payload.value
    },
    SET_PAYING_CUSTOMER_DETAILS: (state, action) => {
      state.data = action.payload
    },
    // nordicChoiceClubDetails handlers
    UPDATE_ALL: (state, action) => {
      state.data.nordicChoiceClubDetails = {
        ...action.payload,
        validPhoneNumber: true
      }
    },
    UPDATE_MEMBER_ID: (state, action) => {
      state.data.nordicChoiceClubDetails.memberId = action.payload
    },
    UPDATE_EMAIL: (state, action) => {
      state.data.nordicChoiceClubDetails.email = action.payload
    },
    UPDATE_PHONE_NUMBER: (state, action) => {
      state.data.nordicChoiceClubDetails.phoneNumber = action.payload
    },
    UPDATE_CONTACT_DETAILS_TYPE: (state, action) => {
      state.data.nordicChoiceClubDetails.contactDetailsType = action.payload
    },
    UPDATE_PHONE_NUMBER_VALIDITY: (state, action) => {
      state.data.nordicChoiceClubDetails.validPhoneNumber = action.payload
    }
  }
})

export const {
  QUERY_CUSTOMER,
  QUERY_CUSTOMER_SUCCESS,
  QUERY_CUSTOMER_ERROR,
  SET_VALUE,
  SET_PAYING_CUSTOMER_DETAILS,
  UPDATE_ALL,
  UPDATE_MEMBER_ID,
  UPDATE_EMAIL,
  UPDATE_PHONE_NUMBER,
  UPDATE_CONTACT_DETAILS_TYPE,
  UPDATE_PHONE_NUMBER_VALIDITY
} = payingCustomerSlice.actions

export default payingCustomerSlice.reducer

// thunks
export const queryPayingCustomer = reservationId => {
  return (dispatch, getState) => {
    dispatch(QUERY_CUSTOMER())
    let query = api.query('travelBooking/payingCustomer', { reservationId })
    query.then(res => {
      dispatch(QUERY_CUSTOMER_SUCCESS(res.data))
    })
    return query
  }
}

export const setValue = (value, name) => {
  return dispatch => {
    dispatch(SET_VALUE({ value, name }))
  }
}

export const setPayingCustomerDetails = value => {
  return dispatch => {
    dispatch(SET_PAYING_CUSTOMER_DETAILS(value))
  }
}

export const submitSetPayingCustomer = (type, command) => {
  if (type === 'paying-customer') {
    return dispatch => {
      return api.sendCommand('travelBooking/setPayingCustomer', command)
    }
  } else {
    return dispatch => {
      return api.sendCommand(
        'travelBooking/setNordicChoiceClubDetails',
        command
      )
    }
  }
}

// nordicChoiceClubDetails
export const setNordicChoiceClubDetails = (val, field) => {
  switch (field) {
    case 'memberId':
      return dispatch => {
        dispatch(UPDATE_MEMBER_ID(val))
      }
    case 'email':
      return dispatch => {
        dispatch(UPDATE_EMAIL(val))
      }
    case 'phone-number':
      return dispatch => {
        dispatch(UPDATE_PHONE_NUMBER(val))
      }
    case 'contactDetailsType':
      return dispatch => {
        dispatch(UPDATE_CONTACT_DETAILS_TYPE(val))
      }
    case 'all':
      return dispatch => {
        dispatch(UPDATE_ALL(val))
      }
    default:
      break
  }
}

export const setPhoneNumberValidity = val => {
  return dispatch => {
    dispatch(UPDATE_PHONE_NUMBER_VALIDITY(val))
  }
}
