import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Box } from 'jsxstyle'
import HotelDescription from './hotel-description'
import RoomList from './room-list'
import HotelImages from './hotel-images'
import HotelUsp from './hotel-usp'
import HotelName from './hotel-name'
import HotelFromPrice from './hotel-from-price'
import HotelFacilities from './hotel-facilities'
import HotelRatings from './hotel-ratings'
import HotelDistances from './hotel-distances'
import HotelMap from './hotel-map'
import HotelCustomerComments from './hotel-customer-comments'
import HotelReservationSummary from './hotel-reservation-summary'
import HotelWifi from './hotel-wifi'
import SelectRoomButton from './select-room-button'
import PriceAndNext from './price-and-next'
import FlightInfo from './flight-info'
import Halfboards from './halfboards'
import withReservationId from '../../containers/with-reservation-id'
import { Icon } from '../../components/icon'
import Summary from '../../components/summary'
import Y from '../../y18n'
import { Page, Layout, Content } from '../../components/layout'
import { queryRoomProposal } from '../../store/ducks/room-proposal'
import { queryReservationSummary } from '../../store/ducks/reservation'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  proposal: state.roomProposal.item,
  loading: state.roomProposal.loading
})

const smallWidth = 992

class RoomProposalPage extends Component {
  timeout
  state = { isSmallLayout: null }

  componentDidMount () {
    this.props.dispatch(queryRoomProposal(this.props.reservationId))
    this.props.dispatch(queryReservationSummary(this.props.reservationId))
    this.setLayout(true)
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = () => this.setLayout()

  setLayout (init) {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(
      () => {
        let windowWidth = window.innerWidth
        if (
          (windowWidth <= smallWidth && this.state.isSmallLayout === true) ||
          (windowWidth > smallWidth && this.state.isSmallLayout === false)
        ) {
        } else {
          if (windowWidth <= smallWidth) {
            this.setState({ isSmallLayout: true })
          } else {
            this.setState({ isSmallLayout: false })
          }
        }
      },
      init ? 0 : 20
    )
  }

  render () {
    const { loading, proposal } = this.props
    if (this.state.isSmallLayout === null) return null

    return (
      <Page>
        <Layout>
          <Summary sticky>
            <HotelReservationSummary />
          </Summary>
          {!loading && !proposal ? (
            <Content>
              <Row className='yellow-bg' padding='.9375rem' margin='.9375rem 0'>
                <Icon style={{ marginRight: '.9375rem' }}>error</Icon>
                <Box>
                  <p className='nomargin'>
                    {Y`The price proposal is sold out. Please contact us on ${Y`AlpInfoPhone`} for a new quote or you can also`}{' '}
                    <a
                      href={Y`AlpInfoUrl`}
                    >{Y`search for a new trip yourself`}</a>
                  </p>
                </Box>
              </Row>
            </Content>
          ) : this.state.isSmallLayout ? (
            <Content>
              <Box margin='0 -.9375rem' width='calc(100% + .9375rem * 2)'>
                <FlightInfo />
                <HotelImages />
              </Box>
              <HotelName isSmallLayout={this.state.isSmallLayout} />
              <Row
                margin='0 auto'
                maxWidth='260px'
                alignItems='center'
                justifyContent='space-between'
              >
                <HotelMap />
                <HotelWifi />
              </Row>
              <HotelDescription />
              <SelectRoomButton />
              <HotelUsp />
              <HotelDistances />
              <HotelCustomerComments />
              <HotelRatings />
              <HotelFacilities />
              <RoomList isSmallLayout={this.state.isSmallLayout} />
              <Halfboards />
              <PriceAndNext />
            </Content>
          ) : (
            <>
              <FlightInfo />
              <Box maxWidth='1800px' margin='0 auto'>
                <Row>
                  <HotelImages />
                  <Box
                    width='calc(33.4% - 1.875rem * 2)'
                    marginLeft='1.8375rem'
                  >
                    <HotelName />
                    <HotelDistances />
                    <Box
                      mediaQueries={{
                        medium: 'screen and (max-width: 1380px)'
                      }}
                      mediumDisplay='none'
                    >
                      <HotelCustomerComments />
                    </Box>
                    <HotelWifi />
                    <HotelRatings />
                    <HotelFromPrice />
                  </Box>
                </Row>
              </Box>
              <Content>
                <Row paddingTop='calc(.9375rem * 2)'>
                  <HotelDescription />
                  <HotelUsp />
                </Row>
                <Box
                  mediaQueries={{ large: 'screen and (min-width: 1380px)' }}
                  largeDisplay='none'
                >
                  <HotelCustomerComments />
                </Box>
                <HotelFacilities />
                <RoomList />
                <Halfboards />
                <PriceAndNext />
              </Content>
            </>
          )}
        </Layout>
      </Page>
    )
  }
}

RoomProposalPage = withReservationId(RoomProposalPage)
RoomProposalPage = connect(mapStateToProps)(RoomProposalPage)
export default RoomProposalPage
