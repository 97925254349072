import { configureStore } from '@reduxjs/toolkit'
import * as y18n from '../y18n'
import addons from './ducks/addons'
import cancellationInsurances from './ducks/cancellation-insurances'
import charterPeriods from './ducks/charter-periods'
import charterTypes from './ducks/charter-types'
import customer from './ducks/customer'
import customerCategories from './ducks/customer-categories'
import flightMeals from './ducks/flight-meals'
import generalFacilities from './ducks/general-facilities'
import halfboards from './ducks/halfboards'
import locales from './ducks/locales'
import market from './ducks/market'
import travelers from './ducks/travelers'
import payingCustomer from './ducks/paying-customer'
import payment from './ducks/payment'
import proposal from './ducks/proposal'
import roomProposal from './ducks/room-proposal'
import reservation from './ducks/reservation'
import skiLuggage from './ducks/skiluggage'
import supplements from './ducks/supplements'
import transfers from './ducks/transfers'
import travelInsurances from './ducks/travel-insurances'
import fullRefundInsurances from './ducks/full-refund-insurances'
import golfBags from './ducks/golfbags'

const store = configureStore({
  reducer: {
    addons,
    cancellationInsurances,
    charterPeriods,
    charterTypes,
    customer,
    customerCategories,
    flightMeals,
    generalFacilities,
    halfboards,
    locales,
    market,
    travelers,
    payment,
    payingCustomer,
    proposal,
    reservation,
    roomProposal,
    skiLuggage,
    supplements,
    transfers,
    travelInsurances,
    fullRefundInsurances,
    golfBags
  }
})

y18n.setStore(store)
export default store
