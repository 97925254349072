import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'

const initialState = { locale: null }

const localesSlice = createSlice({
  name: 'locales',
  initialState,
  reducers: {
    SET_LOCALE: (state, action) => {
      if (Object.keys(action.payload).length) {
        state.locale = action.payload
      }
    }
  }
})

export const { SET_LOCALE } = localesSlice.actions

export default localesSlice.reducer

export const setLocale = language => {
  return dispatch => {
    fetch(`/locales/${language}.json`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`)
        }
        return response.json()
      })
      .then(json => {
        dispatch(SET_LOCALE(json))
      })
      .catch(err => {
        Sentry.captureException(err)
        setLocale('sv')
      })
  }
}
