import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'jsxstyle'
import Y from '../../../y18n'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelDistances extends PureComponent {
  render () {
    const { proposal } = this.props
    if (!proposal) return null
    const hotel = proposal.hotel

    return (
      <Row
        mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
        paddingTop='.9375rem'
        lgPaddingBottom='.9375rem'
        lgBorderBottom='1px solid rgb(238,238,238)'
        borderTop='1px solid rgb(238,238,238)'
        height='72px'
        width='100%'
      >
        {hotel.info.distanceToLift !== null && (
          <Col width='100%' alignItems='center' direction='column' justifyContent='space-between'>
            <span className='caption'>{Y`Lift`}</span>
            <img height='30px' src='/img/ski-lift.svg' alt='' />
            <span className='caption'>{`${hotel.info.distanceToLift}m`}</span>
          </Col>
        )}
        {hotel.info.distanceToTownCentre !== null && (
          <Col width='100%' alignItems='center' justifyContent='space-between'>
            <span className='caption'>{Y`Centre`}</span>
            <img height='28px' src='/img/ski-centre.svg' alt='' />
            <span className='caption'>{`${hotel.info.distanceToTownCentre}m`}</span>
          </Col>
        )}
        {hotel.info.distanceToSkibus !== null && (
          <Col width='100%' alignItems='center' justifyContent='space-between'>
            <span className='caption'>{Y`Skibus`}</span>
            <img height='24px' src='/img/ski-bus.svg' alt='' />
            <span className='caption'>{`${hotel.info.distanceToSkibus}m`}</span>
          </Col>
        )}
      </Row>
    )
  }
}

HotelDistances = connect(mapStateToProps)(HotelDistances)
export default HotelDistances
