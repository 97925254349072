import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Col, Row, Block } from 'jsxstyle'
import HotelInfo from './hotel-info'
import PriceInfo from './price-info'
import Callouts from '../../../components/callouts'
import Info from '../../../components/info'
import { Icon } from '../../../components/icon'
import Stars from '../../../components/stars'
import Skeleton from '../../../components/skeleton'
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardHeader,
  CardContent
} from '../../../components/card'
import Y from '../../../y18n'
import Images from './images'
import { submitSelectProposal } from '../../../store/ducks/reservation'

import './hotel-card.css'

const mapStateToProps = state => ({
  reservationState: state.reservation
})

const smallWidth = 992

class HotelCard extends Component {
  state = { isSmallLayout: null }

  componentDidMount () {
    this.setLayout(true)
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.clearTimeout(this.timeout)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = e => this.setLayout()

  setLayout (init) {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(
      () => {
        let windowWidth = window.innerWidth
        if (
          (windowWidth <= smallWidth && this.state.isSmallLayout === true) ||
          (windowWidth > smallWidth && this.state.isSmallLayout === false)
        ) {
        } else {
          if (windowWidth <= smallWidth) {
            this.setState({ isSmallLayout: true })
          } else {
            this.setState({ isSmallLayout: false })
          }
        }
      },
      init ? 0 : 20
    )
  }

  handleToggleDestinationDialog = () => {
    this.setState({
      ...this.state,
      showDestinationInfo: !this.state.showDestinationInfo
    })
  }

  handleToggleMapDialog = () => {
    this.setState({ ...this.state, showMap: !this.state.showMap })
  }

  handleSelectProposal = proposal => {
    const command = {
      hotel: {
        charterPeriodId: proposal.hotel.charterPeriodId,
        rooms: proposal.hotel.roomProposals[0].rooms.map(room => {
          return {
            id: room.id,
            travelerIds: room.travelerIds
          }
        })
      },
      packageId: proposal.package.id,
      reservationId: this.props.reservationState.reservationId
    }
    if (proposal.flightCharter) {
      command.flightCharter = {
        id: proposal.flightCharter.id
      }
    }
    this.props
      .dispatch(submitSelectProposal(command))
      .then(() =>
        this.props.history.push(
          `/room-proposal/${this.props.reservationState.reservationId}`
        )
      )
  }

  render () {
    const { proposal } = this.props
    const hotel = proposal.hotel
    if (!hotel) return null

    return (
      <Card
        className='hotel-card'
        onClick={() =>
          this.state.isSmallLayout &&
          !proposal.package.forbidden &&
          this.handleSelectProposal(proposal)
        }
      >
        <Row>
          <Block
            width='34%'
            lgWidth='50%'
            mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
            className='hotel-img'
            position='relative'
          >
            {!hotel.info || !hotel.info.imageUrls ? (
              <Block className='skeleton-img'>
                <Icon color='rgba(133, 133, 133, .25)' size='80px'>
                  photo_library
                </Icon>
              </Block>
            ) : this.state.isSmallLayout ? (
              <img
                className=''
                src={
                  hotel.info.imageUrls.find(url => url !== null)
                    ? hotel.info.imageUrls
                        .find(url => url !== null)
                        .replace(
                          '{0}',
                          window.innerWidth >= 992 ? 'Wide' : 'Narrow'
                        )
                    : ''
                }
                alt={hotel.info.name}
              />
            ) : (
              <Images
                src={hotel.info.imageUrls
                  .filter(url => url !== null)
                  .map(url => `${url}&w=768&h=512&fit=cover`)}
              />
            )}
            {hotel.info && <Callouts src={hotel.info.callOuts || []} />}
          </Block>

          <Col
            justifyContent='space-between'
            width='66%'
            lgWidth='50%'
            mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
          >
            <CardHeader>
              <Row>
                <Block width='100%'>
                  <CardTitle>
                    {!hotel.info ? (
                      <Block component='span' width='70%'>
                        <Skeleton />
                      </Block>
                    ) : (
                      hotel.info.name
                    )}
                  </CardTitle>
                  <CardSubtitle>
                    {!hotel.info ? (
                      <Block component='span' width='60%'>
                        <Skeleton />
                      </Block>
                    ) : (
                      proposal.destination.description
                    )}
                  </CardSubtitle>
                  {!hotel.info ? (
                    <Block height='14px' width='40%'>
                      <Skeleton />
                    </Block>
                  ) : (
                    hotel.info.stars && (
                      <Stars color='rgb(255, 220, 13)' size='18px'>
                        {hotel.info.stars}
                      </Stars>
                    )
                  )}
                </Block>
                {!hotel.info || !hotel.info.guestRating ? null : (
                  <Info
                    label={Y`Customer rating`}
                    caption={`${hotel.info.noOfGuestRatings} ${Y`reviews`}`}
                    responsive
                  >
                    {`${hotel.info.guestRating} / 5`}
                  </Info>
                )}
              </Row>
            </CardHeader>
            <CardContent>
              {!hotel.info ? (
                <Block>
                  <Block height='8px' margin='4px 0' width='50%'>
                    <Skeleton />
                  </Block>
                  <Block height='8px' margin='4px 0' width='50%'>
                    <Skeleton />
                  </Block>
                  <Block height='8px' margin='4px 0' width='50%'>
                    <Skeleton />
                  </Block>
                </Block>
              ) : (
                <HotelInfo hotel={hotel.info} flight={proposal.flightCharter} />
              )}
            </CardContent>
            {!hotel.info ? (
              <Block
                mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
                lgHeight='133px'
                height='50px'
                margin='.9375rem'
              />
            ) : (
              <PriceInfo
                price={proposal.price}
                ordinaryPrice={proposal.ordinaryPrice}
                pricePerPerson={proposal.perPersonPrice}
                pckage={proposal.package}
                rooms={hotel.roomProposals[0].rooms}
                onSelect={() => this.handleSelectProposal(proposal)}
              />
            )}
          </Col>
        </Row>
      </Card>
    )
  }
}

HotelCard.propTypes = {
  proposal: PropTypes.object.isRequired
}

HotelCard = connect(mapStateToProps)(HotelCard)
export default withRouter(HotelCard)
