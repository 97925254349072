import moment from 'moment-timezone'
import isAdult from '../../utils/is-adult'

const formats = [moment.ISO_8601, 'YYYY-MM-DD']

const isAgeValid = (traveler, reservation) => {
  if (!moment(traveler.birthDate, formats, true).isValid()) {
    return false
  }

  if (isAdult(traveler.age)) {
    const diff = moment(reservation.lastTravelDate).diff(moment(traveler.birthDate), 'years')
    return diff >= 18 && diff <= 120
  } else {
    const birthDate = moment(traveler.birthDate).startOf('day')
    return (
      moment(traveler.minBirthDate).startOf('day') <= birthDate &&
      birthDate <= moment(traveler.maxBirthDate).startOf('day')
    )
  }
}
export default isAgeValid
