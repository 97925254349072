import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import Button from '../../../components/button'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import TravelInsuranceDialog from './travel-insurance-dialog'
import * as travelInsuranceActions from '../../../store/ducks/travel-insurances'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  travelInsurance: state.travelInsurances.items
})
const mapDispatchToProps = dispatch => ({
  travelInsuranceActions: bindActionCreators(travelInsuranceActions, dispatch)
})

class TravelInsuranceList extends Component {
  state = { showDialog: null }

  componentDidMount () {
    this.props.travelInsuranceActions.queryTravelInsurance(
      this.props.reservationId
    )
  }

  handleSelectTravelInsurance (value, inventoryId, travelerId) {
    let reservationId = this.props.reservationId
    if (value) {
      // Remove existing insurances, this should be done on server
      // becuase we should check if we are allowed to have multiple
      // insurances per traveler or npt
      let traveler = this.props.travelInsurance.find(x => x.id === travelerId)
      let existingInsurance = traveler.travelInsurances.find(
        x => x.reserved === true
      )
      if (existingInsurance) {
        this.props.travelInsuranceActions
          .submitRemoveTravelInsurance({
            reservationId,
            inventoryId: existingInsurance.id,
            travelerId
          })
          .then(() =>
            this.props.travelInsuranceActions.submitAddTravelInsurance({
              reservationId,
              inventoryId,
              travelerId
            })
          )
      } else
        this.props.travelInsuranceActions.submitAddTravelInsurance({
          reservationId,
          inventoryId,
          travelerId
        })
    } else
      this.props.travelInsuranceActions.submitRemoveTravelInsurance({
        reservationId,
        inventoryId,
        travelerId
      })
  }

  isReserved (travelInsurances) {
    return travelInsurances.some(x => x.reserved)
  }

  renderButtonText (travelInsurances) {
    const selected = travelInsurances.find(x => x.reserved)
    return selected ? selected.description : Y`Select`
  }

  render () {
    const { travelInsurance } = this.props
    if (!travelInsurance.length) return null

    return (
      <Card id='TravelInsurance'>
        <CardHeader>
          <CardTitle>{Y`Travel insurance`}</CardTitle>
          <p>{Y`Travel insurance text`}</p>
        </CardHeader>
        <List className='no-odd border'>
          {travelInsurance.map((traveler, k) => (
            <ListItem key={traveler.id}>
              <Row justifyContent='space-between' alignItems='center'>
                <div>
                  <Row width='100%'>
                    <h3 className='nomargin'>
                      {/^\s+$/.test(traveler.name)
                        ? isAdult(traveler.age)
                          ? Y`Adult` + ' ' + traveler.id
                          : Y`Child` + ' ' + traveler.id
                        : traveler.name}
                    </h3>
                    <p style={{ margin: 0 }}>
                      {this.isReserved(traveler.travelInsurances) ? ' - ' : ''}
                      {traveler.travelInsurances.length === 1 ? (
                        <Currency>
                          {traveler.travelInsurances[0].priceAmount}
                        </Currency>
                      ) : this.isReserved(traveler.travelInsurances) ? (
                        <Currency>
                          {
                            traveler.travelInsurances.find(x => x.reserved)
                              .priceAmount
                          }
                        </Currency>
                      ) : (
                        ''
                      )}
                    </p>
                  </Row>
                  {traveler.travelInsurances.length === 1 && (
                    <p className='caption'>
                      {traveler.travelInsurances[0].description}
                    </p>
                  )}
                </div>

                {traveler.travelInsurances.length > 1 ? (
                  <div className='text-right'>
                    <Button
                      key={k}
                      small
                      link={this.isReserved(traveler.travelInsurances)}
                      nomargin
                      style={{
                        color: this.isReserved(traveler.travelInsurances)
                          ? '#47a404'
                          : 'rgb(8, 154, 208)',
                        borderColor: 'rgb(8, 154, 208)'
                      }}
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          showDialog: traveler.id
                        })
                      }
                    >
                      {this.renderButtonText(traveler.travelInsurances)} »
                    </Button>
                    {this.state.showDialog === traveler.id && (
                      <TravelInsuranceDialog
                        title={Y`Travel insurance`}
                        onClose={() =>
                          this.setState({ ...this.state, showDialog: null })
                        }
                        traveler={traveler}
                        onSelect={(value, travelInsuranceId) => {
                          this.handleSelectTravelInsurance(
                            value,
                            travelInsuranceId,
                            traveler.id
                          )
                        }}
                      />
                    )}
                  </div>
                ) : (
                  traveler.travelInsurances.length >= 0 && (
                    <Switch
                      disabled={traveler.travelInsurances[0].blocked}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor='#b4b4b4'
                      onChange={value =>
                        this.handleSelectTravelInsurance(
                          value,
                          traveler.travelInsurances[0].id,
                          traveler.id
                        )
                      }
                      checked={traveler.travelInsurances[0].reserved}
                      onColor='#47a404'
                      id='normal-switch'
                      height={22}
                      width={48}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    />
                  )
                )}
              </Row>
            </ListItem>
          ))}
        </List>
      </Card>
    )
  }
}

TravelInsuranceList = connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelInsuranceList)
export default TravelInsuranceList
