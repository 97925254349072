import React, { Component } from 'react'
import Swipe from '../swipe'
import PropTypes from 'prop-types'
import { CarouselItem } from './carousel-item'
import { Row } from 'jsxstyle'
import { IconButton } from '../icon'
import './carousel.css'

export class Carousel extends Component {
  constructor (props) {
    super(props)
    this.state = { currentPos: props.position, totalSlides: null }
  }

  next = () => {
    this.refs.carousel.next()
    this.setState({ ...this.state, currentPos: this.refs.carousel.getPos() })
  }

  prev = () => {
    this.refs.carousel.prev()
    this.setState({ ...this.state, currentPos: this.refs.carousel.getPos() })
  }

  slide = position => {
    this.setState({ ...this.state, currentPos: position })
    this.refs.carousel.slide(position, 0)
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevState.totalSlides && this.refs.carousel) {
      this.setState({ ...this.state, totalSlides: this.refs.carousel.getNumSlides() })
    }
  }

  render () {
    return (
      <div className='carousel' style={this.props.style}>
        <Row className='carousel-content'>
          <IconButton
            style={{ visibility: this.state.currentPos > 0 ? 'initial' : 'hidden' }}
            color='rgb(151, 151, 151)'
            size='24px'
            onClick={this.prev}
          >
            chevron_left
          </IconButton>
          <Swipe
            ref='carousel'
            swipeOptions={{
              continuous: false,
              callback: index => this.props.onSlideChange(index),
              startSlide: this.state.currentPos
            }}
          >
            {this.props.children}
          </Swipe>
          <IconButton
            style={{ visibility: this.state.currentPos < this.state.totalSlides - 1 ? 'initial' : 'hidden' }}
            color='rgb(151, 151, 151)'
            size='24px'
            onClick={this.next}
          >
            chevron_right
          </IconButton>
        </Row>
      </div>
    )
  }
}

Carousel.propTypes = {
  position: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(CarouselItem).isRequired,
  onSlideChange: PropTypes.func
}
