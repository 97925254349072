import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Col, InlineBlock, Box } from 'jsxstyle'
import Button from '../../../components/button'
import { USPListItem } from '../../../components/list'
import Y from '../../../y18n'
import './hotel-usp.css'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelUsp extends PureComponent {
  state = { showMore: false }

  componentDidMount () {
    this.setWidth()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = e => this.setWidth()

  setWidth () {
    this.setState({ ...this.state, width: window.innerWidth })
  }

  render () {
    const { proposal } = this.props
    if (!proposal || !proposal.hotel.info.usps) return null
    return (
      <div className='husp'>
        <h4>
          {Y`Why you should choose`} {proposal.hotel.info.name}
        </h4>
        <Box
          component='ul'
          padding='0'
          columns={`${this.state.width >= 992 ? 2 : 1} auto`}
          columnRule='1px solid #ebebeb'
          marginBottom={0}
        >
          {proposal.hotel.info.usps
            .filter((f, i) => (this.state.width <= 992 && !this.state.showMore ? i < 3 : true))
            .map((usp, i) => (
              <USPListItem key={i}>{usp}</USPListItem>
            ))}
        </Box>
        {this.state.width <= 992 && (
          <Col alignItems='center' marginBottom='.9375rem'>
            <Button
              link
              onClick={e => {
                let element = e.target
                this.setState({ ...this.state, showMore: !this.state.showMore }, () => {
                  if (!this.state.showMore) {
                    element.scrollIntoView({ behavior: 'instant' })
                  }
                })
              }}
            >
              {[
                <InlineBlock key={1} paddingRight='4px'>
                  {this.state.showMore ? Y`Show less` : Y`Show more`}{' '}
                </InlineBlock>,
                <InlineBlock key={2} component='span' transform={`rotate(${this.state.showMore ? '-90deg' : '90deg'})`}>
                  »
                </InlineBlock>
              ]}
            </Button>
          </Col>
        )}
      </div>
    )
  }
}

HotelUsp = connect(mapStateToProps)(HotelUsp)
export default HotelUsp
