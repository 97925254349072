import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'jsxstyle'

export const CarouselItem = ({ children, ...rest }) => (
  <Row className='carousel-item' {...rest}>{children}</Row>
)

CarouselItem.propTypes = {
  children: PropTypes.node.isRequired
}
