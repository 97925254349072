import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'
import api from '../api'
import { toast } from 'react-toastify'
import Y from '../../y18n'

const initialState = {
  loading: false,
  paymentSucceded: null,
  message: null,
  embeddedUrl: null,
  paymentType: null
}

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    QUERY_TRANSACTION_ID: (state, action) => {
      state.paymentType = action.payload
    },
    QUERY_TRANSACTION_ID_SUCCESS: (state, action) => {
      state.embeddedUrl = action.payload.data.embeddedUrl
    },
    PROCESS_CARD_PAYMENT: state => {
      state.loading = true
    },
    PROCESS_CARD_PAYMENT_SUCCESS: state => {
      state.loading = false
      state.paymentSucceded = true
    },
    PROCESS_CARD_PAYMENT_ERROR: state => {
      state.loading = false
      state.paymentSucceded = false
    },
    RESET_CARD_TRANSACTION: state => {
      state.embeddedUrl = null
    }
  }
})

export const {
  QUERY_TRANSACTION_ID,
  QUERY_TRANSACTION_ID_SUCCESS,
  PROCESS_CARD_PAYMENT,
  PROCESS_CARD_PAYMENT_SUCCESS,
  PROCESS_CARD_PAYMENT_ERROR,
  RESET_CARD_TRANSACTION
} = paymentSlice.actions

export default paymentSlice.reducer

// thunks
export const submitCardTransaction = (
  reservationId,
  amount,
  currencyCode,
  type,
  marketId,
  reservationCode,
  customerInfo,
  terminalId
) => {
  return dispatch => {
    let id = uuidv4()
    const model = {
      id,
      marketId,
      amount,
      currencyId: currencyCode,
      reservationId,
      reservationCode,
      paymentType: type ? type : 'creditCard',
      redirectUrl: `${window.location.protocol}//${window.location.host}/reservation/${reservationId}`,
      customerInfo,
      terminalId
    }

    return api.sendCommand('altapay/initiatepayment', model).then(res => {
      dispatch(queryTransactionId(id, null))
    })
  }
}

export const queryTransactionId = (id, type) => {
  return dispatch => {
    dispatch(QUERY_TRANSACTION_ID(type))
    return api
      .query('travelbooking/cardPaymentTransaction/transactionId', { id })
      .then(res => {
        dispatch(QUERY_TRANSACTION_ID_SUCCESS(res))
      })
  }
}

export function syncPayments (reservationId) {
  const command = {
    reservationId
  }
  return dispatch => {
    return api.sendCommand('travelbooking/cardpaymenttransaction/altapay/syncpayments', command)
  }
}

export function submitProcessCardPayment (
  id,
  transactionId,
  responseCode,
  errorMessage
) {
  return function (dispatch, getState) {
    if (responseCode === 'OK') {
      dispatch(PROCESS_CARD_PAYMENT_SUCCESS())
    } else {
      if (errorMessage) {
        toast.error(errorMessage)
      } else {
        toast.error(Y`Something went wrong`)
      }
      dispatch(PROCESS_CARD_PAYMENT_ERROR())
    }
  }
}

export function resetCurrentTransaction () {
  return function (dispatch) {
    dispatch(RESET_CARD_TRANSACTION())
  }
}
