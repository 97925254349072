import React from 'react'
import PropTypes from 'prop-types'
import './list.css'

export const List = ({ className = '', children, row = false, ...rest }) => {
  return (
    <ul className={`list ${className} ${row ? 'row' : ''}`} {...rest}>
      {children}
    </ul>
  )
}

List.propTypes = {
  row: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
}

export default List
