import React from 'react'
import { Box, Row, InlineBlock } from 'jsxstyle'
import moment from 'moment-timezone'
import { Icon } from '../../../components/icon'
import './flight-info.css'

const FlightInfo = ({ flight, className }) => {
  return flight
    ? <InlineBlock className={`flight-info ${className}`}>
      <Row alignItems='center' paddingBottom='2px' borderBottom='1px solid rgb(205,205,205)'>
        <Icon>flight_takeoff</Icon>
        <Box>
          <p className='nomargin caption'>
            {moment.parseZone(flight.outwardFlights[0].departureDateTime).format('HH:mm')}
          </p>
        </Box>
      </Row>
      <Row alignItems='center'>
        <Icon style={{ transform: 'scaleX(-1)' }}>flight_takeoff</Icon>
        <Box>
          <p className='nomargin caption'>
            {moment.parseZone(flight.homewardFlights[0].departureDateTime).format('HH:mm')}
          </p>
        </Box>
      </Row>
    </InlineBlock>
    : null
}

export default FlightInfo
