import React, { Component } from 'react'
import { Page, Layout, Content } from '../../components/layout'
import HotelList from './hotel-list'
import Summary from '../../components/summary'
import ProposalSummary from './proposal-summary'
import DateCarousel from './date-carousel'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import HotelFilterSort from './hotel-filter-sort'
import Search from '../../containers/search'
import FlightList from './flight-list'
import withReservationId from '../../containers/with-reservation-id'
import * as proposalActions from '../../store/ducks/proposal'
import * as reservationActions from '../../store/ducks/reservation'

const mapStateToProps = state => ({
  reservation: state.reservation.summary,
  reservationId: state.reservation.reservationId,
  search: state.reservation.search
})
const mapDispatchToProps = dispatch => ({
  proposalActions: bindActionCreators(proposalActions, dispatch),
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class ProposalPage extends Component {
  dateCarousel = React.createRef()
  state = { showSearch: false }

  componentDidMount () {
    this.props.reservationActions
      .queryReservationSummary(this.props.reservationId)
      .then(() =>
        this.props.proposalActions.queryProposal(this.props.reservationId)
      )
  }

  handleFilterChange = (value = null) => {
    let carousel = this.dateCarousel.current
    this.props.proposalActions.resetProposals()
    carousel.reset().then(() => {
      this.props.proposalActions.queryProposal(this.props.reservationId)
      if (value) {
        carousel.setActiveDateAndPosition(
          value.outwardDateFrom,
          value.homewardDateFrom
        )
      }
    })
  }

  handleToggleShowSearch () {
    this.setState({ ...this.state, showSearch: !this.state.showSearch })
  }

  render () {
    const { search } = this.props
    return (
      <Page>
        <Layout>
          {this.state.showSearch && (
            <Search
              hideTravelType
              onSubmitted={command => {
                this.handleFilterChange(command)
                this.handleToggleShowSearch()
              }}
            />
          )}
          {!this.state.showSearch && (
            <Summary sticky>
              <ProposalSummary
                onSearch={() => {
                  window.scrollTo(0, 0)
                  this.handleToggleShowSearch()
                }}
              />
            </Summary>
          )}
          <Content>
            <DateCarousel ref={this.dateCarousel} />
            {search.travelType === 'Charter' ||
            search.travelType === 'Hotel' ? (
              <HotelFilterSort onChange={this.handleFilterChange} />
            ) : null}
            {search.travelType === 'Charter' ||
            search.travelType === 'Hotel' ? (
              <HotelList
                charterPeriodId={search.charterPeriodId}
                onSoldOut={(out, home, soldOutDate) => {
                  this.dateCarousel.current.handleButtonClick({
                    out,
                    home,
                    dontResetSoldOutDate: true
                  })
                  this.props.proposalActions.setSoldOutDate(soldOutDate)
                }}
                onNewSearch={() => {
                  window.scrollTo(0, 0)
                  this.handleToggleShowSearch()
                }}
              />
            ) : null}
            {search.travelType === 'Flight' && (
              <FlightList
                charterPeriodId={search.charterPeriodId}
                onSoldOut={(out, home, soldOutDate) => {
                  this.dateCarousel.current.handleButtonClick({
                    out,
                    home,
                    dontResetSoldOutDate: true
                  })
                  this.props.proposalActions.setSoldOutDate(soldOutDate)
                }}
                onNewSearch={() => {
                  window.scrollTo(0, 0)
                  this.handleToggleShowSearch()
                }}
              />
            )}
          </Content>
        </Layout>
      </Page>
    )
  }
}

ProposalPage = withReservationId(ProposalPage)
ProposalPage = connect(mapStateToProps, mapDispatchToProps)(ProposalPage)
export default ProposalPage
