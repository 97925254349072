import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import * as skiLuggageActions from '../../../store/ducks/skiluggage'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  skiLuggage: state.skiLuggage.items
})
const mapDispatchToProps = dispatch => ({
  skiLuggageActions: bindActionCreators(skiLuggageActions, dispatch)
})

class SkiLuggageList extends Component {
  componentDidMount () {
    this.props.skiLuggageActions.querySkiLuggage(this.props.reservationId)
  }

  handleRunNextTraveler = travelers => {
    if (travelers.length) {
      let traveler = travelers.shift()
      this.props.skiLuggageActions
        .submitAddSkiLuggage({
          reservationId: this.props.reservationId,
          travelerId: traveler.id
        })
        .then(() => {
          this.handleRunNextTraveler(travelers)
        })
    }
  }

  handleSelectTransfer (value, travelerId, index) {
    let reservationId = this.props.reservationId
    if (value) {
      this.props.skiLuggageActions.submitAddSkiLuggage({
        reservationId,
        travelerId
      })

      // if first traveler, add to every one else if there is room
      if (
        index === 0 &&
        this.props.skiLuggage[0].skiLuggage.allotmentLeft >=
          this.props.skiLuggage.length
      ) {
        this.props.skiLuggage
          .filter((t, i) => i !== 0 && !t.skiLuggage.reserved)
          .forEach(traveler =>
            this.props.skiLuggageActions.submitAddSkiLuggage({
              reservationId,
              travelerId: traveler.id
            })
          )
      }
    } else
      this.props.skiLuggageActions.submitRemoveSkiLuggage({
        reservationId,
        travelerId
      })
  }

  render () {
    const { skiLuggage } = this.props
    if (!skiLuggage.length || !skiLuggage.some(sl => sl.skiLuggage !== null))
      return null

    const isFinnair = skiLuggage.every(fm => fm.airline === 'AY')

    return (
      <Card id='SkiLuggage'>
        <CardHeader>
          <CardTitle>{Y`Ski luggage`}</CardTitle>
          <p>{Y`Ski luggage text`}</p>
          {skiLuggage.length > skiLuggage[0].skiLuggage.allotmentLeft &&
            skiLuggage[0].skiLuggage.allotmentLeft > 0 && (
              <p className='caption warn nomargin'>{Y`There is only ${skiLuggage[0].skiLuggage.allotmentLeft} available allotments for Ski luggage left`}</p>
            )}
        </CardHeader>
        <List className='no-odd border'>
          {skiLuggage.map((traveler, i) => {
            if (!traveler.skiLuggage) return null
            return (
              <ListItem key={traveler.id}>
                <Row justifyContent='space-between' alignItems='center'>
                  <div>
                    <Row width='100%'>
                      <h3 className='nomargin'>
                        {/^\s+$/.test(traveler.name)
                          ? traveler.age !== null && traveler.age < 18
                            ? `${Y`Child`} ${traveler.age} ${Y`year`}`
                            : Y`Adult` + ' ' + traveler.id
                          : traveler.name}
                        -
                      </h3>
                      {!isFinnair && (
                        <p style={{ margin: 0 }}>
                          <Currency>{traveler.skiLuggage.priceAmount}</Currency>
                        </p>
                      )}
                    </Row>
                    <p className='caption'>{traveler.skiLuggage.description}</p>
                  </div>
                  {isFinnair ? (
                    <p className='nomargin'>Varataan erikseen Finnairilta</p>
                  ) : traveler.skiLuggage.allotmentLeft === null ? (
                    <p className='warn nomargin'>{Y`Not available`}</p>
                  ) : !traveler.skiLuggage.reserved &&
                    traveler.skiLuggage.allotmentLeft <=
                      skiLuggage.reduce(
                        (acc, i) =>
                          acc + (i.skiLuggage && i.skiLuggage.reserved ? 1 : 0),
                        0
                      ) ? (
                    <p className='warn nomargin'>{Y`Sold out`}</p>
                  ) : (
                    <Switch
                      disabled={traveler.skiLuggage.blocked}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor='#b4b4b4'
                      onChange={value =>
                        this.handleSelectTransfer(value, traveler.id, i)
                      }
                      checked={traveler.skiLuggage.reserved}
                      onColor='#47a404'
                      id='normal-switch'
                      height={22}
                      width={48}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    />
                  )}
                </Row>
              </ListItem>
            )
          })}
        </List>
      </Card>
    )
  }
}

SkiLuggageList = connect(mapStateToProps, mapDispatchToProps)(SkiLuggageList)
export default SkiLuggageList
