import React from 'react'
import PropTypes from 'prop-types'
import './card-header.css'

export const CardHeader = ({ children, ...rest }) => {
  return (
    <div className='card-header' {...rest}>
      {children}
    </div>
  )
}

CardHeader.propTypes = {
  children: PropTypes.node
}

export default CardHeader
