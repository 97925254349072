import React from 'react'
import PropTypes from 'prop-types'
import './select.css'

const Select = ({ value, label = null, required = false, hideLabel = false, onChange, children, style, ...rest }) => {
  let id = Math.random().toString(36).substring(7)
  let requiredLabel = required ? ' *' : ''
  let selectLabel = label
    ? <label htmlFor={id} className={hideLabel ? 'hidden' : ''}>{label}{requiredLabel}</label>
    : null

  const handleChange = e => {
    if (typeof onChange === 'function') onChange(e.target.value)
  }

  return (
    <div className='select-wrapper' style={style}>
      {selectLabel}
      <select onChange={handleChange} id={id} required={required} value={value} {...rest}>
        {children}
      </select>
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any.isRequired,
  style: PropTypes.object,
  children: PropTypes.node
}

export default Select
