import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary }from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const cancellationInsurancesSlice = createSlice({
  name: 'cancellationInsurances',
  initialState,
  reducers: {
    QUERY_CANCELLATION_INSURANCE: (state) => {
      state.loading = true
      state.error = null
    },
    QUERY_CANCELLATION_INSURANCE_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_CANCELLATION_INSURANCE: (state, action) => {
      state.items = state.items.map(
        (traveler, i) =>
          (traveler.id === action.payload.travelerId
            ? {
              ...traveler,
              cancellationInsurance: { ...traveler.cancellationInsurance, reserved: action.payload.reserved }
            }
            : traveler)
      )
    }
  }
})

export const { QUERY_CANCELLATION_INSURANCE, QUERY_CANCELLATION_INSURANCE_SUCCESS, SET_CANCELLATION_INSURANCE } = cancellationInsurancesSlice.actions

export default cancellationInsurancesSlice.reducer

// thunks
export const queryCancellationInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/cancellationInsurance', { reservationId })
      .then(res => dispatch(QUERY_CANCELLATION_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddCancellationInsurance = command => {
  return dispatch => {
    dispatch(SET_CANCELLATION_INSURANCE({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addCancellationInsurance', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}

export const submitRemoveCancellationInsurance = command => {
  return dispatch => {
    dispatch(SET_CANCELLATION_INSURANCE({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeCancellationInsurance', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
