let store

export function setStore (x) {
  store = x
}

export default function Y () {
  const config = store ? store.getState().locales.locale : {}
  let str = ''
  let args = arguments
  args[0].forEach(function (part, i) {
    const arg = args[i + 1]
    str += part
    if (typeof arg !== 'undefined') {
      str += '%s'
    }
  })

  if (!config) {
    return ''
  }

  const value = config[str] || '--- MISSING LOCALE ---'
  let count = 1
  return value.replace(/%s/g, x => args[count++])
}
