import React from 'react'
import { Icon } from '../icon'
import { Row } from 'jsxstyle'
import './usp-list-item.css'

export const USPListItem = ({ children, ...rest }) => (
  <Row component='li' alignItems='flex-start' className='usp-list-item' {...rest}>
    <Icon color='rgb(71, 157, 1)'>done</Icon> {children}
  </Row>
)

export default USPListItem
