import { createSlice } from '@reduxjs/toolkit'
import { queryReservationSummary } from './reservation'
import api from '../api'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const supplementsSlice = createSlice({
  name: 'supplements',
  initialState,
  reducers: {
    QUERY_SUPPLEMENTS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_SUPPLEMENT: (state, action) => {
      state.items = state.items.map(supplement =>
        supplement.generalArticleId === action.payload.generalId
          ? {
              ...supplement,
              reserved: action.payload.reserved
            }
          : supplement
      )
    }
  }
})

export const { QUERY_SUPPLEMENTS, SET_SUPPLEMENT } = supplementsSlice.actions

export default supplementsSlice.reducer

// thunks
export const querySupplements = reservationId => {
  return dispatch => {
    return api
      .query('travelbooking/supplements', { reservationId })
      .then(res => dispatch(QUERY_SUPPLEMENTS(res.data)))
  }
}

export const submitAddSupplement = command => {
  return dispatch => {
    dispatch(SET_SUPPLEMENT({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addSupplement', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}

export const submitRemoveSupplement = command => {
  return dispatch => {
    dispatch(SET_SUPPLEMENT({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeSupplement', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
