import React, { Component } from 'react'
import Button from '../components/button'
import { Dialog, DialogContent, DialogButtons } from '../components/dialog'
import { CardContent } from '../components/card'
import Currency from './currency'
import { List, ListItem } from '../components/list'
import { Icon } from '../components/icon'
import Input from '../components/input'
import { Row, Box } from 'jsxstyle'
import Api from '../store/api'
import Y from '../y18n'

const small = { sm: 'screen and (max-width: 380px)' }
class PaymentAlternativesDialog extends Component {
  state = { selected: null, amount: null, terminals: [] }

  componentDidMount () {
    let queryParams
    if (this.props.useTestTerminal) {
      queryParams = {
        useTestTerminals: this.props.useTestTerminal
      }
    } else {
      queryParams = {
        marketId: this.props.marketId
      }
    }
    Api.query('altaPay/terminals', queryParams).then(res => {
      this.setState({
        ...this.state,
        amount: this.props.amount,
        terminals: res.data,
        selected: res.data[0] ?? null
      })
    })
  }

  renderCardPayment (terminal) {
    return (
      <ListItem
        key={terminal.id}
        className='clickable'
        onClick={() => this.setState({ ...this.state, selected: terminal })}
      >
        <Row
          justifyContent='flex-start'
          alignItems='flex-start'
          mediaQueries={small}
          smFlexDirection='column'
        >
          <Row>
            {this.state.selected?.id === terminal.id ? (
              <Icon color='#47a404' size={30}>
                check_circle
              </Icon>
            ) : (
              <Icon color='rgb(194,194,194)' size={30}>
                radio_button_unchecked
              </Icon>
            )}
            <Box paddingLeft='.9375rem' paddingRight='.5rem'>
              <h3 style={{ fontWeight: 'bold' }}>{Y`Card`}</h3>
              <p className='nomargin'>{Y`Credit or debit card`}</p>
            </Box>
          </Row>
          <Row>
            <img
              className='payment-logo cc'
              src='/img/visa_logo.png'
              alt='Visa logo'
            />
            <img
              className='payment-logo cc'
              src='/img/mastercard_logo.png'
              alt='Mastercard logo'
            />
          </Row>
        </Row>
      </ListItem>
    )
  }

  renderTerminals (marketId) {
    if (!this.state.terminals) {
      return null
    }

    return this.state.terminals.map(t =>
      t.paymentType === 'CreditCard' ? (
        this.renderCardPayment(t)
      ) : (
        <ListItem
          key={t.name}
          className='clickable'
          onClick={() => this.setState({ ...this.state, selected: t })}
        >
          <Row
            justifyContent='flex-start'
            alignItems='flex-start'
            mediaQueries={small}
            smFlexDirection='column'
          >
            <Row>
              {this.state.selected?.id === t.id ? (
                <Icon color='#47a404' size={30}>
                  check_circle
                </Icon>
              ) : (
                <Icon color='rgb(194,194,194)' size={30}>
                  radio_button_unchecked
                </Icon>
              )}
              <Box padding='0 .9375rem'>
                <h3 style={{ fontWeight: 'bold' }}>
                  {t.displayNames[marketId] ?? t.paymentType}
                </h3>
                {/* <p className='nomargin'>{t.paymentType}</p> */}
              </Box>
            </Row>
            {this.renderPaymentLogo(t.paymentType)}
          </Row>
        </ListItem>
      )
    )
  }

  renderPaymentLogo (paymentType) {
    if (paymentType === 'Swish') {
      return (
        <Row>
          <img className='payment-logo' alt='swish' src='/img/swish_logo.png' />
        </Row>
      )
    }
    // if (paymentType === 'MobilePay') {
    //   return (
    //     <Row>
    //       <img
    //         className='payment-logo'
    //         alt='swish'
    //         src='/img/mobilepay_logo.png'
    //       />
    //     </Row>
    //   )
    // }
    if (paymentType === 'Vipps') {
      return (
        <Row>
          <img className='payment-logo' alt='vipps' src='/img/vipps_logo.png' />
        </Row>
      )
    }
    return null
  }

  render () {
    const {
      onClose,
      onPay,
      currency,
      maxAmountToPay,
      minAmountToPay,
      marketId
    } = this.props

    if (!this.state.terminals) {
      return null
    }
    return (
      <Dialog show onClose={onClose} title={Y`Payment options`}>
        <form
          onSubmit={e => {
            e.preventDefault()
            onPay(
              this.state.selected.paymentType,
              this.state.amount,
              this.state.selected.id
            )
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 50px)'
          }}
        >
          <DialogContent>
            <CardContent>
              <Input
                type='number'
                autoFocus
                disabled={minAmountToPay === maxAmountToPay}
                min={Math.ceil(minAmountToPay)}
                max={Math.ceil(maxAmountToPay)}
                label={`${Y`Amount`} (${currency})`}
                required
                value={this.state.amount || ''}
                onChange={value =>
                  this.setState({ ...this.state, amount: value })
                }
              />
              {minAmountToPay !== maxAmountToPay && (
                <p className='caption nomargin'>
                  {Y`Minimum amount to pay`}:{' '}
                  <Currency>{minAmountToPay}</Currency>,{' '}
                  {Y`Maximum amount to pay`}:{' '}
                  <Currency>{maxAmountToPay}</Currency>
                </p>
              )}
            </CardContent>
            <List className='no-odd'>{this.renderTerminals(marketId)}</List>
          </DialogContent>
          <DialogButtons>
            <Button cy-id='pay-button' type='submit' primary>{Y`Pay`}</Button>
          </DialogButtons>
        </form>
      </Dialog>
    )
  }
}

export default PaymentAlternativesDialog
