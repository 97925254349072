import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Box, Block } from 'jsxstyle'
import Button from '../../../components/button'
import Skeleton, { SkeletonTheme } from '../../../components/skeleton'
import Y from '../../../y18n'
import './proposal-summary.css'
import isAdult from '../../../utils/is-adult'

class ProposalSummary extends Component {
  render () {
    const { searchInfo, period } = this.props
    let travelers = searchInfo
      ? searchInfo.rooms.length
        ? Array.prototype.concat.apply([], searchInfo.rooms.map(r => r.travelers))
        : searchInfo.flightTravelers
      : []

    return (
      <Row alignItems='center' className='proposal-res-summary'>
        <Box flex={1}>
          <SkeletonTheme color='rgb(255, 236, 120)'>
            <h4>
              {searchInfo ? (
                searchInfo.destination
              ) : (
                <Box width='60%'>
                  <Skeleton />
                </Box>
              )}
            </h4>
            <Box component='p'>
              {searchInfo && period ? (
                `${searchInfo.airport ? `${Y`From`} ${searchInfo.airport}, ` : ''}${
                  travelers.filter(t => isAdult(t.age)).length
                } ${Y`adults`}${
                  travelers.filter(t => !isAdult(t.age)).length
                    ? `, ${travelers.filter(t => !isAdult(t.age)).length} ${Y`children`}`
                    : ''
                }${searchInfo.rooms.length ? `, ${searchInfo.rooms.length} ${Y`rooms`}` : ''}`
              ) : (
                <Block component='span' width='40%'>
                  <Skeleton />
                </Block>
              )}
            </Box>
          </SkeletonTheme>
        </Box>
        <Button onClick={this.props.onSearch}>
          {Y`Change`} {Y`searching`}
        </Button>
      </Row>
    )
  }
}

const mapStateToProps = state => ({ searchInfo: state.proposal.searchInfo, period: state.proposal.period })
ProposalSummary = connect(mapStateToProps)(ProposalSummary)
export default ProposalSummary
