import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import { Row, Box } from 'jsxstyle'
import { Dialog } from '../../components/dialog'
import Button from '../../components/button'
import Skeleton, { SkeletonTheme } from '../../components/skeleton'
import Y from '../../y18n'
import Currency from '../currency'
import ReservationSummaryDialog from '../reservation-summary-dialog'
import * as reservationActions from '../../store/ducks/reservation'
import './short-reservation-summary.css'
import ConfirmBox from '../confirm-box'
import isAdult from '../../utils/is-adult'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  summary: state.reservation.summary
})
const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class ShortReservationSummary extends Component {
  state = { showConfirmDialog: false }

  componentDidMount () {
    let reservationId = this.props.reservationId || this.props.match.params.id
    this.props.reservationActions.queryReservationSummary(reservationId)

    // window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  // componentWillUnmount () {
  //   window.removeEventListener('beforeunload', this.onBeforeUnload)
  // }

  handleRollback () {
    if (window.confirm(Y`Are you sure you want to cancel?`)) {
      let reservationId = this.props.reservationId || this.props.match.params.id
      this.props.reservationActions
        .submitRollbackReservation({ reservationId })
        .then(() => window.location.reload())
    }
  }

  onBeforeUnload = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  getGroupedRooms (rooms) {
    let groupedRooms = []
    rooms.forEach(room => {
      let existingIndex = groupedRooms.findIndex(
        x => x.categoryId === room.categoryId
      )
      if (existingIndex > -1) {
        groupedRooms[existingIndex].number =
          groupedRooms[existingIndex].number + 1
      } else {
        groupedRooms.push({ ...room, number: 1 })
      }
    })

    return groupedRooms
  }

  renderRoomDescriptions (rooms) {
    const groupedRooms = this.getGroupedRooms(rooms)
    const isSameTypeOfRoom = groupedRooms.length === 1

    return groupedRooms.map(
      (room, i) =>
        `${!isSameTypeOfRoom || room.number > 1 ? room.number : ''} ${
          room.description || room.categoryId
        }${i < groupedRooms.length - 1 ? ', ' : ''}`
    )
  }

  renderWithChanges (summary) {
    return (
      <div className='short-res-summary'>
        <Row justifyContent='space-between' alignItems='center'>
          <Box
            component='h4'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
          >
            {Y`Upsells`}
          </Box>

          <Box
            component='h4'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
          >
            <Currency>
              {summary.status === 'Confirmed' ? 0 : summary.upsellPrice}
            </Currency>
          </Box>
        </Row>
        {summary.status === 'ConfirmedWithChanges' && (
          <Row
            className='short-res-summary'
            marginTop='.9375rem'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              component='p'
              fontStyle='italic'
            >{Y`You have made changes to the booking`}</Box>
            <Row>
              <Button
                onClick={() => this.handleRollback()}
                style={{
                  margin: 0,
                  marginRight: '.4687rem',
                  marginLeft: '.4687rem'
                }}
                link
              >
                {Y`Cancel`}
              </Button>
              <Button
                onClick={() =>
                  this.setState({ ...this.state, showConfirmDialog: true })
                }
                style={{ margin: 0 }}
                small
                primary
              >
                {Y`Confirm`}
              </Button>
            </Row>
          </Row>
        )}
        {this.state.showConfirmDialog && (
          <Dialog
            show
            onClose={() =>
              this.setState({ ...this.state, showConfirmDialog: false })
            }
            title={Y`Confirm my booking`}
          >
            <ConfirmBox
              style={{ margin: 0, background: '#fff' }}
              ignoreCustomerForm
              onCancel={() =>
                this.setState({ ...this.state, showConfirmDialog: false })
              }
            />
          </Dialog>
        )}
      </div>
    )
  }

  renderStandard (summary, hideLink) {
    const hotel =
      summary.hotels && summary.hotels.length ? summary.hotels[0] : null
    const outwardFlight = summary.charter ? summary.charter.flights[0] : null
    const price = summary.price
    const travelers = summary.travelers

    return (
      <React.Fragment>
        <Row
          justifyContent='space-between'
          alignItems='center'
          className='short-res-summary'
        >
          <Box minWidth={0}>
            {hotel && (
              <Box
                component='h4'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {hotel.name}, {hotel.destinationDescription}
              </Box>
            )}
            {!hotel && outwardFlight ? (
              <Box
                component='h4'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {outwardFlight.destinationAirport}
              </Box>
            ) : null}
            <p>
              {moment.parseZone(summary.firstTravelDate).format('ddd D MMM')},{' '}
              {moment
                .parseZone(summary.lastTravelDate)
                .startOf('day')
                .diff(
                  moment.parseZone(summary.firstTravelDate).startOf('day'),
                  'days'
                ) + 1}{' '}
              {Y`days`}
            </p>
            <p>
              {`${
                outwardFlight
                  ? `${Y`From`} ${outwardFlight.originAirport}, `
                  : ''
              } ${travelers.filter(t => isAdult(t.age)).length} ${Y`adults`}${
                travelers.filter(t => !isAdult(t.age)).length
                  ? `, ${
                      travelers.filter(t => !isAdult(t.age)).length
                    } ${Y`children`}`
                  : ''
              }`}
            </p>
            {hotel && (
              <Box
                component='p'
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
              >
                {this.renderRoomDescriptions(hotel.rooms)}
              </Box>
            )}
          </Box>
          <Box paddingLeft='.9375rem' textAlign='center'>
            <p className='caption'>{Y`Total price`}</p>
            <h4>
              <Currency>{price}</Currency>
            </h4>
            {!hideLink && <ReservationSummaryDialog />}
          </Box>
        </Row>
      </React.Fragment>
    )
  }

  render () {
    const { summary, hideLink } = this.props
    if (!summary) {
      return (
        <SkeletonTheme color='rgb(255, 236, 120)'>
          <Box width='60%'>
            <Skeleton />
          </Box>
          <Box width='40%'>
            <Skeleton />
          </Box>
          <Box width='40%'>
            <Skeleton />
          </Box>
        </SkeletonTheme>
      )
    }

    if (
      summary.status === 'Confirmed' ||
      summary.status === 'ConfirmedWithChanges'
    ) {
      return this.renderWithChanges(summary)
    } else {
      return this.renderStandard(summary, hideLink)
    }
  }
}

ShortReservationSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShortReservationSummary)
export default withRouter(ShortReservationSummary)
