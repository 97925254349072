import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row } from 'jsxstyle'
import { withRouter } from 'react-router-dom'
import Button from '../../components/button'
import BackButton from '../../containers/back-button'
import Y from '../../y18n'
import isAgeValid from './is-age-valid'
import * as customerActions from '../../store/ducks/customer'

const mapStateToProps = state => ({
  reservation: state.reservation.summary,
  travelers: state.travelers.data
})

const mapDispatchToProps = dispatch => ({
  customerActions: bindActionCreators(customerActions, dispatch)
})

class NextButton extends Component {
  handleNext () {
    if (this.isValid()) this.props.history.push(`/addons/${this.props.reservation.id}`)
    else {
      let forms = document.querySelectorAll('.traveler-form')
      for (let index = 0; index < forms.length; index++) {
        const form = forms[index]
        if (!form.checkValidity()) {
          form.querySelector('button#submit').click()
          break
        }
      }
    }
  }

  isValid () {
    const { travelers, reservation } = this.props
    if (!reservation) return null

    let allOk = true

    if (reservation.search.travelType === 'Flight') {
      for (let i = 0; i < travelers.length; i++) {
        const traveler = travelers[i]
        if (
          !traveler.firstName ||
          !traveler.lastName ||
          !traveler.genderCode ||
          !traveler.birthDate ||
          !isAgeValid(traveler, reservation)
        ) {
          allOk = false
          break
        }
      }
    } else {
      for (let i = 0; i < travelers.length; i++) {
        const room = travelers[i]
        for (let j = 0; j < room.travelers.length; j++) {
          const traveler = room.travelers[j]
          if (
            !traveler.firstName ||
            !traveler.lastName ||
            !traveler.genderCode ||
            !traveler.birthDate ||
            !isAgeValid(traveler, reservation)
          ) {
            allOk = false
            break
          }
        }
      }
    }

    return allOk
  }

  render () {
    if (!this.props.travelers.length) return null
    return (
      <Row
        justifyContent={!this.props.reservation.isEditable ? 'space-between' : 'flex-end'}
        smAlignItems='center'
        smFlexDirection='column-reverse'
        mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
      >
        {!this.props.reservation.isEditable && <BackButton />}
        <Button onClick={() => this.handleNext()} primary cy-id='next-button-travelers'>
          {Y`Next`} »
        </Button>
      </Row>
    )
  }
}

NextButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(NextButton)
export default withRouter(NextButton)
