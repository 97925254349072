import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row } from 'jsxstyle'
import Info from '../../../components/info'
import Y from '../../../y18n'
import './hotel-ratings.css'

const mapStateToProps = state => ({
  market: state.market.market,
  proposal: state.roomProposal.item
})

class HotelRatings extends PureComponent {
  render () {
    const { proposal } = this.props
    if (!proposal) return null

    return (
      <Row
        justifyContent='space-between'
        padding='0 0 .9375rem 0'
        smPadding='.9375rem 0'
        smBorderTop='1px solid rgb(238,238,238)'
        mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
        className='hratings'
      >
        {proposal.hotel.info.guestRating && (
          <Info label={Y`Customer rating`} caption={`${proposal.hotel.info.noOfGuestRatings} ${Y`reviews`}`}>
            {`${proposal.hotel.info.guestRating} / 5`}
          </Info>
        )}

        {proposal.hotel.info.tripAdvisor && (
          <Info
            label={Y`Rating on TripAdvisor`}
            caption={`${proposal.hotel.info.tripAdvisor.num_reviews} ${Y`reviews`}`}
          >
            <a href={proposal.hotel.info.tripAdvisor.web_url} target='_blank' rel='noopener noreferrer'>
              <img
                style={{ margin: '6px 0' }}
                src={proposal.hotel.info.tripAdvisor.rating_image_url.replace('http:', 'https:')}
                alt='Tripadvizor rating'
              />
            </a>
          </Info>
        )}
      </Row>
    )
  }
}
HotelRatings = connect(mapStateToProps)(HotelRatings)
export default HotelRatings
