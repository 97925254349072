import React from 'react'
import { Box } from 'jsxstyle'
import { Card } from '../../../components/card'
import './package-info.css'

function PackageInfo ({ title, info, imageUrl }) {
  return (
    <Card className='package-info-card'>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        padding='.9375rem'
        position='relative'
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          width='100%'
        >
          <Box component='h4' className='info-title'>
            {title}
          </Box>
          {info && <p className='info'>{info}</p>}
        </Box>
        {imageUrl && (
          <img className='package-img' src={imageUrl} alt='Package logo' />
        )}
      </Box>
    </Card>
  )
}

export default PackageInfo
