import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Button from '../../../components/button'
import Currency from '../../../containers/currency'
import TransferDialog from './transfer-dialog'
import Y from '../../../y18n'
import * as transferActions from '../../../store/ducks/transfers'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  search: state.reservation.search,
  transfer: state.transfers.items
})
const mapDispatchToProps = dispatch => ({
  transferActions: bindActionCreators(transferActions, dispatch)
})

class TransferList extends Component {
  state = { showDialog: null }

  componentDidMount () {
    this.props.transferActions.queryTransfers(this.props.reservationId)
  }

  handleSelectTransfer (value, travelerId, transferId, travelerIndex) {
    let reservationId = this.props.reservationId
    if (value) {
      this.props.transferActions.submitSelectTransfer({
        reservationId,
        travelerId,
        transferId
      })

      // if first traveler, add to every one else
      if (travelerIndex === 0) {
        this.props.transfer
          .filter(
            (t, i) =>
              i !== 0 &&
              !t.transfers.find(tr => tr.transferId === transferId).reserved
          )
          .forEach(traveler => {
            this.props.transferActions.submitSelectTransfer({
              reservationId,
              transferId,
              travelerId: traveler.id
            })
          })
      }
    } else
      this.props.transferActions.submitRemoveTransfer({
        reservationId,
        travelerId,
        transferId
      })
  }

  isReserved (transfers) {
    return transfers.some(x => x.reserved)
  }

  renderButtonText (transfers) {
    const selected = transfers.find(x => x.reserved)
    return selected ? selected.description : Y`Select`
  }

  render () {
    const { transfer } = this.props
    if (
      !transfer ||
      !transfer.length ||
      !transfer.some(traveler => traveler.transfers.length)
    )
      return null

    return (
      <Card id='Transfer'>
        <CardHeader>
          <CardTitle>{Y`Transfer`}</CardTitle>
          <p>{Y`Transfer text`}</p>
        </CardHeader>
        <List className='no-odd border'>
          {
            transfer.map((traveler, i) => (
              <ListItem key={traveler.id}>
                <Row justifyContent='space-between' alignItems='center'>
                  <div>
                    <Row width='100%'>
                      <h3 className='nomargin'>
                        {/^\s+$/.test(traveler.name)
                          ? traveler.age !== null && traveler.age < 18
                            ? Y`Child` + ' ' + traveler.id
                            : Y`Adult` + ' ' + traveler.id
                          : traveler.name}
                      </h3>
                      <p style={{ margin: 0 }}>
                        {traveler.transfers.length === 1 ? (
                          <React.Fragment>
                            {' '}
                            -{' '}
                            <Currency>
                              {traveler.transfers[0].priceAmount}
                            </Currency>
                          </React.Fragment>
                        ) : this.isReserved(traveler.transfers) ? (
                          <React.Fragment>
                            {' '}
                            -{' '}
                            <Currency>
                              {
                                traveler.transfers.find(x => x.reserved)
                                  .priceAmount
                              }
                            </Currency>
                          </React.Fragment>
                        ) : (
                          ''
                        )}
                      </p>
                    </Row>
                    {traveler.transfers.length === 1 && (
                      <p className='caption'>
                        {traveler.transfers[0].description}
                      </p>
                    )}
                  </div>

                  {traveler.transfers.length > 1 ? (
                    <div className='text-right'>
                      <Button
                        key={i}
                        small
                        link={this.isReserved(traveler.transfers)}
                        nomargin
                        style={{
                          color: this.isReserved(traveler.transfers)
                            ? '#47a404'
                            : 'rgb(8, 154, 208)',
                          borderColor: 'rgb(8, 154, 208)'
                        }}
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            showDialog: traveler.id
                          })
                        }
                      >
                        {this.renderButtonText(traveler.transfers)} »
                      </Button>
                      {this.state.showDialog === traveler.id && (
                        <TransferDialog
                          title={'Transfer'}
                          onClose={() =>
                            this.setState({ ...this.state, showDialog: null })
                          }
                          traveler={traveler}
                          onSelect={(value, transferId) => {
                            this.handleSelectTransfer(
                              value,
                              traveler.id,
                              transferId
                            )
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <Switch
                      disabled={
                        traveler.transfers[0].inPackage ||
                        traveler.transfers[0].blocked
                      }
                      cy-id={`transfer-switch-${i}`}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor='#b4b4b4'
                      onChange={value =>
                        this.handleSelectTransfer(
                          value,
                          traveler.id,
                          traveler.transfers[0].transferId,
                          i
                        )
                      }
                      checked={traveler.transfers[0].reserved}
                      onColor='#47a404'
                      id='normal-switch'
                      height={22}
                      width={48}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    />
                  )}
                </Row>
              </ListItem>
            ))

            // <ListItem key={traveler.id}>
            //   <Row justifyContent='space-between' alignItems='center'>
            //     <div>
            //       <Row width='100%'>
            //         <h3 className='nomargin'>
            //           {/^\s+$/.test(traveler.name)
            //             ? traveler.age ? `${Y`Child`} ${traveler.age} ${Y`year`}` : Y`Adult` + ' ' + traveler.id
            //             : traveler.name}
            //           -
            //         </h3>
            //         <p style={{ margin: 0 }}>
            //           <Currency>{traveler.transfer.priceAmount}</Currency>
            //         </p>
            //       </Row>
            //       <p className='caption'>{traveler.transfer.description}</p>
            //     </div>

            //     <Switch
            //       uncheckedIcon={false}
            //       checkedIcon={false}
            //       offColor='#b4b4b4'
            //       onChange={value => this.handleSelectTransfer(value, traveler.id, i)}
            //       checked={traveler.transfer.reserved}
            //       onColor='#47a404'
            //       id='normal-switch'
            //       height={22}
            //       width={48}
            //       boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
            //       activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
            //     />
            //   </Row>
            // </ListItem>
          }
        </List>
      </Card>
    )
  }
}

TransferList = connect(mapStateToProps, mapDispatchToProps)(TransferList)
export default TransferList
