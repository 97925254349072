import { createSlice } from '@reduxjs/toolkit'
import api from '../api'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const golfBagsSlice = createSlice({
  name: 'golfBags',
  initialState,
  reducers: {
    QUERY_GOLF_BAGS: state => {
      state.loading = true
      state.error = null
    },
    QUERY_GOLF_BAGS_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    QUERY_GOLF_BAGS_ERROR: state => {
      state.loading = false
      state.error = true
    },
    UPDATE_GOLF_BAGS: (state, action) => {
      state.items = action.payload
    }
  }
})

export const {
  QUERY_GOLF_BAGS,
  QUERY_GOLF_BAGS_SUCCESS,
  QUERY_GOLF_BAGS_ERROR,
  UPDATE_GOLF_BAGS
} = golfBagsSlice.actions

export default golfBagsSlice.reducer

// thunks
export const queryGolfBags = reservationId => {
  return async dispatch => {
    dispatch(QUERY_GOLF_BAGS())
    const res = await api
      .query('travelbooking/golfBag', { reservationId })
      .catch(e => {
        return dispatch(QUERY_GOLF_BAGS_ERROR())
      })
    return dispatch(QUERY_GOLF_BAGS_SUCCESS(res.data))
  }
}

export const updateGolfBags = golfbags => {
  return dispatch => {
    dispatch(UPDATE_GOLF_BAGS(golfbags))
  }
}
