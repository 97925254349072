import React from 'react'
import PropTypes from 'prop-types'

export const Icon = ({ size, color, className = '', children, style = {}, ...rest }) => {
  if (size) {
    style.fontSize = size
    style.maxWidth = size
    style.width = size
    style.height = size
  }

  if (color) {
    style.color = color
  }

  return <i className={`material-icons ${className}`} style={style} {...rest}>{children}</i>
}

Icon.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Icon
