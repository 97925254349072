import React from 'react'
import PropTypes from 'prop-types'
import ImageCarousel from '../../../components/image-carousel'
import { Box } from 'jsxstyle'
import './images.css'

const Images = ({ src }) => (
  <Box position='relative' width='100%' height='100%'>
    <ImageCarousel>
      {src.map((img, index) => <img key={index} className='card-img' src={img} alt='' />)}
    </ImageCarousel>
  </Box>
)

Images.propTypes = {
  src: PropTypes.any
}

export default Images
