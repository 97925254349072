import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCommand } from '../../../store/api'
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle
} from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Switch from 'react-switch'
import { Row } from 'jsxstyle'
import Y from '../../../y18n'
import { queryReservationSummary } from '../../../store/ducks/reservation'
import isAdult from '../../../utils/is-adult'
import { queryGolfBags, updateGolfBags } from '../../../store/ducks/golfbags'

export const GolfBagList = () => {
  const dispatch = useDispatch()
  const reservationId = useSelector(state => state.reservation.reservationId)
  const golfBags = useSelector(state => state.golfBags.items)

  const [, submitAddGolfBagCommand] = useCommand('travelbooking/addgolfBag')
  const [, execRemoveGolfBagCommand] = useCommand('travelbooking/removegolfBag')

  useEffect(() => {
    if (reservationId) {
      dispatch(queryGolfBags(reservationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId])

  const handleSelectGolfBag = async (value, travelerId, index) => {
    let newState
    if (value) {
      if (index === 0 && golfBags[0].golfBag.allotmentLeft >= golfBags.length) {
        const travelerIds = golfBags.filter(t => !t.golfBag.reserved).map(t => t.id)
        newState = golfBags.map(traveler => {
          if (traveler.golfBag.reserved) {
            return traveler
          }
          return {
            ...traveler,
            golfBag: {
              ...traveler.golfBag,
              reserved: true
            }
          }
        })
        dispatch(updateGolfBags(newState))
        await submitAddGolfBagCommand({
          reservationId,
          travelerIds: travelerIds
        })
      } else {
        newState = golfBags.map((traveler, i) => {
          if (i !== index) {
            return traveler
          }
          return {
            ...traveler,
            golfBag: {
              ...traveler.golfBag,
              reserved: true
            }
          }
        })
        dispatch(updateGolfBags(newState))

        await submitAddGolfBagCommand({
          reservationId,
          travelerIds: [travelerId]
        })
      }
    } else {
      newState = golfBags.map((traveler, i) => {
        if (i !== index) {
          return traveler
        }
        return {
          ...traveler,
          golfBag: {
            ...traveler.golfBag,
            reserved: false
          }
        }
      })
      dispatch(updateGolfBags(newState))

      await execRemoveGolfBagCommand({
        reservationId,
        travelerIds: [travelerId]
      })
    }
    dispatch(queryReservationSummary(reservationId))
  }

  if (
    !golfBags ||
    !golfBags.length ||
    golfBags.every(g => g.golfBag === null)
  ) {
    return null
  }
  return (
    <Card id='GolfBag'>
      <CardHeader>
        <CardTitle>{Y`Golfbag`}</CardTitle>
        <p> {Y`Golfbag text`} </p>
        {golfBags.length > golfBags[0].golfBag.allotmentLeft &&
        golfBags[0].golfBag.allotmentLeft > 0 ? (
          <p className='caption warn nomargin'>{`There is only ${golfBags[0].golfBag.allotmentLeft} available allotments for Golf bag left`}</p>
        ) : null}
      </CardHeader>
      <CardContent>
        <List className='no-odd border'>
          {golfBags.map((traveler, index) => (
            <ListItem key={index}>
              <Row justifyContent='space-between' alignItems='center'>
                <div>
                  <Row width='100%'>
                    <h3 className='nomargin'>
                      {/^\s+$/.test(traveler.name)
                        ? !isAdult(traveler.age)
                          ? `${Y`Child`} ${traveler.age} ${Y`year`}`
                          : Y`Adult` + ' ' + traveler.id
                        : traveler.name}
                      -
                    </h3>
                    <p style={{ margin: 0 }}>
                      <Currency>{traveler.golfBag.priceAmount}</Currency>
                    </p>
                  </Row>
                  <p className='caption'>{traveler.golfBag.description}</p>
                </div>
                {traveler.golfBag.allotmentLeft === null ? (
                  <p className='warn nomargin'>{Y`Not available`}</p>
                ) : !traveler.golfBag.reserved &&
                  traveler.golfBag.allotmentLeft <=
                    golfBags.reduce((acc, t) => {
                      return acc + (t.golfBag && t.golfBag.reserved ? 1 : 0)
                    }, 0) ? (
                  <p className='warn nomargin'>{Y`Sold out`}</p>
                ) : (
                  <Switch
                    disabled={traveler.golfBag.blocked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor='#b4b4b4'
                    onChange={value => {
                      handleSelectGolfBag(value, traveler.id, index)
                    }}
                    checked={traveler.golfBag.reserved}
                    onColor='#47a404'
                    id='normal-switch'
                    height={22}
                    width={48}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  />
                )}
              </Row>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  )
}
