import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reservationActions from '../../store/ducks/reservation'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId
})
const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

export default function withReservationId (WrappedComponent) {
  // ...and returns another component...
  class ReservationIdComponent extends Component {
    componentDidMount () {
      this.props.reservationActions.setReservationId(this.props.match.params.reservationId)
    }

    render () {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return this.props.reservationId ? <WrappedComponent {...this.props} /> : null
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ReservationIdComponent)
}
