import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe'
import Y from '../../y18n'
import './input.css'

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('yyyy/mm/dd')
const formats = [moment.ISO_8601, 'YYYY-MM-DD']

const DateInput = ({ label = null, hideLabel = false, required = false, onChange, style, value, ...rest }) => {
  if (moment(value, formats, true).isValid()) {
    value = moment(value).format('YYYY-MM-DD')
  }

  let id = Math.random().toString(36).substring(7)
  let requiredLabel = required ? ' *' : ''
  let inputLabel = label
    ? <label className={hideLabel ? 'hidden' : ''} htmlFor={id}>
      {label}{requiredLabel}
    </label>
    : null

  const handleChange = e => {
    if (typeof onChange === 'function') {
      onChange(e.target.value, e)
    }
  }

  return (
    <div className='input' style={style}>
      {inputLabel}

      <MaskedInput
        id={id}
        required
        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
        className='form-control'
        placeholder={`${Y`yyyy`}-${Y`mm`}-${Y`dd`}`}
        guide
        placeholderChar='x'
        value={value}
        pipe={autoCorrectedDatePipe}
        onChange={handleChange}
        {...rest}
      />
    </div>
  )
}

DateInput.propTypes = {
  label: PropTypes.any,
  required: PropTypes.bool,
  style: PropTypes.any,
  onChange: PropTypes.func
}

export default DateInput
