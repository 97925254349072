import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Content } from '../../../components/layout'
import { Row, Box, Col } from 'jsxstyle'
import moment from 'moment-timezone'
import { Icon } from '../../../components/icon'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

const mediaQuery = {
  sm: 'screen and (max-width: 992px)',
  lg: 'screen and (min-width: 993px)'
}

class FlightInfo extends PureComponent {
  renderBus (bus, last = false) {
    return (
      <Box
        key={bus.id}
        mediaQueries={mediaQuery}
        width='100%'
        borderRight={!last && '1px solid rgb(238, 238, 238)'}
      >
        <Row
          alignItems='center'
          mediaQueries={mediaQuery}
          smAlignItems='flex-start'
          paddingLeft='.9375rem'
          smPaddingLeft='.4687rem'
          marginTop='.9375rem'
          marginBottom='.9375rem'
          lgJustifyContent='center'
        >
          <Box marginLeft={last ? '.4687rem' : ''}>
            <Box
              component='p'
              textTransform='capitalize'
              fontSize='.9rem'
              smFontSize='.8rem'
              mediaQueries={mediaQuery}
              fontWeight='bold'
              className='nomargin'
            >
              {moment
                .parseZone(bus.departureDateTime)
                .format('ddd D MMMM YYYY')}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {moment.parseZone(bus.departureDateTime).format('HH:mm')}{' '}
              {bus.originAirport}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {bus.airlineName}
            </Box>

            {bus.stops && (
              <Box
                fontStyle='italic'
                fontSize='.9rem'
                smFontSize='.6875rem'
                mediaQueries={mediaQuery}
                smClassName='caption nomargin'
                component='p'
              >
                Via {bus.stops.map(s => s.description).join(', ')}
              </Box>
            )}

            <Box
              component='p'
              textTransform='capitalize'
              fontSize='.9rem'
              smFontSize='.8rem'
              mediaQueries={mediaQuery}
              fontWeight='bold'
              className='nomargin'
            >
              {moment.parseZone(bus.arrivalDateTime).format('ddd D MMMM YYYY')}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {moment.parseZone(bus.arrivalDateTime).format('HH:mm')}{' '}
              {bus.destinationAirport}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {bus.airlineName}
            </Box>
          </Box>
        </Row>
        <Row />
      </Box>
    )
  }

  renderFlight (flight, last = false) {
    return (
      <Box
        key={flight.id}
        mediaQueries={mediaQuery}
        width='100%'
        borderRight={!last && '1px solid rgb(238, 238, 238)'}
      >
        <Row
          alignItems='center'
          mediaQueries={mediaQuery}
          smAlignItems='flex-start'
          paddingLeft='.9375rem'
          smPaddingLeft='.4687rem'
          marginTop='.9375rem'
          marginBottom='.9375rem'
          lgJustifyContent='center'
        >
          <Icon style={{ transform: last ? 'scaleX(-1)' : '' }}>
            flight_takeoff
          </Icon>
          <Box marginLeft='.4687rem'>
            <Box
              component='p'
              textTransform='capitalize'
              fontSize='.9rem'
              smFontSize='.8rem'
              mediaQueries={mediaQuery}
              fontWeight='bold'
              className='nomargin'
            >
              {moment
                .parseZone(flight.departureDateTime)
                .format('ddd D MMMM YYYY')}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {moment.parseZone(flight.departureDateTime).format('HH:mm')}{' '}
              {flight.originAirport} - {flight.destinationAirport}
            </Box>
            <Box
              fontSize='.9rem'
              smFontSize='.6875rem'
              mediaQueries={mediaQuery}
              smClassName='caption nomargin'
              component='p'
              className='nomargin'
            >
              {flight.airlineName}
            </Box>
          </Box>
        </Row>
        <Row />
      </Box>
    )
  }

  render () {
    const { proposal } = this.props
    if (!proposal || !proposal.flightCharter) return null
    return proposal.transportationType === 'Bus' ? (
      <Row
        mediaQueries={mediaQuery}
        background='#f8f8f8'
        lgBorderBottom='1px solid #ebebeb'
        lgMarginBottom='.9375rem'
      >
        <Content>
          <Row>
            <Col width='100%'>
              {proposal.flightCharter.outwardFlights.map(outward =>
                this.renderBus(outward)
              )}
            </Col>
            <Col width='100%'>
              {proposal.flightCharter.homewardFlights.map(homeward =>
                this.renderBus(homeward, true)
              )}
            </Col>
          </Row>
        </Content>
      </Row>
    ) : (
      <Row
        mediaQueries={mediaQuery}
        background='#f8f8f8'
        lgBorderBottom='1px solid #ebebeb'
        lgMarginBottom='.9375rem'
      >
        <Content>
          <Row>
            <Col width='100%'>
              {proposal.flightCharter.outwardFlights.map(outward =>
                this.renderFlight(outward)
              )}
            </Col>
            <Col width='100%'>
              {proposal.flightCharter.homewardFlights.map(homeward =>
                this.renderFlight(homeward, true)
              )}
            </Col>
          </Row>
        </Content>
      </Row>
    )
  }
}

FlightInfo = connect(mapStateToProps)(FlightInfo)
export default FlightInfo
