import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CardTitle, CardHeader } from '../card'
import { Row } from 'jsxstyle'
import { IconButton } from '../icon'
import './sidenav.css'

class Sidenav extends Component {
  componentDidMount () {
    document.body.style.overflowY = 'hidden'
    window.addEventListener('keydown', this.handleEscPress, false)
  }

  componentWillUnmount () {
    document.body.removeAttribute('style')
    window.removeEventListener('keydown', this.handleEscPress, false)
  }

  handleEscPress = e => {
    if (e.keyCode === 27) this.props.onClose()
  }

  render () {
    if (!this.props.show) {
      return null
    }

    return ReactDOM.createPortal(
      [
        <div key='sidenav' className={`sidenav ${this.props.className || ''}`} style={this.props.style}>
          <Row alignItems='center' justifyContent='space-between'>
            <CardHeader>
              <CardTitle>
                {this.props.title}
              </CardTitle>
            </CardHeader>
            <IconButton style={{ marginRight: '.9375rem' }} onClick={this.props.onClose}>close</IconButton>
          </Row>
          <div className='sidenav-content'>
            {this.props.children}
          </div>
        </div>,
        <div key='sidenav-backdrop' className='sidenav-backdrop' onClick={this.props.onClose} />
      ],
      document.body
    )
  }
}

Sidenav.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.any
}

export default Sidenav
