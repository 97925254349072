import React, { useEffect } from 'react'
import moment from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../components/select'
import Y from '../../y18n'
import * as reservationActions from '../../store/ducks/reservation'

const AirportSelect = () => {
  const dispatch = useDispatch()
  const { travelType, airportId, quikbook } = useSelector(state => ({
    travelType: state.reservation.search.travelType,
    airportId: state.reservation.search.airportId,
    quikbook: state.reservation.quikbook
  }))

  useEffect(() => {
    if (
      travelType !== 'Hotel' &&
      quikbook.airports &&
      quikbook.airports.length > 0
    ) {
      // Dispatch action to update `airportId` to the first airport id when `travelType` changes
      const firstAirportId = quikbook.airports[0].airportId
      dispatch(reservationActions.setSearchValue('airportId', firstAirportId))
    } else {
      // Dispatch action to clear `airportId` when `travelType` is 'Hotel' or there are no airports
      dispatch(reservationActions.setSearchValue('airportId', ''))
    }
  }, [travelType, quikbook.airports, dispatch])

  const renderDisabledSelect = () => (
    <Select hideLabel label='Origin' disabled value=''>
      {travelType === 'Hotel' && <option value=''>{Y`Only hotel`}</option>}
    </Select>
  )

  const disableComponent = !quikbook.airports

  if (disableComponent) {
    return renderDisabledSelect()
  }

  const noAirportsAvailible =
    quikbook.airports && quikbook.airports.length === 0

  if (noAirportsAvailible && airportId !== '') {
    dispatch(reservationActions.setSearchValue('airportId', ''))
  }

  return (
    <Select
      label='Origin'
      cy-id='origin-select'
      hideLabel
      disabled={travelType === 'Hotel'}
      value={airportId || ''}
      onChange={value => {
        const firstDestination = quikbook.airports.find(
          a => a.airportId === value
        ).destinationGroups[0].destinations[0]
        const firstPeriod = firstDestination.periods[0]

        dispatch(reservationActions.setSearchValue('airportId', value))
        dispatch(
          reservationActions.setSearchValue(
            'destinationId',
            firstDestination.id
          )
        )
        dispatch(
          reservationActions.setSearchValue(
            'charterPeriodId',
            `${moment.parseZone(firstPeriod.out).format()};${moment
              .parseZone(firstPeriod.home)
              .format()}`
          )
        )
      }}
    >
      {travelType === 'Hotel' && <option value=''>{Y`Only hotel`}</option>}
      {travelType !== 'Hotel' && !noAirportsAvailible ? (
        quikbook.airports.map(airport => (
          <option key={airport.airportId} value={airport.airportId}>
            {airport.name}
          </option>
        ))
      ) : (
        <option value=''>No departure airports found</option>
      )}
    </Select>
  )
}

export default AirportSelect
