import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Box } from 'jsxstyle'
import { List, ListItem } from '../../../components/list'
import { Icon } from '../../../components/icon'
import { Dialog, DialogContent, DialogButtons } from '../../../components/dialog'
import Button from '../../../components/button'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'
// import './hotel-map.css'

class TransferDialog extends Component {
  isNoSelected (transfers) {
    return !transfers.some(a => a.reserved)
  }

  handleSelectNone (transfers) {
    transfers.forEach(transfer => {
      if (transfer.reserved) {
        this.props.onSelect(false, transfer.transferId)
      }
    })
  }

  handleSelect (transfer) {
    this.props.onSelect(true, transfer.transferId)
  }

  render () {
    const { title, traveler, onClose } = this.props
    const disableNone = traveler.transfers.some(x => x.reserved && x.blocked)

    return (
      <Dialog show onClose={onClose} title={`${title} ${traveler.name}`}>
        <DialogContent>
          <List className='no-odd border'>
            <ListItem
              className={disableNone ? 'disabled' : 'clickable'}
              onClick={() => !disableNone && this.handleSelectNone(traveler.transfers)}
            >
              <Row justifyContent='space-between' alignItems='flex-start'>
                {this.isNoSelected(traveler.transfers)
                  ? <Icon color='#47a404' size={30}>check_circle</Icon>
                  : <Icon color='rgb(194,194,194)' size={30}>radio_button_unchecked</Icon>}
                <Box width='100%' paddingLeft='.9375rem'>
                  <h3 style={{ fontWeight: 'bold' }}>
                    {Y`None`} {title} - <span style={{ fontWeight: 'normal' }}><Currency>{0}</Currency></span>
                  </h3>
                </Box>
              </Row>
            </ListItem>
            {traveler.transfers.map(transfer => (
              <ListItem
                key={transfer.transferId}
                className={transfer.blocked ? 'disabled' : 'clickable'}
                onClick={() => !transfer.blocked && this.handleSelect(transfer)}
              >
                <Row justifyContent='space-between' alignItems='flex-start'>
                  {transfer.reserved
                    ? <Icon color='#47a404' size={30}>check_circle</Icon>
                    : <Icon color='rgb(194,194,194)' size={30}>radio_button_unchecked</Icon>}
                  <Box width='100%' paddingLeft='.9375rem'>
                    <h3 style={{ fontWeight: 'bold' }}>
                      {transfer.description}
                      {' '}
                      -
                      {' '}
                      <span style={{ fontWeight: 'normal' }}><Currency>{transfer.priceAmount}</Currency></span>
                    </h3>
                  </Box>
                </Row>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogButtons>
          <Button small onClick={onClose}>{Y`Select`}</Button>
        </DialogButtons>
      </Dialog>
    )
  }
}

TransferDialog.propTypes = {
  title: PropTypes.string,
  traveler: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TransferDialog
