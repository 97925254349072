import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/browser'
import api from '../api'

const initialState = {
  error: null,
  loading: false,
  item: null,
  searchInfo: null
}

const roomProposalSlice = createSlice({
  name: 'roomProposal',
  initialState,
  reducers: {
    QUERY_ROOM_PROPOSAL: (state, action) => {
      state.loading = true
      state.error = null
      state.item = null
    },
    QUERY_ROOM_PROPOSAL_SUCCESS: (state, action) => {
      state.loading = false
      state.error = null
      state.item = action.payload.proposal
      state.searchInfo = action.payload.searchInfo
      state.facilities = action.payload.facilities
    },
    QUERY_ROOM_PROPOSAL_ERROR: state => {
      state.loading = false
      state.error = 'Error'
      state.item = null
    }
  }
})

export const {
  QUERY_ROOM_PROPOSAL,
  QUERY_ROOM_PROPOSAL_SUCCESS,
  QUERY_ROOM_PROPOSAL_ERROR
} = roomProposalSlice.actions

export default roomProposalSlice.reducer

// thunks
export const queryRoomProposal = reservationId => {
  return dispatch => {
    dispatch(QUERY_ROOM_PROPOSAL())

    api
      .query('travelBooking/proposals', { reservationId, showRooms: true })
      .then(res => {
        dispatch(
          QUERY_ROOM_PROPOSAL_SUCCESS({
            proposal: res.data.periods[0].proposals[0],
            searchInfo: res.data.searchInfo,
            facilities: res.data.periods[0].facilities
          })
        )
      })
      .catch(err => {
        Sentry.captureException(err)
        dispatch(QUERY_ROOM_PROPOSAL_ERROR())
      })
  }
}
