// @flow

export const getScrollPage = () => {
  let docScrollTop = 0
  if (document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop
  }
  return window.pageYOffset || docScrollTop
}

export const scrollTo = (scrollnumber = 0) =>
  window.requestAnimationFrame(() => {
    window.scrollTo(0, scrollnumber)
  })
