import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Box } from 'jsxstyle'
import { Dialog, DialogContent, DialogButtons } from '../../components/dialog'
import { CardContent } from '../../components/card'
import Button from '../../components/button'
import ReservationSummary from '../reservation-summary'
import Y from '../../y18n'
import Currency from '../currency'

const mapStateToProps = state => ({
  reservation: state.reservation.summary
})

class ReservationSummaryDialog extends PureComponent {
  state = { showDialog: false }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleToggleDialog()
    }
  }

  handleToggleDialog () {
    this.setState({ ...this.state, showDialog: !this.state.showDialog })
  }

  render () {
    const { reservation } = this.props

    return [
      <Button style={{ whiteSpace: 'nowrap' }} key={1} link onClick={() => this.handleToggleDialog()}>
        {Y`Price specification`} »
      </Button>,
      this.state.showDialog && (
        <Dialog
          key={2}
          show={this.state.showDialog}
          onClose={() => this.handleToggleDialog()}
          title={Y`Price specification`}
        >
          <DialogContent>
            <CardContent>
              <ReservationSummary />
            </CardContent>
          </DialogContent>
          <DialogButtons>
            <Row justifyContent='space-between' alignItems='center' width='100%'>
              <Button small onClick={() => this.handleToggleDialog()}>{Y`Close`}</Button>
              <Row alignItems='center'>
                <Box component='p' className='nomargin' paddingRight='.4687rem'>
                  {Y`Total price`}{' '}
                </Box>
                <p className='price green nomargin'>
                  <Currency>{reservation.price}</Currency>
                </p>
              </Row>
            </Row>
          </DialogButtons>
        </Dialog>
      )
    ]
  }
}

ReservationSummaryDialog = connect(mapStateToProps)(ReservationSummaryDialog)
export default withRouter(ReservationSummaryDialog)
