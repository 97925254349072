import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'

class Toast extends PureComponent {
  render () {
    return ReactDOM.createPortal(<ToastContainer autoClose={5000} />, document.body)
  }
}

export default Toast
