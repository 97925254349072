import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './image-carousel.css'

function SampleNextArrow (props) {
  const { className, style, onClick, small, setAutoplay, autoplay } = props
  return (
    <img
      src={`/img/next-arrow${small ? '' : '-big'}.svg`}
      className={className + ' carousel-next-arrow' + (small ? ' carousel-small-arrow' : '')}
      style={{
        ...style
      }}
      alt='next'
      onClick={() => {
        onClick()
        if (autoplay) {
          setTimeout(() => {
            setAutoplay(false)
          }, 400)
        }
      }}
    />
  )
}

function SamplePrevArrow (props) {
  const { className, style, onClick, small, setAutoplay, autoplay } = props
  return (
    <img
      src={`/img/prev-arrow${small ? '' : '-big'}.svg`}
      className={className + ' carousel-prev-arrow' + (small ? ' carousel-small-arrow' : '')}
      style={{
        ...style
      }}
      alt='previous'
      onClick={() => {
        onClick()
        if (autoplay) {
          setTimeout(() => {
            setAutoplay(false)
          }, 400)
        }
      }}
    />
  )
}

const ImageCarousel = props => {
  const [autoplay, setAutoplay] = useState(!!props.autoplay)
  const settings = {
    autoplay,
    lazyLoad: true,
    arrows: props.smallArrows || window.innerWidth > 992,
    afterChange: props.afterChange,
    nextArrow: <SampleNextArrow autoplay={autoplay} setAutoplay={setAutoplay} small={!!props.smallArrows} />,
    prevArrow: <SamplePrevArrow autoplay={autoplay} setAutoplay={setAutoplay} small={!!props.smallArrows} />
  }
  return (
    <div className='image-carousel' style={props.style}>
      <Slider {...settings}>{props.children}</Slider>
    </div>
  )
}

ImageCarousel.propTypes = {
  children: PropTypes.node,
  afterChange: PropTypes.func
}

export default ImageCarousel
