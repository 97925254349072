import React from 'react'
import PropTypes from 'prop-types'
import './list-item-box.css'

export const ListItemBox = (
  { borderPlace = '', children, noPadding = false }
) => (
  <div
    className={`list-item-box ${borderPlace} ${noPadding ? 'no-padding' : ''}`}
  >
    {children}
  </div>
)

ListItemBox.propTypes = {
  borderPlace: PropTypes.string,
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool
}

export default ListItemBox
