import React from 'react'
import PropTypes from 'prop-types'
import { Box, Row } from 'jsxstyle'
import { CardContent } from '../../../components/card'
import { Dialog, DialogContent } from '../../../components/dialog'
import Select from '../../../components/select'
import Button from '../../../components/button'
import Y from '../../../y18n'
import './customer-category-rooms-dialog.css'
import isAdult from '../../../utils/is-adult'

const CustomerCategoryRoomsDialog = ({
  show = false,
  onClose,
  customerCategories,
  rooms,
  onCustomerCategoryChange,
  onNumberOfRoomsChange,
  onSelectAge
}) => {
  const handleSubmit = e => {
    e.preventDefault()
    handleClose(true)
  }

  const handleClose = isSubmit => {
    if (rooms.some(r => !r.travelers.length)) {
      alert(`${Y`Enter at least one traveler per room`}.`)
    } else if (rooms.map(r => r.travelers.filter(t => isAdult(t.age))).flat().length === 0) {
      alert(
        `${Y`Enter at least one adult traveler`}.\n\n${Y`Would you like to book a trip without adult travelers? Call us on ${Y`AlpInfoPhone`} and we will help you`}.`
      )
    } else if (rooms.some(r => r.travelers.every(t => t.age !== null && t.age <= 2))) {
      alert(`${Y`Not allowed with only infants in a room`}.`)
    } else {
      onClose(isSubmit)
    }
  }

  const renderAgeSelect = (customerCategory, traveler, roomIndex, travelerIndex) => {
    if (customerCategory.insertAge) {
      return (
        <Select
          cy-id={`age-select-${travelerIndex}`}
          key={`${roomIndex}_${travelerIndex}`}
          required
          style={{ width: 'auto' }}
          label=''
          value={traveler.age}
          onChange={onSelectAge({ traveler, roomIndex, travelerIndex })}
        >
          {customerCategory.yearOptions.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      )
    }
    return null
  }

  const renderRooms = (rooms, customerCategories) => {
    return rooms.map((room, i) => (
      <div key={i}>
        <Box component='h5' marginTop='.9375rem' marginBottom='-.5rem'>
          {Y`Room`} {i + 1}
        </Box>
        <Row className='flex-margin'>
          {customerCategories.map((cc, j) => {
            let options = Array.from(Array(cc.max)).map((e, i) => i + cc.min)

            return (
              <Box key={cc.code} width='100%'>
                <Select
                  cy-id={`traveler-select-${cc?.code}`}
                  value={room.travelers.filter(t => (cc.code === 'CHILD' ? !isAdult(t.age) : isAdult(t.age))).length}
                  label={cc.code === 'CHILD' ? Y`Child/Youth (0-17 years)` : cc.description}
                  onChange={onCustomerCategoryChange({ code: cc.code, roomIndex: i })}
                >
                  {options.map(option => (
                    <option key={option} value={option}>
                      {option} {cc.description}
                    </option>
                  ))}
                </Select>
                {room.travelers.filter(t => !isAdult(t.age)).length > 0 && j === 1 && (
                  <Row className='select-wrapper'>
                    <label className='age-label'>{Y`Age (at time of return travel)`}</label>
                  </Row>
                )}
                <Row className='flex-margin' flexWrap='wrap'>
                  {room.travelers.map((t, j) => {
                    return !isAdult(t.age) ? renderAgeSelect(cc, t, i, j) : null
                  })}
                </Row>
              </Box>
            )
          })}
        </Row>
      </div>
    ))
  }

  const renderNumberOfRooms = () => {
    let options = Array.from(Array(10)).map((e, i) => i + 1)

    return (
      <Select
        style={{ width: '33%', marginTop: 0 }}
        label={Y`Number of rooms`}
        value={rooms.length}
        onChange={onNumberOfRoomsChange}
      >
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    )
  }

  return show ? (
    <Dialog className='cc-dialog' show={show} onClose={handleClose} title=''>
      <DialogContent>
        <CardContent>
          {renderNumberOfRooms()}
          {renderRooms(rooms, customerCategories)}
          <Button onClick={handleSubmit} primary style={{ width: '100%' }}>
            {Y`Search trip`}
          </Button>
        </CardContent>
      </DialogContent>
    </Dialog>
  ) : null
}

CustomerCategoryRoomsDialog.propTypes = {
  show: PropTypes.bool,
  rooms: PropTypes.array.isRequired,
  customerCategories: PropTypes.array.isRequired,
  onNumberOfRoomsChange: PropTypes.func.isRequired,
  onCustomerCategoryChange: PropTypes.func.isRequired,
  onSelectAge: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

export default CustomerCategoryRoomsDialog
