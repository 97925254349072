import { createSlice } from '@reduxjs/toolkit'
import Y from '../../y18n'
import { setSearchValue } from './reservation'
import isAdult from '../../utils/is-adult'

const initialState = {
  error: null,
  loading: false,
  data: []
}

const customerCategoriesSlice = createSlice({
  name: 'customerCategories',
  initialState,
  reducers: {
    QUERY_CUSTOMERCATEGORIES: state => {
      state.loading = true
      state.error = null
    },
    QUERY_CUSTOMERCATEGORIES_SUCCESS: (state, action) => {
      state.loading = false
      state.data = action.payload
    }
  }
})

export const { QUERY_CUSTOMERCATEGORIES, QUERY_CUSTOMERCATEGORIES_SUCCESS } =
  customerCategoriesSlice.actions

export default customerCategoriesSlice.reducer

// thunks
export const queryCustomerCategories = () => {
  return dispatch => {
    dispatch(QUERY_CUSTOMERCATEGORIES())

    dispatch(
      QUERY_CUSTOMERCATEGORIES_SUCCESS([
        {
          code: 'ADULT',
          description: Y`Adults`,
          min: 0,
          max: 20,
          insertAge: false
        },
        {
          code: 'CHILD',
          description: Y`Children`,
          min: 0,
          max: 20,
          insertAge: true,
          years: [],
          yearOptions: Array.apply(null, { length: 18 }).map(
            Number.call,
            Number
          )
        }
      ])
    )
  }
}

// TODO: Move these to the reservation duck, since they only use state from there
export const setNumberOfTravelersInRoom = (code, number, roomIndex) => {
  return (dispatch, getState) => {
    number = parseInt(number, 10)
    const rooms = getState().reservation.search.rooms.map(r => ({ ...r, travelers: [...r.travelers] })) // Need to deep copy to not mutate the state
    let roomTravelers = rooms[roomIndex].travelers

    const customerCategoryTravelersCount = roomTravelers.filter(rt =>
      code === 'ADULT' ? isAdult(rt.age) : !isAdult(rt.age)
    ).length

    if (number > customerCategoryTravelersCount) {
      Array.from(Array(number - customerCategoryTravelersCount)).forEach(
        traveler => {
          roomTravelers.push({
            id: '',
            age: code === 'ADULT' ? 40 : 0
          })
        }
      )
    } else {
      let otherCategoryTravelers = roomTravelers.filter(rt =>
        code === 'ADULT' ? !isAdult(rt.age) : isAdult(rt.age)
      )
      let selectedCategoryTravelers = roomTravelers.filter(rt =>
        code === 'ADULT' ? isAdult(rt.age) : !isAdult(rt.age)
      )
      selectedCategoryTravelers.length = number
      roomTravelers = otherCategoryTravelers.concat(selectedCategoryTravelers)
    }
    rooms[roomIndex].travelers = roomTravelers
    dispatch(setSearchValue('rooms', rooms))
  }
}

export const setCustomerAge = (traveler, roomIndex, travelerIndex, age) => {
  return (dispatch, getState) => {
    let rooms = JSON.parse(JSON.stringify(getState().reservation.search.rooms)) // Need to deep copy to not mutate the state

    rooms[roomIndex].travelers[travelerIndex].age = age
    return dispatch(setSearchValue('rooms', rooms))
  }
}

export const setNumberOfRooms = number => {
  return (dispatch, getState) => {
    number = parseInt(number, 10)
    let rooms = [...getState().reservation.search.rooms]

    if (number > rooms.length) {
      Array.from(Array(number - rooms.length)).forEach(room => {
        rooms.push({
          id: '',
          travelers: [
            { id: null, age: null },
            { id: null, age: null }
          ]
        })
      })
    } else {
      rooms.length = number
    }

    return dispatch(setSearchValue('rooms', rooms))
  }
}
