import React, { Component } from 'react'
import { Page, Layout, Content } from '../../components/layout'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as paymentActions from '../../store/ducks/payment'

const mapStateToProps = state => ({
  paymentState: state.payment
})
const mapDispatchToProps = dispatch => ({
  paymentActions: bindActionCreators(paymentActions, dispatch)
})

class PaymentProcessPage extends Component {
  componentDidMount () {
    const params = new URLSearchParams(this.props.location.search)
    const transactionId = params.get('transactionId')
    const responseCode = params.get('responseCode')
    const errorMessage = params.get('errorMessage')
    const id = this.props.match.params.id
    this.props.location.pathname.includes('/bank')
      ? this.props.paymentActions.completeDirectBankPayment(id)
      : this.props.paymentActions.submitProcessCardPayment(id, transactionId, responseCode, errorMessage)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.paymentState.paymentSucceded !== null) {
      setTimeout(
        () =>
          this.props.history.push(
            `/reservation/${this.props.match.params.reservationId}?status=${
              nextProps.paymentState.paymentSucceded ? 'paymentSucceded' : 'paymentFailed'
            }`
          ),
        1000
      )
    }
  }

  render () {
    return (
      <Page>
        <Layout>
          <Content>
            <p>Loading...</p>
          </Content>
        </Layout>
      </Page>
    )
  }
}

PaymentProcessPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentProcessPage)

export default PaymentProcessPage
