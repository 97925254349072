import { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getScrollPage, scrollTo } from './utils'

class ScrollMemory extends Component {
  urls = null

  constructor (props) {
    super(props)
    this.detectPop = this.detectPop.bind(this)
    this.urls = new Map()
  }

  componentDidMount () {
    window.addEventListener('popstate', this.detectPop)
  }

  componentWillUnmount () {
    window.removeEventListener('pushstate', this.detectPop)
  }

  shouldComponentUpdate (nextProps) {
    const { location } = this.props
    // location before change url
    const actual = location
    // location after change url
    const next = nextProps.location
    // the first page has not key, set "enter" for key
    const key = actual.key || 'enter'

    // if hash => let the normal operation of the browser
    const locationChanged = (next.pathname !== actual.pathname || next.search !== actual.search) && next.hash === ''

    // get scroll of the page or the element before change location
    const scroll = getScrollPage()

    if (locationChanged) {
      scrollTo(0)
      this.urls.set(key, scroll)
    }
    return false
  }

  detectPop (location) {
    const { state } = location
    // key or enter page
    const key = state ? state.key : 'enter'
    // get the next for scroll position
    const nextFind = this.urls.get(key)

    if (nextFind) {
      const intervalCheckTime = 50
      const maxChecks = 30
      let timesRun = 0

      let interval = setInterval(() => {
        timesRun++
        var limit = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight
        )

        if (timesRun >= maxChecks) {
          clearInterval(interval)
        }

        if (limit > nextFind) {
          scrollTo(nextFind)
          clearInterval(interval)
        }
      }, intervalCheckTime)
    }
  }

  render () {
    return null
  }
}

export default withRouter(ScrollMemory)
