import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Box, Col } from 'jsxstyle'
import { List, ListItem, ListItemBox } from '../../../components/list'
import { Icon } from '../../../components/icon'
import Y from '../../../y18n'
import {
  queryHalfboard,
  submitAddHalfboard,
  submitRemoveHalfboard
} from '../../../store/ducks/halfboards'
import Currency from '../../../containers/currency'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  halfboard: state.halfboards.item
})

class Halfboards extends Component {
  componentDidMount () {
    this.props.dispatch(queryHalfboard(this.props.reservationId))
  }

  handleSelectHalfboard (halfboard) {
    const value = !halfboard.reserved
    if (value) {
      this.props.dispatch(
        submitAddHalfboard({
          reservationId: this.props.reservationId
        })
      )
    } else {
      this.props.dispatch(
        submitRemoveHalfboard({
          reservationId: this.props.reservationId
        })
      )
    }
  }

  render () {
    const { halfboard } = this.props
    if (!halfboard) return null

    return (
      <React.Fragment>
        <Box
          component='h2'
          mediaQueries={{ sm: 'screen and (max-width: 910px)' }}
          smFontSize='1.25rem'
          textAlign='center'
          marginBottom='.9375rem'
        >
          {Y`Select meals`}
        </Box>
        <List>
          <ListItem
            className={
              !halfboard.mandatory && !halfboard.blocked ? 'clickable' : ''
            }
            onClick={e => {
              !halfboard.mandatory &&
                !halfboard.blocked &&
                this.handleSelectHalfboard(halfboard)
            }}
          >
            <ListItemBox noPadding>
              <Row alignItems='flex-start'>
                {halfboard.reserved ? (
                  <Icon
                    color={halfboard.mandatory ? 'rgb(194,194,194)' : '#47a404'}
                    size={30}
                  >
                    check_box
                  </Icon>
                ) : (
                  <Icon color='rgb(194,194,194)' size={30}>
                    check_box_outline_blank
                  </Icon>
                )}
                <Box flex={1} marginLeft='.4rem'>
                  <h5>
                    {halfboard.description} - {halfboard.numberOfDays} {Y`days`}
                  </h5>
                  <p
                    style={{
                      fontSize: '.85rem',
                      lineHeight: 1.3
                    }}
                  >
                    {halfboard.info}
                  </p>
                </Box>
                <Col alignItems='flex-end' paddingLeft='.4687rem'>
                  <p className='price'>
                    {halfboard.priceAmount === 0 ? (
                      Y`Included`
                    ) : (
                      <Currency>{halfboard.priceAmount}</Currency>
                    )}
                  </p>
                  {halfboard.priceAmount > 0 && (
                    <p className='caption'>
                      <Currency>
                        {halfboard.priceAmount / halfboard.travelers.length}
                      </Currency>
                      /{Y`person`}
                    </p>
                  )}
                </Col>
              </Row>
            </ListItemBox>
          </ListItem>
        </List>
      </React.Fragment>
    )
  }
}

export default connect(mapStateToProps)(Halfboards)
