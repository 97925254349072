import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import PropTypes from 'prop-types'

const Gmap = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap defaultZoom={props.defaultZoom || 8} defaultCenter={props.position}>
        <Marker position={props.position} />
      </GoogleMap>
    )
  })
)

Gmap.propTypes = {
  position: PropTypes.object.isRequired,
  defaultZoom: PropTypes.number
}

export default Gmap
