import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row } from 'jsxstyle'
import Button from '../../components/button'
import BackButton from '../../containers/back-button'
import Y from '../../y18n'

const mapStateToProps = state => ({
  reservation: state.reservation.summary,
  reservationId: state.reservation.reservationId,
  loading: state.addons.loading
})

class NextButton extends Component {
  render () {
    const { reservation, reservationId } = this.props
    if (!reservation || this.props.loading) return null
    return (
      <Row
        justifyContent='space-between'
        smAlignItems='center'
        smFlexDirection='column-reverse'
        mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
      >
        <BackButton />
        <Button
          to={
            reservation.isEditable && reservation.status === 'Confirmed'
              ? `/reservation/${reservationId}`
              : `/confirm/${reservationId}`
          }
          primary
          cy-id='next-button-addons'
        >
          {Y`Next`} »
        </Button>
      </Row>
    )
  }
}

NextButton = connect(mapStateToProps)(NextButton)
export default NextButton
