import React from 'react'
import { Row, Col, Box } from 'jsxstyle'
import './progress.css'
import Y from '../../y18n'

const Progress = ({ active }) => {
  const small = { sm: 'screen and (max-width: 992px)' }
  const steps = [Y`Travelers`, Y`Addons`, Y`Summary`]
  const total = steps.length

  return (
    <React.Fragment>
      <Row marginTop='.9375rem' alignItems='center'>
        {[...Array(total)].map((_, i) => (
          <React.Fragment key={i}>
            {i === 0 ? (
              <Box mediaQueries={small} smDisplay='none' width='30px' height='6px' background={'#47a404'} />
            ) : (
              <Box flex={1} height='6px' background={i <= active - 1 ? '#47a404' : 'rgb(205, 205, 205)'} />
            )}
            <Col
              mediaQueries={small}
              borderRadius='50%'
              width='25px'
              smWidth='20px'
              height='25px'
              smHeight='20px'
              smFontSize='.8rem'
              alignItems='center'
              color={i < active - 1 || (active === 1 && i === 0) ? '#fff' : 'rgba(0, 0, 0, 0.5)'}
              fontWeight='bold'
              justifyContent='center'
              border='3px solid rgb(205, 205, 205)'
              background={i < active - 1 || (active === 1 && i === 0) ? '#47a404' : '#fff'}
            >
              <span>{i + 1}</span>
            </Col>
            {i < total - 1 ? (
              <Box flex={1} height='6px' background={i < active - 1 ? '#47a404' : 'rgb(205, 205, 205)'} />
            ) : (
              <Box mediaQueries={small} smDisplay='none' width='30px' height='6px' background='rgb(205, 205, 205)' />
            )}
          </React.Fragment>
        ))}
      </Row>
      <Row justifyContent='space-between' marginBottom='.9375rem' paddingLeft='7.5px' paddingRight='7.5px'>
        {steps.map((s, i) => (
          <Box
            key={i}
            mediaQueries={small}
            smDisplay='none'
            maxWidth='80px'
            fontSize='.75rem'
            textAlign='center'
            width='33%'
            color={i < active - 1 ? '#47a404' : null}
            textDecoration={i < active - 1 ? 'underline' : null}
          >
            {s}
          </Box>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default Progress
