import React from 'react'
import './dialog-buttons.css'

export const DialogButtons = ({ children }) => (
  <div className='dialog-buttons'>
    {children}
  </div>
)

export default DialogButtons
