import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Box, Col, Row, InlineBlock } from 'jsxstyle'
import Button from '../../../components/button'
import Y from '../../../y18n'
import './hotel-facilities.css'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelFacilities extends Component {
  state = { showMoreFacilities: false }

  componentDidMount () {
    this.setColumnWidth()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = e => this.setColumnWidth()

  setColumnWidth () {
    let windowWidth = window.innerWidth
    if (windowWidth < 768) {
      this.setState({ ...this.state, columns: 2, width: windowWidth })
    } else if (windowWidth < 992) {
      this.setState({ ...this.state, columns: 3, width: windowWidth })
    } else {
      this.setState({ ...this.state, columns: 4, width: windowWidth })
    }
  }

  render () {
    const { proposal } = this.props
    if (!proposal) return null
    const hotel = proposal.hotel

    return (
      <Box className='hfacilities'>
        <h4 style={{ textAlign: 'center' }}>{Y`Hotel fact`}</h4>
        <Box marginTop='.9375rem' columns={`${this.state.columns} auto`} columnRule='1px solid #ebebeb'>
          {hotel.info.facilities
            .filter(f => f.exist)
            .filter((f, i) => (this.state.width <= 992 && !this.state.showMoreFacilities ? i < 8 : true))
            .map((f, i) => (
              <Row key={i} justifyContent='space-between'>
                <p className='nomargin caption'>{f.description}</p>
                <p className='nomargin caption'>{f.exist ? Y`Yes` : Y`No`}</p>
              </Row>
            ))}
        </Box>
        {this.state.width <= 992 && (
          <Col alignItems='center' marginTop='.9375rem'>
            <Button
              link
              onClick={e => {
                let element = e.target
                this.setState({ ...this.state, showMoreFacilities: !this.state.showMoreFacilities }, () => {
                  if (!this.state.showMoreFacilities) {
                    element.scrollIntoView({ behavior: 'instant' })
                  }
                })
              }}
            >
              {[
                <InlineBlock key={1} paddingRight='4px'>
                  {this.state.showMoreFacilities ? Y`Show less facts` : Y`Show more facts`}{' '}
                </InlineBlock>,
                <InlineBlock
                  key={2}
                  component='span'
                  transform={`rotate(${this.state.showMoreFacilities ? '-90deg' : '90deg'})`}
                >
                  »
                </InlineBlock>
              ]}
            </Button>
          </Col>
        )}
      </Box>
    )
  }
}
HotelFacilities = connect(mapStateToProps)(HotelFacilities)
export default HotelFacilities
