import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Box } from 'jsxstyle'
import { List, ListItem } from '../../../components/list'
import { Icon } from '../../../components/icon'
import { Dialog, DialogContent, DialogButtons } from '../../../components/dialog'
import Button from '../../../components/button'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'
// import ErvLogo from './erv.svg'
// import './hotel-map.css'

class TravelInsuranceDialog extends Component {
  isNoSelected (travelInsurances) {
    return !travelInsurances.some(a => a.reserved)
  }

  handleSelectNone (travelInsurances) {
    travelInsurances.forEach(travelInsurance => {
      if (travelInsurance.reserved) {
        this.props.onSelect(false, travelInsurance.id)
      }
    })
  }

  handleSelect (travelInsurance) {
    this.props.onSelect(true, travelInsurance.id)
  }

  render () {
    const { title, traveler, onClose } = this.props
    const disableNone = traveler.travelInsurances.some(x => x.reserved && x.blocked)

    return (
      <Dialog show onClose={onClose} title={`${title} ${traveler.name}`}>
        <DialogContent>
          <img src='/img/erv.svg' alt='erv logo' style={{ height: '60px', padding: '.4687rem .9375rem' }} />
          <List className='no-odd border'>
            <ListItem
              className={disableNone ? 'disabled' : 'clickable'}
              onClick={() => !disableNone && this.handleSelectNone(traveler.travelInsurances)}
            >
              <Row justifyContent='space-between' alignItems='flex-start'>
                {this.isNoSelected(traveler.travelInsurances)
                  ? <Icon color='#47a404' size={30}>check_circle</Icon>
                  : <Icon color='rgb(194,194,194)' size={30}>radio_button_unchecked</Icon>}
                <Box width='100%' paddingLeft='.9375rem'>
                  <h3 style={{ fontWeight: 'bold' }}>
                    {Y`None`} {title} - <span style={{ fontWeight: 'normal' }}><Currency>{0}</Currency></span>
                  </h3>
                </Box>
              </Row>
            </ListItem>
            {traveler.travelInsurances.map(travelInsurance => (
              <ListItem
                key={travelInsurance.id}
                className={travelInsurance.blocked ? 'disabled' : 'clickable'}
                onClick={() => !travelInsurance.blocked && this.handleSelect(travelInsurance)}
              >
                <Row justifyContent='space-between' alignItems='flex-start'>
                  {travelInsurance.reserved
                    ? <Icon color='#47a404' size={30}>check_circle</Icon>
                    : <Icon color='rgb(194,194,194)' size={30}>radio_button_unchecked</Icon>}
                  <Box width='100%' paddingLeft='.9375rem'>
                    <h3 style={{ fontWeight: 'bold' }}>
                      {travelInsurance.description}
                      {' '}
                      -
                      {' '}
                      <span style={{ fontWeight: 'normal' }}><Currency>{travelInsurance.priceAmount}</Currency></span>
                    </h3>
                    <p className='nomargin'>
                      {travelInsurance.info}
                    </p>
                  </Box>
                </Row>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogButtons>
          <Button small onClick={onClose}>{Y`Select`}</Button>
        </DialogButtons>
      </Dialog>
    )
  }
}

TravelInsuranceDialog.propTypes = {
  title: PropTypes.string,
  traveler: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TravelInsuranceDialog
