import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}


const addonsSlice = createSlice({
  name: 'addons',
  initialState,
  reducers: {
    QUERY_ADDONS: (state) => {
      state.loading = true
      state.error = null
    },
    QUERY_ADDONS_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    QUERY_ADDONS_TYPE_SUCCESS: (state, action) => {
      let items = state.items.slice()
      state.loading = false
      state.items = items.find(addon => addon.type === action.payload.type)
      ? items.map(addon =>
        addon.type === action.payload.type
          ? { ...addon, ...action.payload }
          : addon
      ) : [...items, action.payload]
    },
    SET_ADDON: (state, action) => {
        state.items = state.items.map(addon =>
          addon.type === action.payload.addonType
            ? {
                ...addon,
                travelers: addon.travelers.map((traveler, j) =>
                  traveler.id === action.payload.travelerId
                    ? {
                        ...traveler,
                        articles: traveler.articles.map((article, k) =>
                          article.articleId === action.payload.articleId
                            ? {
                                ...article,
                                reserved: action.payload.reserved,
                                blocked: action.payload.blocked
                              }
                            : article
                        )
                      }
                    : traveler
                )
              }
            : addon
        )
      }
    }
  })

export const { QUERY_ADDONS, QUERY_ADDONS_SUCCESS, QUERY_ADDONS_TYPE_SUCCESS, SET_ADDON } = addonsSlice.actions

export default addonsSlice.reducer

// thunks
export const queryAddons = (reservationId, type = null) => {
  return dispatch => {
    const params = type ? { reservationId, type } : { reservationId }
    dispatch(QUERY_ADDONS())
    api.query('travelbooking/destinationArticles', params).then(res => {
      if (type === null) dispatch(QUERY_ADDONS_SUCCESS(res.data))
      else
        dispatch(QUERY_ADDONS_TYPE_SUCCESS(res.data.length ? res.data[0] : {}))
    })
  }
}

export const submitAddAddon = command => {
  return (dispatch, getState) => {
    const status = getState().reservation.summary.status
    dispatch(
      SET_ADDON({
        ...command,
        reserved: true,
        blocked:
          (status === 'Confirmed' || status === 'ConfirmedWithChanges') &&
          !command.dontBlock
      })
    )
    let cmd = api
      .sendCommand('travelBooking/addDestinationArticle', command)
      .then(() =>
        dispatch(
          queryReservationSummary(command.reservationId)
        )
      )
      .catch(() =>
        dispatch(
          SET_ADDON({
            ...command,
            reserved: false,
            blocked: false
          })
        )
      )
    return cmd
  }
}

export const submitRemoveAddon = command => {
  return dispatch => {
    dispatch(SET_ADDON({ ...command, reserved: false, blocked: false }))
    let cmd = api.sendCommand('travelBooking/removeDestinationArticle', command)
    cmd.then(() =>
      dispatch(
        queryReservationSummary(command.reservationId)
      )
    )
    return cmd
  }
}
