import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Block } from 'jsxstyle'
import {
  Carousel,
  CarouselItem,
  CarouselButton
} from '../../../components/carousel'
import Currency from '../../../containers/currency'
import Skeleton from '../../../components/skeleton'
import Y from '../../../y18n'
import {
  queryAlternativeProposals,
  setSoldOutDate,
  queryProposal
} from '../../../store/ducks/proposal'
import { submitSetSearchCriteria } from '../../../store/ducks/reservation'
import { queryCharterPeriods } from '../../../store/ducks/charter-periods'

const mapStateToProps = state => ({
  reservation: state.reservation,
  searchInfo: state.proposal.searchInfo,
  period: state.proposal.period,
  alternativePeriods: state.proposal.alternativePeriods
})

const size = 992

const initialState = {
  active: null,
  position: null,
  items: [],
  itemsPerSlide: window.innerWidth > size ? 4 : 3,
  dontSetDate: false,
  loadingAlternative: false
}

class DateCarousel extends Component {
  // TODO: get from server
  carousel = React.createRef()
  loading = false
  state = initialState

  UNSAFE_componentWillReceiveProps (nextProps) {
    const periodsCopy = { ...nextProps.period }
    if (!periodsCopy.out && nextProps.reservation.search) {
      periodsCopy.out = nextProps.reservation.search.outwardDateFrom
      periodsCopy.home = nextProps.reservation.search.homewardDateFrom
    }

    if (
      !this.loading &&
      nextProps.searchInfo &&
      periodsCopy.out &&
      !this.state.items.length
    ) {
      this.loading = true
      this.props
        .dispatch(
          queryCharterPeriods(
            nextProps.searchInfo.destinationId,
            nextProps.searchInfo.charterTypeId,
            nextProps.searchInfo.airportId,
            true,
            nextProps.searchInfo.travelType
          )
        )
        .then(res => {
          this.loading = false
          let items = []

          let periods = [...res.data]
          periods.sort(
            (a, b) => moment.parseZone(a.out) - moment.parseZone(b.out)
          )

          let active = `${moment.parseZone(periodsCopy.out)};${moment.parseZone(
            periodsCopy.home
          )}`
          for (let i = 0; i < periods.length; i += this.state.itemsPerSlide) {
            items.push(periods.slice(i, i + this.state.itemsPerSlide))
          }

          let position = this.getPosition(items, active)

          this.setState(
            {
              ...this.state,
              items: items,
              position: position,
              active: active
            },
            () => this.handleQueryAlternativePeriods(position, periodsCopy)
          )
        })
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  reset () {
    return new Promise(resolve => {
      this.setState({ ...initialState }, () => resolve())
    })
  }

  setActiveDateAndPosition (out, home) {
    return new Promise(resolve => {
      let id = `${moment.parseZone(out)};${moment.parseZone(home)}`
      let position = this.getPosition(this.state.items, id)
      this.setState(
        { ...this.state, position: position, active: id, dontSetDate: true },
        () => resolve()
      )
      this.carousel.slide(position)
    })
  }

  getPosition (items = null, active = null) {
    let position = 0
    items = items || this.state.items || []
    active = active || this.state.active

    items.forEach((item, i) => {
      item.forEach(date => {
        if (
          `${moment.parseZone(date.out)};${moment.parseZone(date.home)}` ===
          active
        ) {
          position = i
        }
      })
    })

    return position
  }

  handleQueryAlternativePeriods = (position, period) => {
    position = position || this.state.position
    let alternativePeriods = this.state.items[position]
    let loadedCount = 0
    this.setState({ ...this.state, loadingAlternative: true })

    if (alternativePeriods) {
      alternativePeriods.forEach(ap => {
        if (!period || ap.home !== period.home || ap.out !== period.out) {
          this.props
            .dispatch(
              queryAlternativeProposals(
                this.props.reservation.reservationId,
                moment.parseZone(ap.out).format(),
                moment.parseZone(ap.out).add(1, 'days').format(),
                moment.parseZone(ap.home).format(),
                moment.parseZone(ap.home).add(1, 'days').format()
              )
            )
            .then(() => {
              loadedCount++
              if (loadedCount === alternativePeriods.length - 1) {
                this.setState({ ...this.state, loadingAlternative: false })
              }
            })
        }
      })
    }
  }

  handleWindowResize = () => {
    let width = window.innerWidth
    if (width > size && this.state.itemsPerSlide === 3)
      this.setState({ ...this.state, itemsPerSlide: 4 })
    else if (width <= size && this.state.itemsPerSlide === 4)
      this.setState({ ...this.state, itemsPerSlide: 3 })
  }

  handleButtonClick (date) {
    this.setState({
      ...this.state,
      active: `${moment.parseZone(date.out)};${moment.parseZone(date.home)}`
    })

    if (!date.dontResetSoldOutDate) {
      this.props.dispatch(setSoldOutDate(null))
    }

    this.props
      .dispatch(
        submitSetSearchCriteria({
          ...this.props.reservation.search,
          reservationId: this.props.reservation.reservationId,
          outwardDateFrom: moment.parseZone(date.out).format(),
          outwardDateTo: moment.parseZone(date.out).add(23, 'hours').format(),
          homewardDateFrom: moment.parseZone(date.home).format(),
          homewardDateTo: moment.parseZone(date.home).add(23, 'hours').format()
        })
      )
      .then(() => {
        this.props.dispatch(
          queryProposal(
            this.props.reservation.reservationId,
            moment.parseZone(date.out).format(),
            moment.parseZone(date.home).format()
          )
        )
      })
  }

  handleSlideChange (index, items) {
    let date = items[index][0]
    this.setState(
      {
        ...this.state,
        active: `${moment.parseZone(date.out)};${moment.parseZone(date.home)}`,
        position: index,
        dontSetDate: false
      },
      () => {
        if (!this.state.dontSetDate) {
          this.handleButtonClick(date)
        }
        this.handleQueryAlternativePeriods(index)
      }
    )
  }

  render () {
    const { alternativePeriods } = this.props
    const { items, active, position } = this.state

    if (!items.length || position === null) {
      return (
        <Block
          height='86px'
          mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
          smHeight='77px'
          smMarginLeft='-.9375rem'
          smMarginRight='-.9375rem'
          smWidth='calc(100% + (.9375rem * 2))'
          borderRight='1px solid #ebebeb'
          borderLeft='1px solid #ebebeb'
        />
      )
    }

    return (
      <Carousel
        ref={this.carousel}
        position={position}
        onSlideChange={index => this.handleSlideChange(index, items)}
      >
        {items.map((item, i) => {
          return (
            <CarouselItem key={i}>
              {item.map((date, j) => {
                const outDate = moment.parseZone(date.out)
                const homeDate = moment.parseZone(date.home)
                const periodDays = date.daysBetween + 1
                const period =
                  alternativePeriods[
                    `${outDate.format('YYYY-MM-DD')}_${homeDate.format(
                      'YYYY-MM-DD'
                    )}`
                  ]

                return (
                  <CarouselButton
                    onClick={() => this.handleButtonClick(date)}
                    active={`${outDate};${homeDate}` === active}
                    key={j}
                    disabled={!period || !period.fromPrice}
                  >
                    <h4>{moment.parseZone(outDate).format('ddd DD MMM')} </h4>
                    <p>
                      {periodDays} {Y`days`}
                    </p>
                    <p className='price'>
                      {period ? (
                        period.fromPrice ? (
                          <span>
                            {Y`From`} <Currency>{period.fromPrice}</Currency>
                          </span>
                        ) : (
                          Y`Sold out`
                        )
                      ) : !this.state.loadingAlternative ? (
                        Y`Sold out`
                      ) : (
                        <Block width='50%' margin='0 auto' component='span'>
                          <Skeleton />
                        </Block>
                      )}
                    </p>
                  </CarouselButton>
                )
              })}
            </CarouselItem>
          )
        })}
      </Carousel>
    )
  }
}

DateCarousel = connect(mapStateToProps, null, null, {
  forwardRef: true
})(DateCarousel)
export default DateCarousel
