import React from 'react'
import { Row } from 'jsxstyle'
import PropTypes from 'prop-types'
import './radio-button.css'

const RadioButton = ({
  label = null,
  alternatives = [],
  required = false,
  disabled = false,
  name,
  checkedValue,
  onChange,
  ...rest
}) => {
  let radioLabel = label ? <label>{label}</label> : null
  let id = Math.random()
    .toString(36)
    .substring(7)

  const handleOnChange = e => {
    if (typeof onChange === 'function') onChange(e.target.value)
  }

  const handleKeyDown = id => e => {
    // Trigger change on spacebar and enter keys (in violation of wai-aria spec).
    if (e.keyCode === 32 || e.keyCode === 13) {
      e.preventDefault()
      onChange(id)
    }
  }

  let radioButtons = alternatives.map((a, i) => {
    return (
      <Row className='radio-button' marginRight='1rem' key={a.id}>
        <input
          disabled={disabled}
          checked={a.id === checkedValue}
          value={a.id}
          onChange={handleOnChange}
          required={required && i === 0}
          type='radio'
          id={`${id}-${i}`}
          name={`${id}-${name}`}
        />
        <label htmlFor={`${id}-${i}`}>
          <span tabIndex='0' onKeyDown={handleKeyDown(a.id)} />
          {a.description}
        </label>
      </Row>
    )
  })

  return (
    <div className='radio-buttons'>
      {radioLabel}
      <Row>{radioButtons}</Row>
    </div>
  )
}

RadioButton.propTypes = {
  label: PropTypes.any.isRequired,
  alternatives: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  checkedValue: PropTypes.string
}

export default RadioButton
