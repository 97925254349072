import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import moment from 'moment-timezone'
import * as localeActions from './locales'

const initialState = { market: null }

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    SET_MARKET: (state, action) => {
      state.market = action.payload
    }
  }
})

export const { SET_MARKET } = marketSlice.actions
export default marketSlice.reducer

export const setMarket = () => {
  return dispatch => {
    api.query('travelBooking/currentMarket').then(res => {
      const market = res.data
      dispatch(SET_MARKET(market))
      let lang = market.defaultLanguageId
      lang = lang === 'NO' ? 'NB' : lang
      lang = lang.toLowerCase()

      import(`moment/locale/${lang}`).then(() => {
        dispatch(localeActions.setLocale(lang))
        moment.locale(lang)
      })
    })
  }
}
