import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Button from '../../components/button'
import Y from '../../y18n'

const size = 992
class BackButton extends Component {
  state = { isSmallWidth: null }

  componentDidMount () {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = () => {
    let width = window.innerWidth
    if (width > size && this.state.isSmallWidth) this.setState({ ...this.state, isSmallWidth: false })
    else if (width <= size && !this.state.isSmallWidth) this.setState({ ...this.state, isSmallWidth: true })
  }
  render () {
    const { to, ...rest } = this.props
    return to ? (
      <Button
        {...rest}
        style={
          this.state.isSmallWidth
            ? { textDecoration: 'none', color: 'rgb(157,157,157)' }
            : { background: 'rgb(220,220,220)', color: '#333', border: 'none' }
        }
        link={this.state.isSmallWidth}
        to={to}
      >
        « {Y`Back`}
      </Button>
    ) : (
      <Button
        {...rest}
        style={
          this.state.isSmallWidth
            ? { textDecoration: 'none', color: 'rgb(157,157,157)' }
            : { background: 'rgb(220,220,220)', color: '#333', border: 'none' }
        }
        onClick={() => this.props.history.goBack()}
      >
        « {Y`Back`}
      </Button>
    )
  }
}

export default withRouter(BackButton)
