import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Box, Row } from 'jsxstyle'
import HotelCard from '../hotel-card'
import Y from '../../../y18n'
import { Icon } from '../../../components/icon'
import Button from '../../../components/button'
import PackageInfo from '../package-info'

const mapStateToProps = state => ({
  loading: state.proposal.loading,
  altPeriodLoading: state.proposal.altPeriodLoading,
  period: state.proposal.period,
  proposals: state.proposal.period.proposals,
  soldOutDate: state.proposal.soldOutDate,
  alternativePeriods: state.proposal.alternativePeriods,
  airportId: state.proposal.searchInfo?.airportId
})

const size = 992

/*
    Values below are used for the info box that is shown when there are no available trips from Arlanda but might be from Bromma.
    Currently this is disabled but it might be enabled again for summer.
*/
// const ARNID = 'ARN'
// const ARN_TITLETEXT = 'Inga lediga resor från Arlanda?'
// const ARN_SWITCHTEXT =
//   'Prova att byta flygplats till Bromma för att se tillgängliga resor på ditt valda datum. '

class HotelList extends Component {
  componentDidUpdate (prevProps, prevState) {
    // if we dont get any result, try to show for another period instead
    const length = window.innerWidth > size ? 3 : 2
    const outDate = this.props.charterPeriodId.split(';')[0]

    if (
      !this.props.soldOutDate &&
      !this.props.loading &&
      this.props.proposals.length === 0
    ) {
      if (
        !this.props.altPeriodLoading &&
        Object.keys(this.props.alternativePeriods).length >= length
      ) {
        let alternative = null
        for (let key in this.props.alternativePeriods) {
          let period = this.props.alternativePeriods[key]
          if (period.proposals.length > 0) {
            if (!alternative) {
              alternative = period
            } else {
              let diff = Math.abs(
                moment(outDate).diff(moment(period.out), 'days')
              )
              if (
                diff <=
                Math.abs(moment(outDate).diff(moment(alternative.out), 'days'))
              ) {
                alternative = period
              }
            }
          }
        }
        if (alternative) {
          this.props.onSoldOut(alternative.out, alternative.home, outDate)
        }
      }
    }
  }

  /*
  renderSwitchToBMAInfoBox = () => {
    return (
      <Row className='red-bg' padding='.9375rem' margin='.9375rem 0'>
        <Icon className='white' style={{ marginRight: '.9375rem' }}>
          error
        </Icon>
        <Box>
          <Box component='h5' margin='0' color='white'>
            {ARN_TITLETEXT}
          </Box>
          <p className='nomargin white'>
            {ARN_SWITCHTEXT}
            <Button
              link
              onClick={() => this.props.onNewSearch()}
              style={{ color: 'white' }}
            >
              {Y`Change`} {Y`searching`}
            </Button>
          </p>
        </Box>
      </Row>
    )
  }
  */

  render () {
    const { proposals, period } = this.props
    if (!period.packages) {
      return proposals.map((proposal, i) => (
        <HotelCard key={i} proposal={proposal} />
      ))
    }

    let packages =
      period && period.packages
        ? period.packages.map(p => {
            return {
              ...p,
              proposals: []
            }
          })
        : []

    if (!proposals) return null

    packages.sort((a, b) => b.priority - a.priority)

    const uniqueHotels = new Set()

    proposals.forEach((p, i) => {
      if (p.hotel) {
        uniqueHotels.add(p.hotel.hotelId)
      }
      if (p.package) {
        let pkg = packages.find(
          x => x.id === p.package.id && x.info === p.package.info
        )
        pkg.proposals.push(p)
      }
    })

    const recommendedProposals = []
    if (uniqueHotels.size > 1) {
      let filtered = [...proposals]
        .filter(p => p.priority >= 100)
        .sort((a, b) => b.priority - a.priority)
      filtered.forEach(f => {
        if (
          !recommendedProposals.some(r => r.hotel.hotelId === f.hotel.hotelId)
        ) {
          recommendedProposals.push(f)
        }
      })
    }

    return (
      <div className='hotel-list'>
        {this.props.soldOutDate && (
          <>
            {/* {this.props.airportId === ARNID && this.renderSwitchToBMAInfoBox()} */}
            <Row className='yellow-bg' padding='.9375rem' margin='.9375rem 0'>
              <Icon style={{ marginRight: '.9375rem' }}>error</Icon>
              <Box>
                <Box component='h5' margin='0'>
                  {Y`Sorry, we did not find any travels`}{' '}
                  {moment(this.props.soldOutDate).format('dddd D MMM')}
                </Box>
                <p className='nomargin'>
                  {Y`Below are available trips on the nearest date instead`},{' '}
                  {moment(this.props.period.out).format('dddd D MMM')}.{' '}
                  <Button link onClick={() => this.props.onNewSearch()}>
                    {Y`Change`} {Y`searching`}
                  </Button>
                </p>
              </Box>
            </Row>
          </>
        )}

        {recommendedProposals.length > 0 && (
          <React.Fragment>
            <PackageInfo title={Y`Selected hotels`} />
            {recommendedProposals.map((proposal, i) => (
              <HotelCard proposal={proposal} key={i} />
            ))}
          </React.Fragment>
        )}

        {packages.map(pkg => (
          <React.Fragment key={pkg.id}>
            <PackageInfo
              title={pkg.description}
              info={pkg.info}
              imageUrl={pkg.imageUrl}
            />
            {pkg.proposals.map((proposal, i) => (
              <HotelCard key={i} proposal={proposal} />
            ))}
          </React.Fragment>
        ))}
        {!proposals.length && (
          <>
            {/* {this.props.airportId === ARNID && this.renderSwitchToBMAInfoBox()} */}
            <p>{Y`No proposals found. Try changing filtering or searching for another date`}</p>
          </>
        )}
      </div>
    )
  }
}

HotelList = connect(mapStateToProps)(HotelList)
export default withRouter(HotelList)
