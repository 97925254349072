import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import api from '../api'
import { toast } from 'react-toastify'
import Y from '../../y18n'

const initialState = {
  reservationId: null,
  emailAddress: null,
  search: {
    charterTypeId: '',
    airportId: '',
    destinationId: '',
    charterPeriodId: '',
    travelType: 'Charter',
    rooms: [
      {
        id: null,
        travelers: [
          { id: '1', age: 40 },
          { id: '2', age: 40 }
        ]
      }
    ],
    flightTravelers: [
      { id: '1', age: 40 },
      { id: '2', age: 40 }
    ],
    offerCode: ''
  },
  changes: null,
  quikbook: {},
  summary: null
}

const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    QUERY_RESERVATIONSUMMARY: (state, action) => {
      const summary = action.payload
      const isEditable =
        summary &&
        (summary.status === 'Confirmed' ||
          summary.status === 'ConfirmedWithChanges') &&
        summary.firstTravelDate &&
        moment(summary.firstTravelDate) > moment()
        state.summary = { ...action.payload, isEditable: isEditable }
  },
  QUERY_QUIKBOOK: (state, action) => {
    state.quikbook = action.payload
  },
  QUERY_CHANGES: (state, action) => {
    state.changes = action.payload
  },
  SET_RESERVATION_ID: (state, action) => {
    state.reservationId = action.payload
  },
  SET_EMAIL_VALUE: (state, action) => {
    state.emailAddress = action.payload
  },
  SET_SEARCH_VALUE: (state, action) => {
    state.search[action.payload.name] = action.payload.value
  },
  SET_SEARCH: (state, action) => {
    state.search = action.payload
  },
  SET_OFFERCODE: (state, action) => {
    state.offerCode = action.payload.change
  }
}})

export const {
  QUERY_RESERVATIONSUMMARY,
  QUERY_QUIKBOOK,
  QUERY_CHANGES,
  SET_RESERVATION_ID,
  SET_EMAIL_VALUE,
  SET_SEARCH_VALUE,
  SET_SEARCH,
  SET_OFFERCODE
} = reservationSlice.actions

export default reservationSlice.reducer


export const setSearchValue = (name, value) => {
  return (dispatch, getState) => {
    if (name === 'travelType') {
      const search = getState().reservation.search
      if (value !== search.travelType) {
        if (search.rooms.length && !search.flightTravelers.length) {
          let flightTravelers = [].concat(...search.rooms.map(r => r.travelers))
          dispatch(
            SET_SEARCH_VALUE({
              name: 'flightTravelers',
              value: flightTravelers
            })
          )
          dispatch(SET_SEARCH_VALUE({ name: 'rooms', value: [] }))
        } else if (search.flightTravelers.length && !search.rooms.length) {
          dispatch(
            SET_SEARCH_VALUE({
              name: 'rooms',
              value: [{ travelers: search.flightTravelers }]
            })
          )
          dispatch(SET_SEARCH_VALUE({ name: 'flightTravelers', value: [] }))
        }

        // Reset charterTypeId when travelType is changed
        dispatch(SET_SEARCH_VALUE({ name: 'charterTypeId', value: '' }))
      }
    }
    dispatch(SET_SEARCH_VALUE({ name, value }))
  }
}

export const setSearch = value => {
  return dispatch => {
    dispatch(SET_SEARCH(value))
  }
}

export const submitCreateReservation = command => {
  return dispatch => {
    dispatch(QUERY_RESERVATIONSUMMARY(null)) // empty reservation summary
    let cmd = api.sendCommand('createCharter', command)
    cmd.then(res => {
      dispatch(SET_RESERVATION_ID(res.data.reservationId))
    })
    return cmd
  }
}

export const submitSetOfferCode = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/setOfferCode', command)
    cmd.then(() =>
      dispatch(
        queryReservationSummary(command.reservationId)
      )
    )
    return cmd
  }
}

export const submitSetReferralCode = command => {
  return () => {
    let cmd = api.sendCommand('travelBooking/setReferralCode', command)
    return cmd
  }
}

export const changeOfferCode = change => {
  return dispatch => {
    dispatch(SET_OFFERCODE({ change }))
  }
}

export const submitNordicChoiceClubDetails = command => {
  //return () => {
  //return api.sendCommand('travelBooking/setNordicChoiceClubDetails', command)
  // }
}

export const setReservationId = reservationId => {
  return dispatch => {
    dispatch(SET_RESERVATION_ID(reservationId))
  }
}

export const setEmailValue = value => {
  return dispatch => {
    dispatch(SET_EMAIL_VALUE(value))
  }
}

export const submitSetSearchCriteria = command => {
  return () => {
    return api.sendCommand('travelBooking/setCharterSearchCriteria', command)
  }
}

export const submitConfirmReservation = command => {
  return () => {
    return api.sendCommand('confirmReservation', command, 'travelBooking')
  }
}

export const submitRollbackReservation = command => {
  return dispatch => {
    return api
      .sendCommand('travelbooking/rollbackReservation', command)
      .then(() => dispatch(queryReservationSummary(command.reservationId)))
  }
}

export const submitSelectProposal = command => {
  return dispatch => {
    return new Promise(resolve => {
      api
        .sendCommand('travelBooking/selectProposal', command)
        .then(() =>
          dispatch(queryReservationSummary(command.reservationId)).then(() =>
            resolve()
          )
        )
    })
  }
}

export const submitSetTravelerDetails = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/setTravelerDetails', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}

export const submitConfirmUpdates = command => {
  return dispatch => {
    let cmd = api.sendCommand('travelBooking/customerConfirmUpdates', command)
    cmd.then(() => {
      dispatch(queryReservationSummary(command.reservationId))
      toast.success(Y`Thanks`)
    })
    return cmd
  }
}

export const queryReservationSummary = (
  reservationId,
  withAvailableArticles = false
) => {
  return dispatch => {
    const params = withAvailableArticles
      ? { reservationId, withAvailableArticles }
      : { reservationId }
    let query = api.query('travelBooking/reservationSummary', params)
    query.then(res => {
      dispatch(
        setSearch({
          ...res.data.search,
          offerCode: res.data.offerCode,
          charterPeriodId: `${res.data.search.outwardDateFrom};${res.data.search.homewardDateFrom}`
        })
      )
      dispatch(QUERY_RESERVATIONSUMMARY(res.data))
    })
    return query
  }
}

export const queryChanges = reservationId => {
  return dispatch => {
    let query = api.query('travelBooking/reservationChanges', { reservationId })
    query.then(res => {
      dispatch(QUERY_CHANGES(res.data))
    })
    return query
  }
}

export const queryQuikbook = (
  marketId,
  travelType,
  epiServerId,
  hotelId = null
) => {
  return (dispatch, getState) => {
    const params = { marketId, travelType, epiServerId, hotelId }
    let query = api.query('travelBooking/quikbook', params)
    query.then(res => {
      const search = getState().reservation.search
      const data = res.data

      try {
        if (travelType === 'Hotel') {
          const destination = data.destinationGroups[0].destinations[0]
          const period = destination.periods[0]

          dispatch(
            setSearch({
              ...search,
              airportId: null,
              destinationId: destination.id,
              charterPeriodId: `${moment
                .parseZone(period.out)
                .format()};${moment.parseZone(period.home).format()}`
            })
          )
        } else if (!search.airportId) {
          const airport = data.airports[0]
          const destination = airport.destinationGroups[0].destinations[0]
          const period = destination.periods[0]

          dispatch(
            setSearch({
              ...search,
              airportId: airport.airportId,
              destinationId: destination.id,
              charterPeriodId: `${moment
                .parseZone(period.out)
                .format()};${moment.parseZone(period.home).format()}`
            })
          )
        }
      } catch (error) {
        setSearch({
          ...search,
          airportId: '',
          destinationId: '',
          charterPeriodId: ''
        })
      }
      dispatch(QUERY_QUIKBOOK(data))
    })
    return query
  }
}

export const queryReservationId = (reservationCode, email) => {
  return api.query('travelBooking/reservationId', { reservationCode, email })
}

export const downloadTravelConditions = marketId => {
  return () => {
    api.query('travelBooking/travelConditions', { marketId }).then(res => {
      var pdfAsDataUri = 'data:application/pdf;base64,' + res.data.data

      // IE 10+
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(dataUriToBlob(pdfAsDataUri), res.data.fileName)
      } else {
        var link = document.createElement('a')
        link.download = res.data.fileName
        link.href = pdfAsDataUri
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }
}

const dataUriToBlob = dataUri => {
  if (!/base64/.test(dataUri)) throw new Error('Supports only base64 encoding.')
  let parts = dataUri.split(/[:;,]/)

  let type = parts[1]

  let binData = atob(parts.pop())

  let mx = binData.length

  let uiArr = new Uint8Array(mx)
  for (var i = 0; i < mx; ++i) {
    uiArr[i] = binData.charCodeAt(i)
  }
  return new Blob([uiArr], { type: type })
}
