import React, { Component } from 'react'
import { Row, Box, Block } from 'jsxstyle'
import Skeleton, { SkeletonTheme } from '../../components/skeleton'

class LoadingSummary extends Component {
  render () {
    return (
      <Row alignItems='center' className='proposal-res-summary'>
        <Box flex={1}>
          <SkeletonTheme color='rgb(255, 236, 120)'>
            <h4><Box width='60%'><Skeleton /></Box></h4>
            <Box component='p'>
              <Block component='span' width='40%'><Skeleton /></Block>
            </Box>
          </SkeletonTheme>
        </Box>
      </Row>
    )
  }
}

export default LoadingSummary
