import qs from 'qs'

const id = 'appRedirectUrl'

export function setRedirectUrl (queryParams) {
  const params = qs.parse(queryParams, {
    ignoreQueryPrefix: true
  })
  if (params.redirectUrl) {
    sessionStorage.setItem(id, params.redirectUrl)
  }
}

export function getRedirectUrl () {
  return sessionStorage.getItem(id)
}
