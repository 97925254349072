import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Col, Box, Row } from 'jsxstyle'
import Stars from '../../../components/stars'
import Skeleton from '../../../components/skeleton'
import HotelMap from '../hotel-map'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelName extends PureComponent {
  render () {
    const { proposal, isSmallLayout } = this.props
    if (!proposal) return null
    const hotel = proposal.hotel

    return (
      <Col smAlignItems='center' margin='.9375rem 0' mediaQueries={{ sm: 'screen and (max-width: 992px)' }}>
        <Row alignItems='center'>
          <Box
            component='h1'
            fontSize='1.8rem'
            className='nomargin'
            width={!hotel ? '70%' : 'auto'}
            marginRight='.9375rem'
          >
            {!hotel ? <Skeleton /> : hotel.info.name}{' '}
          </Box>
          {hotel && hotel.info.stars && !isSmallLayout && (
            <Stars color='rgb(255, 220, 13)' size={'20px'}>
              {hotel.info.stars}
            </Stars>
          )}
        </Row>
        <Box component='p' width={!hotel ? '60%' : 'auto'} className='nomargin'>
          {!hotel ? <Skeleton /> : proposal.destination.description}
        </Box>
        {hotel && hotel.info.stars && (
          <Row justifyContent='space-between'>
            {isSmallLayout && (
              <Stars color='rgb(255, 220, 13)' size={'20px'}>
                {hotel.info.stars}
              </Stars>
            )}
            {!isSmallLayout && <HotelMap />}
          </Row>
        )}
      </Col>
    )
  }
}

HotelName = connect(mapStateToProps)(HotelName)
export default HotelName
