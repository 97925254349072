import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Row, Box } from 'jsxstyle'
import { List, ListItem } from '../../components/list'
import { Icon } from '../../components/icon'
import { Dialog, DialogContent, DialogButtons } from '../../components/dialog'
import Button from '../../components/button'
import Y from '../../y18n'
import Currency from '../../containers/currency'

class AddonDialog extends Component {
  isNoSelected (articles) {
    return !articles.some(a => a.reserved)
  }

  handleSelectNone (articles) {
    articles.forEach(article => {
      if (article.reserved) {
        this.props.onSelect(false, article.articleId, article.generalId)
      }
    })
  }

  handleSelect (article) {
    let value = this.props.multipleChoises ? !article.reserved : true
    this.props.onSelect(value, article.articleId, article.generalId)
  }

  render () {
    const {
      title,
      traveler,
      onClose,
      multipleChoises,
      type,
      languageId
    } = this.props
    const inPackage = traveler.articles.some(a => a.inPackage)
    const disableNone = traveler.articles.some(x => x.reserved && x.blocked)
    const currentLocale = moment.locale()

    return (
      <Dialog show onClose={onClose} title={`${title} ${traveler.name}`}>
        <DialogContent>
          <List className='no-odd border'>
            {!multipleChoises && !inPackage && (
              <ListItem
                className={disableNone ? 'disabled' : 'clickable'}
                onClick={() =>
                  !disableNone && this.handleSelectNone(traveler.articles)
                }
              >
                <Row justifyContent='space-between' alignItems='flex-start'>
                  {this.isNoSelected(traveler.articles) ? (
                    <Icon color='#47a404' size={30}>
                      check_circle
                    </Icon>
                  ) : (
                    <Icon color='rgb(194,194,194)' size={30}>
                      radio_button_unchecked
                    </Icon>
                  )}
                  <Box width='100%' paddingLeft='.9375rem'>
                    <h3 style={{ fontWeight: 'bold' }}>
                      {languageId === 'SV' && type === 'SkiPass'
                        ? 'Inget'
                        : Y`None`}{' '}
                      <span style={{ textTransform: 'lowercase' }}>
                        {title}
                      </span>{' '}
                      -{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        <Currency>{0}</Currency>
                      </span>
                    </h3>
                  </Box>
                </Row>
              </ListItem>
            )}
            {traveler.articles.map(article => (
              <ListItem
                key={article.articleId}
                className={
                  !(article.inPackage && article.reserved) ? 'clickable' : ''
                }
                onClick={() => {
                  if (
                    article.blocked ||
                    (article.inPackage && article.reserved)
                  )
                    return
                  this.handleSelect(article)
                }}
              >
                <Row justifyContent='space-between' alignItems='flex-start'>
                  {article.reserved ? (
                    <Icon color='#47a404' size={30}>
                      {multipleChoises ? 'check_box' : 'check_circle'}
                    </Icon>
                  ) : (
                    <Icon color='rgb(194,194,194)' size={30}>
                      {multipleChoises
                        ? 'check_box_outline_blank'
                        : 'radio_button_unchecked'}
                    </Icon>
                  )}
                  <Box width='100%' paddingLeft='.9375rem'>
                    <h3 style={{ fontWeight: 'bold' }}>
                      {article.description} -{' '}
                      {`${moment.parseZone(article.date).format('dddd')} ${
                        article.numberOfDays > 1
                          ? ` ${Y`to`} ${
                              article.endDayOfWeek
                                ? moment()
                                    .locale('en')
                                    .day(article.endDayOfWeek)
                                    .locale(currentLocale)
                                    .format('dddd')
                                : moment
                                    .parseZone(article.date)
                                    .add(article.numberOfDays - 1, 'days')
                                    .format('dddd')
                            }`
                          : ''
                      }`}
                      {article.numberOfDays > 1
                        ? ` (${article.numberOfDays} ${Y`days`}) `
                        : ''}
                      -{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        {article.priceAmount === 0 ? (
                          Y`Included`
                        ) : (
                          <Currency>{article.priceAmount}</Currency>
                        )}
                      </span>
                    </h3>
                    <p className='nomargin'>{article.info}</p>
                  </Box>
                </Row>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogButtons>
          <Button small onClick={onClose}>{Y`Select`}</Button>
        </DialogButtons>
      </Dialog>
    )
  }
}

AddonDialog.propTypes = {
  title: PropTypes.string,
  traveler: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default AddonDialog
