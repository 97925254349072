import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Y from '../../../y18n'
import { Icon } from '../../../components/icon'
import { Dialog, DialogContent } from '../../../components/dialog'
import Gmap from '../../../components/gmap'
import Button from '../../../components/button'
// import './hotel-map.css'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelMap extends PureComponent {
  state = { show: false }
  handleToggleDialog () {
    this.setState({ show: !this.state.show })
  }

  render () {
    const { proposal } = this.props
    if (!proposal) return null
    const hotel = proposal.hotel
    if (!hotel.info.geoCoordinateLat || !hotel.info.geoCoordinateLong) return null

    return [
      <Button style={{ textDecoration: 'none', marginLeft: -6 }} link onClick={() => this.handleToggleDialog()} key={1}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon>location_on</Icon>
          <p style={{ margin: 0 }}>{Y`Show map`}</p>
        </div>
      </Button>,
      this.state.show && (
        <Dialog key={2} show={this.state.show} onClose={() => this.handleToggleDialog()} title={hotel.info.name}>
          <DialogContent>
            <Gmap
              googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyCAPfaRTBhE_DcY8pjI1fCvGdv38Quv0OM&sensor=false&language=sv'
              loadingElement={<div style={{ height: window.innerWidth > 992 ? '80vh' : 'calc(100vh - 60px)' }} />}
              containerElement={<div className='map-container' />}
              mapElement={<div style={{ height: window.innerWidth > 992 ? '80vh' : 'calc(100vh - 37px)' }} />}
              defaultZoom={16}
              position={{ lat: hotel.info.geoCoordinateLat, lng: hotel.info.geoCoordinateLong }}
            />
          </DialogContent>
        </Dialog>
      )
    ]
  }
}

HotelMap = connect(mapStateToProps)(HotelMap)
export default HotelMap
