import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Box } from 'jsxstyle'
import Switch from 'react-switch'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import * as flightMealActions from '../../../store/ducks/flight-meals'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  flightMeal: state.flightMeals.items,
  market: state.market.market
})
const mapDispatchToProps = dispatch => ({
  flightMealActions: bindActionCreators(flightMealActions, dispatch)
})

class FlightMealList extends Component {
  state = { submitting: false }

  componentDidMount () {
    this.props.flightMealActions.queryFlightMeals(this.props.reservationId)
  }

  // handleSelectFlightMeal (meal) {
  //   this.setState({ ...this.state, submitting: true })
  //   let reservationId = this.props.reservationId

  //   let existingMeal = this.props.flightMeal
  //     .map(fm => fm.meals)
  //     .flat()
  //     .filter(ofm => ofm.articleId !== meal.articleId && ofm.reserved)[0]

  //   if (existingMeal) {
  //     this.props.flightMealActions
  //       .submitRemoveFlightMeal({ reservationId, mealId: existingMeal.articleId })
  //       .then(() =>
  //         this.props.flightMealActions.submitAddFlightMeal({
  //           reservationId,
  //           mealId: meal.articleId
  //         })
  //       )
  //       .then(() => this.setState({ ...this.state, submitting: false }))
  //   } else {
  //     this.props.flightMealActions
  //       .submitAddFlightMeal({ reservationId, mealId: meal.articleId })
  //       .then(() => this.setState({ ...this.state, submitting: false }))
  //   }
  // }

  handleSelectFlightMeal (value, mealId, travelerId) {
    let reservationId = this.props.reservationId
    if (value) {
      // Remove existing insurances, this should be done on server
      // becuase we should check if we are allowed to have multiple
      // insurances per traveler or npt
      let traveler = this.props.flightMeal.find(x => x.id === travelerId)
      let existingMeal = traveler.meals.find(x => x.reserved === true)
      if (existingMeal) {
        this.props.flightMealActions
          .submitRemoveFlightMeal({
            reservationId,
            mealId: existingMeal.articleId,
            travelerId
          })
          .then(() =>
            this.props.flightMealActions.submitAddFlightMeal({
              reservationId,
              mealId,
              travelerId
            })
          )
      } else
        this.props.flightMealActions.submitAddFlightMeal({
          reservationId,
          mealId,
          travelerId
        })
    } else
      this.props.flightMealActions.submitRemoveFlightMeal({
        reservationId,
        mealId,
        travelerId
      })
  }

  handleSelectBusinessClass (businessClass) {
    this.setState({ ...this.state, submitting: true })
    let reservationId = this.props.reservationId

    if (businessClass.reserved) {
      businessClass.travelerIds.forEach(travelerId =>
        this.props.flightMealActions.submitRemoveFlightMeal({
          reservationId,
          travelerId,
          mealId: businessClass.articleId
        })
      )
    } else {
      businessClass.travelerIds.forEach(travelerId =>
        this.props.flightMealActions.submitAddFlightMeal({
          reservationId,
          travelerId,
          mealId: businessClass.articleId,
          remove: true
        })
      )
    }

    // let existingMeal = this.props.flightMeal
    //   .map(fm => fm.meals)
    //   .flat()
    //   .filter(ofm => ofm.articleId !== meal.articleId && ofm.reserved)[0]

    // if (existingMeal) {
    //   this.props.flightMealActions
    //     .submitRemoveFlightMeal({ reservationId, mealId: existingMeal.articleId })
    //     .then(() =>
    //       this.props.flightMealActions.submitAddFlightMeal({
    //         reservationId,
    //         mealId: meal.articleId
    //       })
    //     )
    //     .then(() => this.setState({ ...this.state, submitting: false }))
    // } else {
    //   this.props.flightMealActions
    //     .submitAddFlightMeal({ reservationId, mealId: meal.articleId })
    //     .then(() => this.setState({ ...this.state, submitting: false }))
    // }
  }

  isReserved (flightMeals) {
    return flightMeals.some(x => x.reserved)
  }

  render () {
    const { flightMeal } = this.props

    // ugly hack for finnair
    const isFinnair = flightMeal.every(fm => fm.airline === 'AY')

    if (
      !isFinnair &&
      (!flightMeal.length ||
        !flightMeal.some(fm => fm.meals.length > 0) ||
        flightMeal.every(fm => fm.meals.every(m => m.allotmentLeft === 0)))
    ) {
      return null
    }

    let businessClass = null
    let flightMeals = flightMeal.map(traveler => {
      return { ...traveler, meals: traveler.meals }
    })

    if (!isFinnair) {
      flightMeal.forEach(traveler => {
        traveler.meals.forEach(meal => {
          if (meal.articleType === 'BusinessClass') {
            if (!businessClass) {
              businessClass = {
                ...meal,
                travelerIds: [traveler.id],
                totalPrice: meal.priceAmount
              }
            } else {
              businessClass.travelerIds.push(traveler.id)
              businessClass.totalPrice += meal.priceAmount
            }
          }
        })
      })
    }

    return (
      <Card id='FlightMeal'>
        <CardHeader>
          <CardTitle>{Y`Meals and business class on the flight`}</CardTitle>
          <p>{Y`Meals and business class on the flight text`}</p>
        </CardHeader>
        <List className='no-odd border'>
          {businessClass && (
            <ListItem
              className={
                businessClass.blocked ||
                businessClass.allotmentLeft < businessClass.travelerIds.length
                  ? 'disabled'
                  : 'clickable'
              }
            >
              <Row justifyContent='space-between' alignItems='center'>
                <Box width='80%'>
                  <h3 style={{ fontWeight: 'bold' }}>
                    {businessClass.description} -{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      <Currency>{businessClass.totalPrice}</Currency>
                    </span>
                    <span
                      className='caption'
                      style={{ fontWeight: 'normal', marginLeft: '8px' }}
                    >
                      (
                      <Currency>
                        {businessClass.totalPrice /
                          businessClass.travelerIds.length}
                      </Currency>
                      /{Y`person`})
                    </span>
                  </h3>
                  <p className='caption'>{businessClass.info}</p>
                </Box>

                <Switch
                  disabled={
                    businessClass.blocked ||
                    (!businessClass.reserved &&
                      businessClass.allotmentLeft <
                        businessClass.travelerIds.length)
                  }
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor='#b4b4b4'
                  onChange={() => this.handleSelectBusinessClass(businessClass)}
                  checked={businessClass.reserved}
                  onColor='#47a404'
                  id='normal-switch'
                  height={22}
                  width={48}
                  boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                  activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                />
              </Row>
            </ListItem>
          )}

          {flightMeals.map((traveler, k) => (
            <ListItem
              key={traveler.id}
              className={
                businessClass && businessClass.reserved ? 'disabled' : ''
              }
            >
              <Row justifyContent='space-between' alignItems='center'>
                <div>
                  <Row width='100%'>
                    <h3 className='nomargin'>
                      {/^\s+$/.test(traveler.name)
                        ? traveler.age !== null && traveler.age < 18
                          ? Y`Child` + ' ' + traveler.id
                          : Y`Adult` + ' ' + traveler.id
                        : traveler.name}
                    </h3>
                    {!isFinnair && (
                      <p style={{ margin: 0 }}>
                        {' '}
                        - <Currency>{traveler.meals[0].priceAmount}</Currency>
                      </p>
                    )}
                  </Row>
                  <p className='caption'>{traveler.meals[0].description}</p>
                </div>

                {isFinnair ? (
                  <p className='nomargin'>Varataan erikseen Finnairilta</p>
                ) : (
                  <Switch
                    disabled={
                      businessClass.reserved || traveler.meals[0].blocked
                    }
                    uncheckedIcon={false}
                    checkedIcon={false}
                    offColor='#b4b4b4'
                    onChange={value =>
                      this.handleSelectFlightMeal(
                        value,
                        traveler.meals[0].articleId,
                        traveler.id
                      )
                    }
                    checked={traveler.meals[0].reserved}
                    onColor='#47a404'
                    id='normal-switch'
                    height={22}
                    width={48}
                    boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                    activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                  />
                )}
              </Row>
            </ListItem>
          ))}
        </List>
      </Card>
    )
  }
}

FlightMealList = connect(mapStateToProps, mapDispatchToProps)(FlightMealList)
export default FlightMealList
