import React from 'react'
import PropTypes from 'prop-types'
import './icon-svg.css'

export const IconSvg = ({ size, color, className = '', style = {}, children }) => {
  if (size) {
    style.fontSize = size
    style.maxWidth = size
    style.width = size
    style.height = size
  }

  if (color) {
    style.color = color
  }

  return <i className={`icon-svg ${children} ${className}`} style={style} />
}

IconSvg.propTypes = {
  children: PropTypes.string.isRequired
}

export default IconSvg
