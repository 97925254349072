import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/browser'
import TagManager from 'react-gtm-module'
import App from './App'
import ErrorBoundary from './containers/error-boundary'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import getEnvironment from './get-environment'
import './index.css'

const { REACT_APP_SENTRY_URL: sentryUrl, REACT_APP_VERSION: appVersion } = process.env

const env = getEnvironment()
const getTagManegerArgs = () => {
  if (env === 'dev') {
    return {
      gtmId: 'GTM-MZNV7B7',
      auth: 'aupWkE2jkBSyvBs4LT1Eag',
      preview: 'env-6'
    }
  } else if (env === 'qa') {
    return {
      gtmId: 'GTM-MZNV7B7',
      auth: 'qAsnhbmCNByKVU4dYLsysg',
      preview: 'env-7'
    }
  } else if (env === 'prod') {
    return {
      gtmId: 'GTM-MZNV7B7',
      auth: 'ZhKFtzyye2XvPU1OLsyvwA',
      preview: 'env-2'
    }
  }
  return null
}

const tagManagerArgs = getTagManegerArgs()
if (tagManagerArgs) {
  TagManager.initialize(getTagManegerArgs())
}

if (sentryUrl) {
  Sentry.init({
    dsn: sentryUrl,
    environment: env
  })

  Sentry.configureScope(scope => {
    scope.setTag('version', appVersion)
  })
}

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
)

// serviceWorker.register({
//   onUpdate: () => {
//     // Some UI notification comes here, then reload
//     window.location.reload()
//   }
// })

serviceWorker.unregister()
