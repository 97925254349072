import React, { Component } from 'react'

class Skeleton extends Component {
  static defaultProps = {
    count: 1,
    wrapper: null,
    duration: 1.2
  }

  render () {
    const elements = []
    for (let i = 0; i < this.props.count; i++) {
      elements.push(
        <span
          key={i}
          className='react-loading-skeleton'
          style={{
            ...this.props.style,
            animation: 'progress ' + String(this.props.duration) + 's ease-in-out infinite'
          }}
        >
          ‌
        </span>
      )
    }

    const Wrapper = this.props.wrapper
    return (
      <span>
        {Wrapper ? elements.map((element, i) => <Wrapper key={i}>{element}‌</Wrapper>) : elements}
      </span>
    )
  }
}

export default Skeleton
