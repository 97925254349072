import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'jsxstyle'
import { IconSvg, Icon } from '../icon'

const Stars = ({ children, color = '#333', size = null }) => {
  return (
    <Row component='span' fontSize='48px' lineHeight='1' marginBottom='0' alignItems='center'>
      {[...Array(Math.floor(children))].map((item, i) => <IconSvg key={i} color={color} size={size}>star</IconSvg>)}
      {children % 1 !== 0 ? <Icon color={color} size={size * 1.2}>add</Icon> : ''}
    </Row>
  )
}

Stars.propTypes = {
  children: PropTypes.number.isRequired,
  color: PropTypes.string,
  size: PropTypes.string
}

export default Stars
