/**
 * Groups a list by one or two properties
 * @param {*} list The list to divide into groups
 * @param {*} prop1 The first property to group by
 * @param {*} prop2 The second property to group by [OPTIONAL]
 * @returns A list of objects grouped by the input prop(s)
 */
export const groupBy = (list, prop1, prop2 = null) => {
  if (list.length === 0) return []

  const getGroupIndicatorName = (item, prop1, prop2) =>
    prop2 ? `${item[prop1]}-${item[prop2]}` : `${item[prop1]}`

  if (list.length === 1)
    return [{
      groupIndicator: getGroupIndicatorName(list[0], prop1, prop2),
      items: list
    }]

  return list.reduce((acc, cp) => {
    const groupIndicator = getGroupIndicatorName(cp, prop1, prop2)
    const group = acc.find(g => g.groupIndicator === groupIndicator)
    if (!group) {
      acc.push({ groupIndicator: groupIndicator, items: [cp] })
    } else {
      group.items.push(cp)
    }

    return acc
  }, [])
}
