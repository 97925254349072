import React from 'react'
import PropTypes from 'prop-types'
import './content.css'

export const Content = ({ children, className='content' }) => (
  <div className={className}>
    {children}
  </div>
)

Content.propTypes = {
  children: PropTypes.node.isRequired
}

export default Content
