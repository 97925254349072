const config = {
  apiUrl: process.env.NODE_ENV === 'development' ? '/v1' : '/api/v1',
  pdfUrl: '/api/v1',
  waitingListUrl: {
    'SE': 'https://www.alpresor.se/sista-minuten/vantelista/',
    'NO': 'https://www.alpereiser.no/restplasser/venteliste/',
    'FI': 'https://www.alppimatkat.fi/akkilahdot/odotuslista/'
  }
}

export default config
