import React from 'react'
import PropTypes from 'prop-types'
import './card-content.css'

export const CardContent = ({ children, className = '', ...rest }) => {
  return (
    <div className={`card-content ${className}`} {...rest}>
      {children}
    </div>
  )
}

CardContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default CardContent
