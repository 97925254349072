import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Row, Box } from 'jsxstyle'
import Skeleton, { SkeletonTheme } from '../../../components/skeleton'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'
import ReservationSummaryDialog from '../../../containers/reservation-summary-dialog'
import './hotel-reservation-summary.css'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  reservation: state.reservation.summary
})

class HotelReservationSummary extends Component {
  getGroupedRooms (rooms) {
    let groupedRooms = []
    rooms.forEach(room => {
      let existingIndex = groupedRooms.findIndex(x => x.categoryId === room.categoryId)
      if (existingIndex > -1) {
        groupedRooms[existingIndex].number = groupedRooms[existingIndex].number + 1
      } else {
        groupedRooms.push({ ...room, number: 1 })
      }
    })

    return groupedRooms
  }

  renderRoomDescriptions (rooms) {
    const groupedRooms = this.getGroupedRooms(rooms)
    const isSameTypeOfRoom = groupedRooms.length === 1

    return groupedRooms.map(
      (room, i) =>
        `${!isSameTypeOfRoom || room.number > 1 ? room.number : ''} ${room.description || room.categoryId}${
          i < groupedRooms.length - 1 ? ', ' : ''
        }`
    )
  }

  render () {
    const { reservation } = this.props

    if (!reservation || !reservation.hotels.length) {
      return (
        <SkeletonTheme color='rgb(255, 236, 120)'>
          <Box width='60%'>
            <Skeleton />
          </Box>
          <Box width='40%'>
            <Skeleton />
          </Box>
          <Box width='40%'>
            <Skeleton />
          </Box>
        </SkeletonTheme>
      )
    }

    let travelers = reservation.travelers
    let roomCount = reservation.hotels[0].rooms.length

    return (
      <Row justifyContent='space-between' alignItems='center' className='hotel-res-summary'>
        <Box minWidth={0}>
          <h4>{Y`Your search`}</h4>
          <p>
            {moment.parseZone(reservation.firstTravelDate).format('ddd D MMM')},{' '}
            {moment
              .parseZone(reservation.lastTravelDate)
              .startOf('day')
              .diff(moment.parseZone(reservation.firstTravelDate).startOf('day'), 'days') + 1}{' '}
            {Y`days`}
          </p>
          <p>{`${roomCount} ${Y`rooms`}, ${travelers.filter(t => isAdult(t.age)).length} ${Y`adults`}${
            travelers.filter(t => !isAdult(t.age)).length
              ? `, ${travelers.filter(t => !isAdult(t.age)).length} ${Y`children`}`
              : ''
          }`}</p>
          <Box component='p' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
            {this.renderRoomDescriptions(reservation.hotels[0].rooms)}
          </Box>
        </Box>
        <Box paddingLeft='.9375rem' textAlign='center'>
          <p className='caption'>{Y`Total price`}</p>
          <h4>
            <Currency>{reservation.price}</Currency>
          </h4>
          <ReservationSummaryDialog />
        </Box>
      </Row>
    )
  }
}

HotelReservationSummary = connect(mapStateToProps)(HotelReservationSummary)
export default HotelReservationSummary
