import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardTitle
} from '../../../components/card'
import { Row, Col, Box } from 'jsxstyle'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import Input from '../../../components/input'
import DateInput from '../../../components/input/date-input'
import Button from '../../../components/button'
import RadioButton from '../../../components/radio-button'
import Skeleton from '../../../components/skeleton'
import Y from '../../../y18n'
import isAgeValid from '../is-age-valid'
import * as travelerActions from '../../../store/ducks/travelers'
import * as addonActions from '../../../store/ducks/addons'
import * as flightMealActions from '../../../store/ducks/flight-meals'
import * as skiLuggageActions from '../../../store/ducks/skiluggage'
import * as transferActions from '../../../store/ducks/transfers'
import * as customerActions from '../../../store/ducks/customer'
import * as payingCustomerActions from '../../../store/ducks/paying-customer'
import * as reservationActions from '../../../store/ducks/reservation'
import * as golfbagActions from '../../../store/ducks/golfbags'
import Checkbox from '../../../components/checkbox'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId,
  reservation: state.reservation.summary,
  travelerState: state.travelers.data,
  loading: state.travelers.loading,
  customerState: state.customer
})

const mapDispatchToProps = dispatch => ({
  travelerActions: bindActionCreators(travelerActions, dispatch),
  customerActions: bindActionCreators(customerActions, dispatch),
  payingCustomerActions: bindActionCreators(payingCustomerActions, dispatch),
  reservationActions: bindActionCreators(reservationActions, dispatch),
  addonActions: bindActionCreators(addonActions, dispatch),
  skiLuggageActions: bindActionCreators(skiLuggageActions, dispatch),
  transferActions: bindActionCreators(transferActions, dispatch),
  flightMealActions: bindActionCreators(flightMealActions, dispatch),
  golfbagActions: bindActionCreators(golfbagActions, dispatch)
})

const formats = [moment.ISO_8601, 'YYYY-MM-DD']

class TravelerInfo extends Component {
  form = {}
  state = { changed: false, copyLastName: false }

  componentDidMount () {
    this.props.reservation.search.travelType === 'Flight'
      ? this.props.travelerActions.queryTravelers(this.props.reservationId)
      : this.props.travelerActions.queryTravelersByRoom(
          this.props.reservationId
        )

    // query.then(() => {
    //   // set email address and first traveler details from customer if logged in
    //   if (this.props.customerState.isAuthenticated) {
    //     this.props.customerActions.queryCustomerDetails().then(() => {
    //       let customer = this.props.customerState.details
    //       if (customer) {
    //         if (this.props.travelerState.length && !this.props.travelerState[0].travelers[0].firstName) {
    //           this.handleInputChange(customer.firstName, 'firstName', 0, 0)
    //           this.handleInputChange(customer.lastName, 'lastName', 0, 0)
    //         }
    //       }
    //     })
    //   }
    // })
  }

  handleValidateAge (traveler, e, index) {
    const elem = document.querySelector(
      `input[name="traveler${index}-birthdate"]`
    )
    const isValidDate = moment(traveler.birthDate, formats, true).isValid()
    if (!elem) return

    if (e && traveler.birthDate && isValidDate) {
      if (
        !isAdult(traveler.age) &&
        !isAgeValid(traveler, this.props.reservation)
      ) {
        elem.setCustomValidity(
          Y`The date of birth does not match the specified age`
        )
      } else if (
        isAdult(traveler.age) &&
        !isAgeValid(traveler, this.props.reservation)
      ) {
        elem.setCustomValidity(Y`You must be at least 18 years old`)
      } else {
        elem.setCustomValidity('')
      }
    } else if (e) {
      elem.setCustomValidity(Y`Invalid date`)
    }
  }

  submit (traveler) {
    this.props.reservationActions
      .submitSetTravelerDetails({
        reservationId: this.props.reservationId,
        travelers: [traveler]
      })
      .then(() => {
        this.props.flightMealActions.queryFlightMeals(this.props.reservationId)
        this.props.addonActions.queryAddons(this.props.reservationId, 'SkiPass')
        this.props.addonActions.queryAddons(
          this.props.reservationId,
          'SkiRental'
        )
        this.props.addonActions.queryAddons(
          this.props.reservationId,
          'SkiSchool'
        )
        this.props.addonActions.queryAddons(this.props.reservationId, 'Hiking')
        this.props.skiLuggageActions.querySkiLuggage(this.props.reservationId)
        this.props.transferActions.queryTransfers(this.props.reservationId)
        this.props.golfbagActions.queryGolfBags(this.props.reservationId)
      })
  }

  handleInputChange (val, property, travelerIndex, roomIndex) {
    this.setState({ ...this.state, changed: true })
    if (this.props.reservation.search.travelType === 'Flight') {
      if (
        property === 'lastName' &&
        this.state.copyLastName &&
        travelerIndex === 0
      ) {
        this.props.travelerState.forEach((t, i) => {
          this.props.travelerActions.setTravelerValue(val, property, i)
        })
      } else {
        this.props.travelerActions.setTravelerValue(
          val,
          property,
          travelerIndex,
          roomIndex
        )
      }
    } else {
      if (
        property === 'lastName' &&
        this.state.copyLastName &&
        travelerIndex === 0 &&
        (roomIndex === 0 || !roomIndex)
      ) {
        this.props.travelerState.forEach((t, i) => {
          t.travelers.forEach((tt, j) => {
            this.props.travelerActions.setRoomTravelerValue(val, property, j, i)
          })
        })
      } else {
        this.props.travelerActions.setRoomTravelerValue(
          val,
          property,
          travelerIndex,
          roomIndex
        )
      }
    }
  }

  handleOnBlur (traveler, roomIndex, travelerIndex, e) {
    this.handleValidateAge(traveler, e, `${travelerIndex}-${roomIndex}`)
    const pc = this.props.reservation.payingCustomer

    if (this.state.changed && this.form[traveler.id].checkValidity()) {
      if (
        roomIndex === 0 &&
        travelerIndex === 0 &&
        // !this.props.customerState.isAuthenticated &&
        traveler.firstName &&
        traveler.lastName &&
        (!pc || !pc.emailAddress)
      ) {
        this.props.payingCustomerActions.submitSetPayingCustomer(
          'paying-customer',
          {
            reservationId: this.props.reservationId,
            firstName: traveler.firstName,
            lastName: traveler.lastName,
            birthDate: traveler.birthDate
          }
        )
      }

      this.submit(traveler)
      this.setState({ ...this.state, changed: false })
    } else {
      // this is for triggering hml5 validation messages
      // if (traveler.firstName && traveler.lastName && traveler.genderCode && traveler.birthDate) {
      //   this.form[traveler.id].querySelector('#submit').click()
      // }
    }
  }

  handleExtraInformationChange (val, label, travelerIndex, roomIndex) {
    this.handleInputChange(
      { text: val, label },
      'extraBookingInformation',
      travelerIndex,
      roomIndex
    )
  }

  renderExtraInformation (
    extraInfoPkge,
    traveler,
    travelerIndex,
    roomIndex,
    loading = false
  ) {
    return (
      <Row className='flex-margin' width='50%'>
        <Input
          type='text'
          required={extraInfoPkge.mandatory}
          label={
            loading ? <Skeleton style={{ width: '20%' }} /> : extraInfoPkge.text
          }
          name={`traveler${roomIndex}-extraInfo`}
          value={
            traveler.extraBookingInformation
              ? traveler.extraBookingInformation.text
              : ''
          }
          onChange={value =>
            this.handleExtraInformationChange(
              value,
              extraInfoPkge.text,
              travelerIndex,
              roomIndex
            )
          }
          onBlur={() => this.handleOnBlur(traveler, roomIndex, travelerIndex)}
        />
      </Row>
    )
  }

  renderTravelerForm (
    traveler,
    today,
    genders,
    roomIndex,
    travelerIndex,
    loading = false
  ) {
    let travelerRoomIndex = `${travelerIndex}-${roomIndex}`
    const status = this.props.reservation.status
    const disabled = status !== 'Pending'
    const travelerNotRoom = this.props.reservation.travelers.find(
      x => x.id === traveler.id
    )
    const pkge =
      travelerNotRoom &&
      this.props.reservation.packages.find(
        x => x.id === travelerNotRoom.packageId
      )
    return (
      <form
        className='traveler-form'
        key={travelerIndex}
        ref={form => {
          this.form[traveler.id] = form
        }}
        onSubmit={e => {
          // this is for showing error message, which is triggered from onBlur
          e.preventDefault()
        }}
      >
        <CardContent style={{ borderTop: '1px solid rgb(238, 238, 238)' }}>
          <h5 className='nomargin' style={{ fontWeight: '400' }}>
            {loading ? (
              <Skeleton style={{ width: '20%' }} />
            ) : traveler.age !== null && traveler.age < 18 ? (
              `${Y`Child`}: ${traveler.age} ${Y`year`}`
            ) : (
              `${Y`Adult`}${
                travelerRoomIndex === '0-0' ? ` - ${Y`main traveler`}` : ''
              }`
            )}
          </h5>
          <Row className='flex-margin'>
            <Input
              type='text'
              required={!loading}
              disabled={loading || disabled}
              label={
                loading ? <Skeleton style={{ width: '20%' }} /> : Y`Firstname`
              }
              name={`traveler${travelerRoomIndex}-fname`}
              autoComplete='given-name'
              value={traveler.firstName || ''}
              onChange={val =>
                this.handleInputChange(
                  val,
                  'firstName',
                  travelerIndex,
                  roomIndex
                )
              }
              onBlur={() =>
                this.handleOnBlur(traveler, roomIndex, travelerIndex)
              }
            />

            <Input
              type='text'
              required={!loading}
              disabled={loading || disabled}
              label={
                loading ? <Skeleton style={{ width: '20%' }} /> : Y`Lastname`
              }
              name={`traveler${travelerRoomIndex}-lname`}
              autoComplete='family-name'
              value={traveler.lastName || ''}
              onChange={val =>
                this.handleInputChange(
                  val,
                  'lastName',
                  travelerIndex,
                  roomIndex
                )
              }
              onBlur={() =>
                this.handleOnBlur(traveler, roomIndex, travelerIndex)
              }
            />
          </Row>

          <Row className='flex-margin'>
            <DateInput
              type='tel'
              required={!loading}
              disabled={loading || disabled}
              label={
                loading ? <Skeleton style={{ width: '20%' }} /> : Y`Birth date`
              }
              value={traveler.birthDate}
              name={`traveler${travelerRoomIndex}-birthdate`}
              onChange={val =>
                this.handleInputChange(
                  val,
                  'birthDate',
                  travelerIndex,
                  roomIndex
                )
              }
              onBlur={e =>
                this.handleOnBlur(traveler, roomIndex, travelerIndex, e)
              }
            />

            <RadioButton
              label={
                loading ? <Skeleton style={{ width: '20%' }} /> : Y`Gender`
              }
              name={`traveler${travelerRoomIndex}-gender`}
              alternatives={genders}
              required={!loading}
              disabled={loading || disabled}
              checkedValue={traveler.genderCode}
              onChange={val => {
                this.handleInputChange(
                  val,
                  'genderCode',
                  travelerIndex,
                  roomIndex
                )
                window.setTimeout(() => {
                  this.handleOnBlur({
                    ...traveler,
                    genderCode: val
                  })
                }, 10)
              }}
            />
          </Row>
          {pkge &&
            pkge.extraBookingInformation &&
            this.renderExtraInformation(
              pkge.extraBookingInformation,
              traveler,
              travelerIndex,
              roomIndex,
              loading
            )}
          {roomIndex === 0 && travelerIndex === 0 && (
            <label
              style={{
                marginTop: '.9375rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Checkbox
                disabled={loading || disabled}
                style={{ marginRight: 8 }}
                value={this.state.copyLastName}
                onChange={() => {
                  this.setState(
                    {
                      ...this.state,
                      copyLastName: !this.state.copyLastName
                    },
                    () => {
                      if (this.state.copyLastName) {
                        this.handleInputChange(
                          traveler.lastName || '',
                          'lastName',
                          0,
                          0
                        )
                      }
                    }
                  )
                }}
                name='accept'
              />

              {Y`Everyone in the company has the same lastname`}
            </label>
          )}
        </CardContent>
        <Button id='submit' style={{ display: 'none' }} type='submit'>
          {''}
        </Button>
      </form>
    )
  }

  renderTravelersByRoom (rooms, today, genders) {
    return rooms.map((room, roomIndex) => (
      <Card key={roomIndex}>
        <CardHeader>
          {rooms.length <= 1 ? (
            <CardTitle>{Y`Who should travel?`}</CardTitle>
          ) : (
            <Col>
              <CardTitle>
                {`${Y`Who lives in room`} ${roomIndex + 1}`}?
              </CardTitle>
              <Box className='nomargin' textAlign='center'>
                {room.description}
              </Box>
            </Col>
          )}
        </CardHeader>
        {this.props.reservation.status !== 'Pending' && (
          <CardHeader style={{ backgroundColor: 'rgb(255,244,197)' }}>
            <Box className='nomargin' textAlign='center'>
              {Y`Do you want to change travelers' information? Contact us at ${Y`AlpInfoEmail`} or ${Y`AlpInfoPhone`} so we will help you`}
            </Box>
          </CardHeader>
        )}
        {room.travelers.map((traveler, travelerIndex) =>
          this.renderTravelerForm(
            traveler,
            today,
            genders,
            roomIndex,
            travelerIndex
          )
        )}
      </Card>
    ))
  }

  renderFlightsTraveler (travelers, today, genders) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{Y`Who should travel?`}</CardTitle>
        </CardHeader>
        {this.props.reservation.status !== 'Pending' && (
          <CardHeader style={{ backgroundColor: 'rgb(255,244,197)' }}>
            <Box className='nomargin' textAlign='center'>
              {Y`Do you want to change travelers' information? Contact us at ${Y`AlpInfoEmail`} or ${Y`AlpInfoPhone`} so we will help you`}
            </Box>
          </CardHeader>
        )}
        {travelers.map((traveler, travelerIndex) =>
          this.renderTravelerForm(traveler, today, genders, 0, travelerIndex)
        )}
      </Card>
    )
  }

  render () {
    let today = moment()
    let genders = [
      { id: 'M', description: Y`Male` },
      { id: 'F', description: Y`Female` }
    ]

    if (this.props.loading) {
      return (
        <Card>
          <CardHeader>
            <CardTitle>
              <Skeleton style={{ width: '20%' }} />
            </CardTitle>
          </CardHeader>
          {this.renderTravelerForm({}, today, genders, 0, 0, true)}
        </Card>
      )
    }

    return this.props.reservation.search.travelType === 'Flight'
      ? this.renderFlightsTraveler(this.props.travelerState, today, genders)
      : this.renderTravelersByRoom(this.props.travelerState, today, genders)
  }
}

TravelerInfo = connect(mapStateToProps, mapDispatchToProps)(TravelerInfo)
export default withRouter(TravelerInfo)
