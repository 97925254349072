import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Y from '../../../y18n'
import Input from '../../../components/input'
import CustomerCategoryRoomsDialog from './customer-category-rooms-dialog'
import * as customerCategoriesActions from '../../../store/ducks/customer-categories'
import * as reservationActions from '../../../store/ducks/reservation'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  customerCategories: state.customerCategories.data,
  rooms: state.reservation.search.rooms
})

const mapDispatchToProps = dispatch => ({
  customerCategoriesActions: bindActionCreators(customerCategoriesActions, dispatch),
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class CustomerCategoryRoomsInput extends Component {
  state = { showDialog: false }

  componentDidMount () {
    this.props.customerCategoriesActions.queryCustomerCategories()
  }

  handleSelectCustomerCategory = cc => number => {
    this.props.customerCategoriesActions.setNumberOfTravelersInRoom(cc.code, number, cc.roomIndex)
  }

  handleSelectAge = room => age => {
    this.props.customerCategoriesActions.setCustomerAge(
      room.traveler,
      room.roomIndex,
      room.travelerIndex,
      parseInt(age, 10)
    )
  }

  handleSelectNumberOfRooms = value => {
    this.props.customerCategoriesActions.setNumberOfRooms(value)
  }

  renderSelectedCustomerCategories (rooms) {
    let travelers = Array.prototype.concat.apply([], rooms.map(r => r.travelers))
    return `${rooms.length} ${Y`Room`}, ${travelers.filter(t => isAdult(t.age)).length} ${Y`adults`}${travelers.filter(t => !isAdult(t.age)).length ? `, ${travelers.filter(t => !isAdult(t.age)).length} ${Y`children`}` : ''}`
  }

  render () {
    return [
      <Input
        className='cc'
        key={0}
        readOnly
        label='Travelers'
        hideLabel
        style={{ width: '80%' }}
        value={this.renderSelectedCustomerCategories(this.props.rooms)}
        onFocus={e => {
          this.setState({ showDialog: true })
          e.target.blur()
        }}
      />,
      <CustomerCategoryRoomsDialog
        key={1}
        show={this.state.showDialog}
        rooms={this.props.rooms}
        customerCategories={this.props.customerCategories}
        onClose={shouldSearch => {
          this.setState({ showDialog: false })
          if (shouldSearch) this.props.onSearch()
        }}
        onCustomerCategoryChange={this.handleSelectCustomerCategory}
        onNumberOfRoomsChange={this.handleSelectNumberOfRooms}
        onSelectAge={this.handleSelectAge}
      />
    ]
  }
}

CustomerCategoryRoomsInput = connect(mapStateToProps, mapDispatchToProps)(CustomerCategoryRoomsInput)
export default CustomerCategoryRoomsInput
