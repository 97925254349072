import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Y from '../../../y18n'
import Input from '../../../components/input'
import FlightTravelersDialog from './flight-travelers-dialog'
import * as customerCategoriesActions from '../../../store/ducks/customer-categories'
import * as reservationActions from '../../../store/ducks/reservation'
import isAdult from '../../../utils/is-adult'

const mapStateToProps = state => ({
  customerCategories: state.customerCategories.data,
  flightTravelers: state.reservation.search.flightTravelers
})

const mapDispatchToProps = dispatch => ({
  customerCategoriesActions: bindActionCreators(customerCategoriesActions, dispatch),
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class FlightTravelersInput extends Component {
  state = { showDialog: false }

  componentDidMount () {
    this.props.customerCategoriesActions.queryCustomerCategories()
  }

  renderSelectedTravelers (travelers) {
    return `${travelers.filter(t => isAdult(t.age)).length} ${Y`adults`}${travelers.filter(t => !isAdult(t.age)).length ? `, ${travelers.filter(t => !isAdult(t.age)).length} ${Y`children`}` : ''}`
  }

  render () {
    return (
      <React.Fragment>
        <Input
          className='cc'
          key={0}
          readOnly
          style={{ width: '80%' }}
          value={this.renderSelectedTravelers(this.props.flightTravelers)}
          onFocus={e => {
            this.setState({ showDialog: true })
            e.target.blur()
          }}
        />
        <FlightTravelersDialog
          key={1}
          show={this.state.showDialog}
          customerCategories={this.props.customerCategories}
          onClose={shouldSearch => {
            this.setState({ showDialog: false })
            if (shouldSearch) this.props.onSearch()
          }}
        />
      </React.Fragment>
    )
  }
}

FlightTravelersInput = connect(mapStateToProps, mapDispatchToProps)(FlightTravelersInput)
export default FlightTravelersInput
