import React from 'react'
import PropTypes from 'prop-types'
import './card-title.css'

export const CardTitle = ({ children, ...rest }) => {
  return (
    <h4 className='card-title' {...rest}>
      {children}
    </h4>
  )
}

CardTitle.propTypes = {
  children: PropTypes.node.isRequired
}

export default CardTitle
