import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Card, CardTitle, CardHeader } from '../card'
import { Row, Col } from 'jsxstyle'
import { IconButton } from '../icon'
import { FadeIn } from '../animate'
import './dialog.css'

export class Dialog extends Component {
  componentDidMount () {
    window.addEventListener('keydown', this.handleEscPress, false)
    this.dialogContentRef = this.dialogRef.querySelector('.dialog-content')
    if (this.props.show) disableBodyScroll(this.dialogContentRef)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscPress, false)
    clearAllBodyScrollLocks()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.show) {
      disableBodyScroll(this.dialogContentRef)
    } else if (!nextProps.show) {
      clearAllBodyScrollLocks()
    }
  }

  handleEscPress = e => {
    if (e.keyCode === 27) this.props.onClose()
  }

  handleClose = () => {
    this.props.onClose()
  }

  render () {
    if (!this.props.show) {
      return null
    }

    return ReactDOM.createPortal(
      <FadeIn>
        <div
          className='dialog-container'
          ref={dialog => (this.dialogRef = dialog)}
        >
          <Card
            className={`dialog ${this.props.className || ''}`}
            style={this.props.style}
          >
            <Col height='100%'>
              <Row alignItems='center' className='grey-bg' minHeight='50px'>
                <CardHeader
                  style={{ flex: 1, marginRight: 'calc(-24px + -0.4687rem)' }}
                >
                  <CardTitle>{this.props.title || ' '}</CardTitle>
                </CardHeader>
                <IconButton
                  style={{ marginRight: '.4687rem', fontWeight: 'bold' }}
                  onClick={this.handleClose}
                >
                  close
                </IconButton>
              </Row>
              {this.props.children}
            </Col>
          </Card>
          <div className='dialog-backdrop' onClick={this.handleClose} />
        </div>
      </FadeIn>,
      document.body
    )
  }
}

Dialog.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.any
}

export default Dialog
