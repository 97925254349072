import React from 'react'
import { connect } from 'react-redux'
import { Box, Col } from 'jsxstyle'
import ImageCarousel from '../../../components/image-carousel'
import moment from 'moment-timezone'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

const HotelCustomerComments = props => {
  const { proposal } = props
  if (!proposal) return null
  const hotel = proposal.hotel
  if (hotel.info.customerComments.length < 1) return null
  return (
    <Box
      mediaQueries={{ sm: 'screen and (max-width: 992px)', lg: 'screen and (min-width: 1380px)' }}
      height='200px'
      lgBorderBottom='1px solid rgb(238,238,238)'
      smBorderTop='1px solid rgb(238,238,238)'
      smMarginTop='0.9375rem'
    >
      <ImageCarousel autoplay smallArrows>
        {hotel.info.customerComments
          .filter(c => c.comment.length < 270)
          .map((comment, i) => (
            <React.Fragment key={i}>
              <Col maxWidth='calc(100% - 56px)' height='200px' justifyContent='center' margin='0 auto'>
                <Box maxWidth='680px' margin='0 auto'>
                  <p style={{ marginBottom: 0, textAlign: 'center', fontSize: '0.9rem' }}>"{comment.comment}"</p>
                  <p style={{ marginTop: '4px', textAlign: 'right', fontWeight: 'bold', fontSize: '0.9rem' }}>
                    {comment.name}, {moment(comment.date).format('MMM YYYY')}
                  </p>
                </Box>
              </Col>
            </React.Fragment>
          ))}
      </ImageCarousel>
    </Box>
  )
}

const Connect = connect(mapStateToProps)(HotelCustomerComments)
export default Connect
