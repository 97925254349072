import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { v4 as uuid } from 'uuid'
import moment from 'moment-timezone'
import Summary from '../../components/summary'
import { Page, Layout } from '../../components/layout'
import LoadingSummary from './loading-summary'
import * as reservationActions from '../../store/ducks/reservation'

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class CreatePage extends Component {
  async handleCodes (command, reservationId) {
    try {
      if (command.offerCode) {
        await this.props.reservationActions.submitSetOfferCode({
          reservationId: reservationId,
          offerCode: command.offerCode
        })
      }
      if (command.referralCode) {
        await this.props.reservationActions.submitSetReferralCode({
          reservationId: reservationId,
          referralCode: command.referralCode
        })
      }
      this.next(reservationId)
    } catch (error) {
      this.next(reservationId)
    }
  }

  next (reservationId) {
    setTimeout(() => this.props.history.replace(`/proposal/${reservationId}`))
  }

  componentDidMount () {
    const search = this.props.location.search // could be '?foo=bar'
    const params = new URLSearchParams(search)
    const reservationId = uuid()

    const travelType = params.get('travelType')
    const charterTypeId = params.get('charterTypeId')
    const destinationId = params.get('destinationId')
    const offerCode = params.get('offerCode')
    const nordicChoiceClub = params.get('NCC')
    const referralCode = params.get('referral')
    let outwardDate = params.get('out').replace(' ', '+')
    outwardDate = moment.parseZone(outwardDate)
    let homewardDate = params.get('home').replace(' ', '+')
    homewardDate = moment.parseZone(homewardDate)
    let packageIds = params.get('packageIds')

    let command = {
      reservationId,
      charterTypeId,
      destinationId,
      outwardDate,
      homewardDate,
      salesChannelId: 'Web'
    }

    if (nordicChoiceClub) {
      sessionStorage.setItem('NCC', '1')
    }

    if (packageIds) {
      command.packageIds = JSON.parse(packageIds)
    }

    if (offerCode) {
      command.offerCode = offerCode
    }

    if (referralCode) {
      command.referralCode = referralCode
    }

    if (travelType === 'Charter') {
      const airportId = params.get('airportId')
      const hotelId = params.get('hotelId')

      let rooms = params.get('rooms')
      rooms = JSON.parse(rooms)
      rooms = rooms.map(room => {
        return {
          travelers: room.map(age => {
            return { age: age ?? 40 }
          })
        }
      })

      command = {
        ...command,
        airportId,
        rooms
      }

      if (hotelId) command.hotelId = hotelId
    } else if (travelType === 'Flight') {
      const airportId = params.get('airportId')
      let travelers = params.get('travelers')
      travelers = JSON.parse(travelers)

      command = {
        ...command,
        airportId,
        flightTravelers: travelers.map(t => {
          return {
            age: t
          }
        })
      }
    } else if (travelType === 'Hotel') {
      const hotelId = params.get('hotelId')

      let rooms = params.get('rooms')
      rooms = JSON.parse(rooms)
      rooms = rooms.map(room => {
        return {
          travelers: room.map(age => {
            return { age: age ?? 40 }
          })
        }
      })

      command = {
        ...command,
        rooms
      }

      if (hotelId) command.hotelId = hotelId
    }

    this.props.reservationActions
      .submitCreateReservation(command)
      .then(res => this.handleCodes(command, res.data.reservationId))
  }

  render () {
    return (
      <Page>
        <Layout>
          <Summary sticky>
            <LoadingSummary />
          </Summary>
        </Layout>
      </Page>
    )
  }
}

CreatePage = connect(null, mapDispatchToProps)(CreatePage)
export default CreatePage
