import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import api from '../api'
import { setSearch } from './reservation'

const initialState = {
  error: null,
  loading: false,
  altPeriodLoading: false,
  soldOutDate: null,
  period: {
    proposals: Array.from({ length: 4 }, v => {
      return { skeleton: true, hotel: {} }
    })
  },
  searchInfo: null,
  alternativePeriods: {}
}

const proposalSlice = createSlice({
  name: 'proposal',
  initialState,
  reducers: {
    QUERY_PROPOSAL: (state, action) => {
      state.loading = true
      state.error = null
      state.period = action.payload
        ? { ...initialState.period, out: action.payload.out }
        : initialState.period
    },
    QUERY_PROPOSAL_SUCCESS: state => {
      state.loading = false
      state.error = null
    },
    SET_PERIOD: (state, action) => {
      state.period = action.payload
    },
    SET_SEARCH_INFO: (state, action) => {
      state.searchInfo = action.payload
    },
    SET_SOLDOUT_DATE: (state, action) => {
      state.soldOutDate = action.payload
    },
    QUERY_ALTERNATIVE_PROPOSAL_SUCCESS: (state, action) => {
      state.loading = false
      state.altPeriodLoading = false
      state.alternativePeriods = {
        ...state.alternativePeriods,
        [`${moment
          .parseZone(action.payload.out)
          .format('YYYY-MM-DD')}_${moment
          .parseZone(action.payload.home)
          .format('YYYY-MM-DD')}`]: action.payload
      }
    },
    QUERY_PROPOSAL_ERROR: state => {
      state.loading = false
      state.error = 'Error'
    },
    RESET_PROPOSALS: state => {
      state = initialState
    }
  }
})

export const {
  QUERY_PROPOSAL,
  QUERY_PROPOSAL_SUCCESS,
  SET_PERIOD,
  SET_SEARCH_INFO,
  SET_SOLDOUT_DATE,
  QUERY_ALTERNATIVE_PROPOSAL_SUCCESS,
  QUERY_PROPOSAL_ERROR,
  RESET_PROPOSALS
} = proposalSlice.actions

export default proposalSlice.reducer

// thunks
export const queryProposal = (
  reservationId,
  outDate = null,
  homeDate = null
) => {
  return (dispatch, getState) => {
    let reservationSearch = getState().reservation.search
    let travelType = getState().reservation.search.travelType
    let proposal = getState().proposal
    let sortOrder = sessionStorage.getItem('sortOrder')
    let filterString = sessionStorage.getItem('filter')
    let filter = null
    if (filterString) filter = JSON.parse(filterString)
    let period = proposal.alternativePeriods[`${outDate}_${homeDate}`]
    let searchInfo = { ...proposal.searchInfo }
    dispatch(QUERY_PROPOSAL(period))

    if (period) {
      dispatch(
        SET_PERIOD({ ...period, proposals: initialState.period.proposals })
      )

      setTimeout(() => {
        dispatch(SET_PERIOD(period))
        dispatch(SET_SEARCH_INFO(searchInfo))
        dispatch(
          setSearch({
            ...searchInfo,
            charterPeriodId: `${period.out};${period.home}`
          })
        )
      })
    } else {
      let queryParams = {
        reservationId,
        showAlternativeFlights: travelType === 'Flight'
      }
      if (sortOrder) queryParams = { ...queryParams, sortOrder }
      if (filter) queryParams = { ...queryParams, ...filter }

      let query = api.query('travelBooking/proposals', queryParams)

      query.then(res => {
        const period = res.data.periods.length
          ? res.data.periods[0]
          : { proposals: [] }
        searchInfo = {
          ...res.data.searchInfo,
          charterPeriodId:
            period.out && period.home
              ? `${period.out};${period.home}`
              : reservationSearch.charterPeriodId
        }

        if ((searchInfo.charterPeriodId && !period.out) || !period.home) {
          let [out, home] = searchInfo.charterPeriodId.split(';')
          period.out = out
          period.home = home
        }

        dispatch(QUERY_PROPOSAL_SUCCESS())
        dispatch(SET_PERIOD(period))
        dispatch(QUERY_ALTERNATIVE_PROPOSAL_SUCCESS(period))
        dispatch(SET_SEARCH_INFO(searchInfo))
        dispatch(setSearch(searchInfo))
      })

      return query
    }
  }
}

export const queryAlternativeProposals = (
  reservationId,
  outwardDateFrom,
  outwardDateTo,
  homewardDateFrom,
  homewardDateTo
) => {
  return (dispatch, getState) => {
    let travelType = getState().reservation.search.travelType
    let sortOrder = sessionStorage.getItem('sortOrder')
    let params = {
      reservationId,
      outwardDateFrom,
      outwardDateTo,
      homewardDateFrom,
      homewardDateTo,
      showAlternativeFlights: travelType === 'Flight'
    }
    if (sortOrder) params.sortOrder = sortOrder
    let filterString = sessionStorage.getItem('filter')
    let filter = null
    if (filterString) filter = JSON.parse(filterString)
    if (filter) params = { ...params, ...filter }

    let query = api.query('travelBooking/proposals', params)
    query
      .then(res => {
        dispatch(
          QUERY_ALTERNATIVE_PROPOSAL_SUCCESS(
            res.data.periods.length ? res.data.periods[0] : { proposals: [] }
          )
        )
      })
      .catch(err => {
        Sentry.captureException(err)
        dispatch(QUERY_PROPOSAL_ERROR())
      })

    return query
  }
}

export const resetProposals = () => {
  return dispatch => {
    dispatch(RESET_PROPOSALS())
  }
}

export const setSoldOutDate = date => {
  return dispatch => {
    dispatch(SET_SOLDOUT_DATE(date))
  }
}
