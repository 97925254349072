import React, { Component } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { CardContent } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import {
  Dialog,
  DialogContent,
  DialogButtons
} from '../../../components/dialog'
import Button from '../../../components/button'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'

class ExcursionDialog extends Component {
  render () {
    const { excursion, onClose } = this.props

    return (
      <Dialog
        show
        onClose={onClose}
        title={`${excursion.description} - ${
          moment.parseZone(excursion.date).format('dddd') +
          (excursion.numberOfDays !== 1
            ? ' - ' +
              moment
                .parseZone(excursion.date)
                .add(excursion.numberOfDays, 'days')
                .format('dddd')
            : '')
        }`}
      >
        <DialogContent>
          <CardContent>
            <p>{excursion.info}</p>
          </CardContent>
          <List className='no-odd border'>
            {excursion.travelers.map((traveler, i) => {
              return (
                <ListItem key={traveler.id}>
                  <Row justifyContent='space-between' alignItems='center'>
                    <div>
                      <Row width='100%'>
                        <h3 className='nomargin' style={{ fontWeight: 'bold' }}>
                          {/^\s+$/.test(traveler.name)
                            ? traveler.age !== null && traveler.age < 18
                              ? `${Y`Child`} ${traveler.age} ${Y`year`}`
                              : Y`Adult` + ' ' + traveler.id
                            : traveler.name}
                        </h3>
                        <p style={{ margin: 0 }}>
                          {' '}
                          -{' '}
                          <Currency>
                            {
                              traveler.articles.find(
                                a => a.description === excursion.description
                              ).priceAmount
                            }
                          </Currency>
                        </p>
                      </Row>
                    </div>
                    <Switch
                      disabled={
                        traveler.articles.find(
                          a => a.description === excursion.description
                        ).blocked
                      }
                      uncheckedIcon={false}
                      checkedIcon={false}
                      offColor='#b4b4b4'
                      onChange={value =>
                        this.props.onSelect(
                          value,
                          traveler.articles.find(
                            a => a.description === excursion.description
                          ).articleId,
                          traveler.id,
                          i
                        )
                      }
                      checked={
                        traveler.articles.find(
                          a => a.description === excursion.description
                        ).reserved
                      }
                      onColor='#47a404'
                      id='normal-switch'
                      height={22}
                      width={48}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                    />
                  </Row>
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
        <DialogButtons>
          <Button small onClick={onClose}>{Y`Select`}</Button>
        </DialogButtons>
      </Dialog>
    )
  }
}

ExcursionDialog.propTypes = {
  excursion: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default ExcursionDialog
