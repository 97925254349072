import React, { useState, useEffect, useContext, createContext } from 'react'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import * as Sentry from '@sentry/browser'
import config from '../config'
import Y from '../y18n'
import qs from 'qs'
import { getRedirectUrl } from '../components/redirect-service'

const { REACT_APP_VERSION: appVersion } = process.env
const commandQueue = []
const isLocal = window.location.hostname.includes('local')

const formatObject = command => {
  let obj = { ...command }
  Object.keys(obj).forEach(property => {
    const value = obj[property]
    if (moment.isDate(value)) {
      throw new Error(
        'Date objects not allowed since they lack timezone; use moment objects or strings with timezone'
      )
    } else if (moment.isMoment(value)) {
      obj[property] = value.format()
    } else if (value instanceof Object) {
      formatObject(value)
    }
  })

  return obj
}

const execNext = () => {
  let task = commandQueue[0]
  let headers = {
    'Content-Type': 'application/json',
    'x-clientversion': appVersion,
    'X-Origin-App': 'web'
  }

  const jwt = localStorage.getItem('jwt')
  if (jwt) headers['Authorization'] = jwt

  let redirectUrl = getRedirectUrl()
  headers['X-Origin-App'] = redirectUrl ? 'webViaApp' : 'web'

  if (isLocal) {
    headers['Market'] = window.location.hostname.split('-')[0]
  }
  const url = `${window.location.origin}${config.apiUrl}/command${
    task.controller ? `/${task.controller}` : ''
  }/${task.commandName}`

  fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(task.command)
  })
    .then(res => {
      const isOk = res.ok
      const headers = res.headers // This is how you access the headers
      return res.json().then(data => ({ data, headers, isOk })) // Return both data and headers
    })
    .then(({ data, headers, isOk }) => {
      if (!isOk) {
        const error = new Error(data.error.message)
        error.data = data
        throw error
      }
      commandQueue.shift()
      if (task.returnHeaders) {
        task.resolve({ data: data, headers: headers })
      } else {
        task.resolve(data)
      }
      if (commandQueue.length > 0) execNext()
    })
    .catch(error => {
      commandQueue.shift()
      handleError(error, task.reject, true)
      if (commandQueue.length > 0) execNext()
    })
}

const Api = {
  query: (paths = '', queryParams = null, returnHeaders) => {
    let url = `${window.location.origin}${config.apiUrl}/query/${paths}`

    return new Promise((resolve, reject) => {
      let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-clientversion': appVersion,
        'X-Origin-App': 'web'
      }

      const jwt = localStorage.getItem('jwt')
      if (jwt) headers['Authorization'] = jwt

      let redirectUrl = getRedirectUrl()
      headers['X-Origin-App'] = redirectUrl ? 'webViaApp' : 'web'

      if (isLocal) {
        headers['Market'] = window.location.hostname.split('-')[0]
      }
      if (queryParams) {
        queryParams = formatObject(queryParams)
        url += `?${qs.stringify(queryParams)}`
      }

      fetch(url, { headers: headers })
        .then(res => res.json())
        .then(json => {
          if (returnHeaders) {
            resolve({ data: json, headers: json.headers })
          } else {
            resolve(json)
          }
        })
        .catch(err => handleError(err, reject))
    })
  },

  sendCommand: (commandName, command, controller, returnHeaders = false) => {
    return new Promise((resolve, reject) => {
      command = formatObject(command)
      commandQueue.push({
        commandName,
        controller,
        command,
        resolve,
        reject,
        returnHeaders
      })
      if (commandQueue.length === 1) execNext()
    })
  }
}

const handleError = (error, reject, isCommand = false) => {
  Sentry.captureEvent(error)
  if (error.data && error.data.error && error.data.error.message) {
    toast.error(error.data.error.message)
    if (isCommand) reject(error.data.error.message)
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    toast.error(error.response.data.error.message)
    if (isCommand) reject(error.response.data.error.message)
  } else {
    toast.error(Y`Something went wrong`)
    if (isCommand) reject()
  }
}

// Api Cache context
const ApiCacheContext = createContext([{}, () => {}])
export const ApiCacheProvider = ({ children }) => {
  const [cachedState, setCachedState] = useState({})
  return (
    <ApiCacheContext.Provider value={[cachedState, setCachedState]}>
      {children}
    </ApiCacheContext.Provider>
  )
}
export const ApiCacheConsumer = ApiCacheContext.Consumer

export function useQuery ({
  path,
  initParams = null,
  manual = false,
  compare = [],
  cache = false
}) {
  const [cachedState, setCachedState] = useContext(ApiCacheContext)

  const [state, setState] = useState({
    data: null,
    error: undefined,
    loading: false
  })

  const execQuery = (queryParams = initParams) => {
    const cacheKey = `${path}?${qs.stringify(queryParams)}`

    if (cache) {
      const cachedQuery = cachedState[cacheKey]
      if (cachedQuery) {
        setState({ ...state, data: cachedQuery })
        return
      }
    }

    setState({ ...state, loading: true })

    return Api.query(path, queryParams)
      .then(res => {
        setState({ error: undefined, data: res.data, loading: false })

        if (cache) {
          setCachedState({ ...cachedState, [cacheKey]: res.data })
        }
      })
      .catch(error => setState({ error, loading: false, data: null }))
  }

  useEffect(() => {
    !manual && execQuery()
    // eslint-disable-next-line
  }, compare)

  return [state, execQuery]
}

export function useCommand (path) {
  const [state, setState] = useState({
    done: false,
    error: undefined,
    loading: false
  })

  const sendCommand = command => {
    setState({ ...state, loading: true })
    let cmd = Api.sendCommand(path, command)
    cmd
      .then(() => setState({ ...state, loading: false, done: true }))
      .catch(error => setState({ error, loading: false, done: false }))

    return cmd
  }

  return [state, sendCommand]
}

export default Api
