import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const flightMealsSlice = createSlice({
  name: 'flightMeals',
  initialState,
  reducers: {
    QUERY_FLIGHT_MEALS: state => {
      state.loading = true
      state.error = null
    },
    QUERY_FLIGHT_MEALS_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_FLIGHT_MEAL: (state, action) => {
      state.items = state.items.map((traveler, j) => {
        return traveler.id === action.payload.travelerId
          ? {
              ...traveler,
              meals: traveler.meals.map((meal, k) =>
                meal.articleId === action.payload.mealId
                  ? {
                      ...meal,
                      reserved: action.payload.reserved,
                      blocked: action.payload.blocked
                    }
                  : action.payload.remove
                  ? { ...meal, reserved: false }
                  : meal
              )
            }
          : traveler
      })
    }
  }
})

export const {
  QUERY_FLIGHT_MEALS,
  QUERY_FLIGHT_MEALS_SUCCESS,
  SET_FLIGHT_MEAL
} = flightMealsSlice.actions

export default flightMealsSlice.reducer

// thunks
export const queryFlightMeals = reservationId => {
  return dispatch => {
    const query = api.query('travelbooking/flightMeal', { reservationId })
    query.then(res => dispatch(QUERY_FLIGHT_MEALS_SUCCESS(res.data)))
    return query
  }
}

export const submitAddFlightMeal = command => {
  return (dispatch, getState) => {
    const status = getState().reservation.summary.status
    dispatch(
      SET_FLIGHT_MEAL({
        ...command,
        reserved: true,
        blocked: status === 'Confirmed' || status === 'ConfirmedWithChanges'
      })
    )
    let cmd = api
      .sendCommand('travelBooking/addFlightMeal', command)
      .then(() => dispatch(queryReservationSummary(command.reservationId)))
      .catch(() =>
        dispatch(
          SET_FLIGHT_MEAL({
            ...command,
            reserved: false,
            blocked: false
          })
        )
      )
    return cmd
  }
}

export const submitRemoveFlightMeal = command => {
  return dispatch => {
    dispatch(SET_FLIGHT_MEAL({ ...command, reserved: false, blocked: false }))
    let cmd = api.sendCommand('travelBooking/removeFlightMeal', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
