import { createSlice } from '@reduxjs/toolkit'
import { queryReservationSummary } from './reservation'
import api from '../api'

const initialState = {
  error: null,
  loading: false,
  item: null
}

const halfboardSlice = createSlice({
  name: 'halfboard',
  initialState,
  reducers: {
    QUERY_HALFBOARD: (state, action) => {
      state.loading = false
      state.item = action.payload
    },
    SET_HALFBOARD: (state, action) => {
      state.item = { ...state.item, reserved: action.payload.reserved }
    }
  }
})

export const { QUERY_HALFBOARD, SET_HALFBOARD } = halfboardSlice.actions

export default halfboardSlice.reducer

// thunks
export const queryHalfboard = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/halfboard', { reservationId })
      .then(res => dispatch(QUERY_HALFBOARD(res.data)))
  }
}

export const submitAddHalfboard = command => {
  return dispatch => {
    dispatch(SET_HALFBOARD({ reserved: true }))
    return api
      .sendCommand('travelBooking/addHalfboard', command)
      .then(() => dispatch(queryReservationSummary(command.reservationId)))
  }
}

export const submitRemoveHalfboard = command => {
  return dispatch => {
    dispatch(SET_HALFBOARD({ reserved: false }))
    return api
      .sendCommand('travelBooking/removeHalfboard', command)
      .then(() => dispatch(queryReservationSummary(command.reservationId)))
  }
}
