import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Page, Layout, Content } from '../../components/layout'
import TravelerInfo from './traveler-info'
import FlightMealList from './flight-meal-list'
import DestinationAddonList from '../../containers/destination-addon-list'
import TransferList from './transfer-list'
import SkiLuggageList from './skiluggage-list'
import NextButton from './next-button'
import ScrollToHash from '../../components/scroll-to-hash'
import Summary from '../../components/summary'
import { setRedirectUrl } from '../../components/redirect-service'
import Progress from '../../containers/progress'
import withReservationId from '../../containers/with-reservation-id'
import ShortReservationSummary from '../../containers/short-reservation-summary'
import MyTripHeader from '../../containers/my-trip-header'
import Y from '../../y18n'
import './travelers-page.css'
import GolfBagList from './golfbag-list'

const mapStateToProps = state => ({
  summary: state.reservation.summary
})

class TravelersPage extends Component {
  componentDidMount () {
    setRedirectUrl(this.props.location.search)
  }

  render () {
    const { summary } = this.props

    return (
      <ScrollToHash>
        <Page>
          <Layout>
            <Summary sticky>
              <ShortReservationSummary />
            </Summary>
            <Content>
              {summary &&
                (summary.status === 'Confirmed' ||
                  summary.status === 'ConfirmedWithChanges') && (
                  <MyTripHeader
                    onClick={e => {
                      if (summary.status === 'ConfirmedWithChanges') {
                        e.preventDefault()
                        window.alert(
                          Y`Please confirm or delete your selected extensions before going back to 'My trip'`
                        )
                      }
                    }}
                    linkTo={`/reservation/${summary.id}`}
                  />
                )}
              {summary &&
                summary.status !== 'Confirmed' &&
                summary.status !== 'ConfirmedWithChanges' && (
                  <Progress active={1} />
                )}
              <div className='travelers'>
                {summary && <TravelerInfo />}
                <TransferList />
                <FlightMealList />
                <DestinationAddonList type='Hiking' />
                <DestinationAddonList type='SkiPass' />
                <SkiLuggageList />
                <GolfBagList />
                <DestinationAddonList type='SkiRental' />
                <DestinationAddonList type='SkiSchool' />
                <NextButton />
              </div>
            </Content>
          </Layout>
        </Page>
      </ScrollToHash>
    )
  }
}

TravelersPage = withReservationId(TravelersPage)
export default connect(mapStateToProps)(TravelersPage)
