import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Box } from 'jsxstyle'
import Button from '../../../components/button'
import { Col, InlineBlock } from 'jsxstyle'
import Y from '../../../y18n'
import './hotel-description.css'
import Skeleton from '../../../components/skeleton'

const mapStateToProps = state => ({
  proposal: state.roomProposal.item
})

class HotelDescription extends PureComponent {
  state = { showMoreDescription: false }

  render () {
    const { proposal } = this.props

    return (
      <Col
        width='100%'
        className={`hdescription${!this.state.showMoreDescription ? ' hid' : ''}`}
        paddingBottom='.9375rem'
        paddingRight='.9375rem'
      >
        {proposal ? (
          <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: proposal.hotel.info.description }} />
        ) : (
          <React.Fragment>
            {[...Array(3)].map((item, i) => (
              <Box key={i} height='16px' margin='4px 0' width='90%'>
                <Skeleton />
              </Box>
            ))}
          </React.Fragment>
        )}
        {proposal && (
          <Button
            link
            onClick={e => {
              let element = e.target
              this.setState({ ...this.state, showMoreDescription: !this.state.showMoreDescription }, () => {
                if (!this.state.showMoreDescription) {
                  element.scrollIntoView({ behavior: 'instant' })
                }
              })
            }}
          >
            {[
              <InlineBlock key={1} paddingRight='4px'>
                {this.state.showMoreDescription ? Y`Less about the hotel` : Y`More about the hotel`}{' '}
              </InlineBlock>,
              <InlineBlock
                key={2}
                component='span'
                transform={`rotate(${this.state.showMoreDescription ? '-90deg' : '90deg'})`}
              >
                »
              </InlineBlock>
            ]}
          </Button>
        )}
      </Col>
    )
  }
}

HotelDescription = connect(mapStateToProps)(HotelDescription)
export default HotelDescription
