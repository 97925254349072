import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const skiluggageSlice = createSlice({
  name: 'skiluggage',
  initialState,
  reducers: {
    QUERY_SKILUGGAGE: state => {
      state.loading = true
      state.error = null
    },
    QUERY_SKILUGGAGE_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_SKILUGGAGE: (state, action) => {
      state.items = state.items.map(
        (traveler,) =>
          (traveler.id === action.payload.travelerId
            ? {
              ...traveler,
              skiLuggage: { ...traveler.skiLuggage, reserved: action.payload.reserved }
            }
            : traveler)
      )
    }
  }
})

export const { QUERY_SKILUGGAGE, QUERY_SKILUGGAGE_SUCCESS, SET_SKILUGGAGE } = skiluggageSlice.actions

export default skiluggageSlice.reducer

// thunks
export const querySkiLuggage = reservationId => {
  return dispatch => {
    api.query('travelbooking/skiluggage', { reservationId }).then(res => dispatch(QUERY_SKILUGGAGE_SUCCESS(res.data)))
  }
}

export const submitAddSkiLuggage = command => {
  return dispatch => {
    dispatch(SET_SKILUGGAGE({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addSkiluggage', command)
      .then(() => dispatch(queryReservationSummary(command.reservationId)))
      .catch(() => dispatch((SET_SKILUGGAGE({ ...command, reserved: false }))))
    return cmd
  }
}

export const submitRemoveSkiLuggage = command => {
  return dispatch => {
    dispatch(SET_SKILUGGAGE({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeSkiluggage', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
