import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import api from '../api'
import Y from '../../y18n'

const initialState = {
  isAuthenticated: localStorage.getItem('jwt') !== null,
  authToken: localStorage.getItem('jwt'),
  details:
    localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : null,
  reservations: [],
  login: {
    emailAddress: '',
    password: ''
  },
  register: {
    emailAddress: '',
    password: ''
  }
}
const customerSlices = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    SET_AUTH_TOKEN: (state, action) => {
      state.authToken = action.payload
      state.isAuthenticated = true
    },
    QUERY_USER_DETAILS: (state, action) => {
      state.details = action.payload
    },
    QUERY_CUSTOMER_RESERVATIONS: (state, action) => {
      state.reservations = action.payload
    },
    SET_LOGIN_VALUE: (state, action) => {
      state.login[action.payload.name] = action.payload.value
    },
    SET_REGISTER_VALUE: (state, action) => {
      state.register[action.payload.name] = action.payload.value
    },
    SET_DETAILS_VALUE: (state, action) => {
      state.details[action.payload.name] = action.payload.value
    }
  }
})

export const {
  QUERY_CUSTOMER_RESERVATIONS,
  SET_AUTH_TOKEN,
  QUERY_USER_DETAILS,
  SET_LOGIN_VALUE,
  SET_REGISTER_VALUE,
  SET_DETAILS_VALUE
} = customerSlices.actions

export default customerSlices.reducer

// thunks
export const submitCreateCustomer = command => {
  return dispatch => {
    let cmd = api.sendCommand(
      'registerCustomer',
      {
        createCustomer: command,
        setPassword: { password: command.password },
        setCustomer: { reservationId: command.reservationId }
      },
      null,
      true
    )

    cmd.then(res => {
      toast.success(Y`Your account has been created`)
      let authToken =
        res.headers.get('Authorization') ??
        res.headers.get('X-Amzn-Remapped-Authorization')

      if (authToken) {
        localStorage.setItem('jwt', authToken)
        dispatch(SET_AUTH_TOKEN(authToken))
        dispatch(queryCustomerDetails())
      }
    })

    return cmd
  }
}

export const submitUpdateCustoemr = command => {
  return () => {
    return api
      .sendCommand('updateCustomer', { updateCustomer: command })
      .then(res => {
        toast.success(Y`Your account has been updated`)
      })
  }
}

export const submitLoginCustomer = command => {
  return dispatch => {
    return api.sendCommand('loginAgent', command, null, true).then(res => {
      let authToken =
        res.headers.get('Authorization') ??
        res.headers.get('X-Amzn-Remapped-Authorization')
      let successful = res?.data?.data?.successful
      if (successful === false) {
        toast.error(res.data.data.exception?.message || 'Failed to login')
      }
      if (authToken) {
        localStorage.setItem('jwt', authToken)
        dispatch(SET_AUTH_TOKEN(authToken))
        dispatch(queryCustomerDetails())
      }
    })
  }
}

export const submitLogout = () => {
  return dispatch => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    dispatch(QUERY_USER_DETAILS(null))
  }
}

export const submitUpdatePassword = command => {
  return () => {
    return api
      .sendCommand('updatePassword', command)
      .then(() => toast.success(Y`Password has been updated`))
  }
}

export const submitResetPassword = command => {
  return () => {
    return api
      .sendCommand('crm/agentUser/resetPassword', command)
      .then(() => toast.success(Y`Password has been updated`))
  }
}

export const submitSendResetLink = command => {
  return () => {
    return api
      .sendCommand('crm/agentUser/setAgentUserPasswordResetTicket', command)
      .then(() =>
        toast.success(`${Y`Instructions have been sent to`} ${command.email}`)
      )
  }
}

export const submitSetCustomerOnReservation = command => {
  return () => {
    return api.sendCommand('travelBooking/setAgentUser', command)
  }
}

export const queryCustomerDetails = () => {
  return dispatch => {
    return api.query('agentUserDetails').then(res => {
      localStorage.setItem('user', JSON.stringify(res.data))
      dispatch(QUERY_USER_DETAILS(res.data))
    })
  }
}

export const queryCustomerReservations = () => {
  return dispatch => {
    api.query('agentReservations').then(res => {
      dispatch(QUERY_CUSTOMER_RESERVATIONS(res.data))
    })
  }
}

export const setLoginValue = (value, name) => {
  return dispatch => {
    dispatch(SET_LOGIN_VALUE({ value, name }))
  }
}

export const setRegisterValue = (value, name) => {
  return dispatch => {
    dispatch(SET_REGISTER_VALUE({ value, name }))
  }
}

export const setDetailsValue = (value, name) => {
  return dispatch => {
    dispatch(SET_DETAILS_VALUE({ value, name }))
  }
}
