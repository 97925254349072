const getEnvironment = () => {
  const host = window.location.host
  if (host.includes('.dev.') || host.includes('.pr-')) {
    return 'dev'
  } else if (host.includes('.qa.')) {
    return 'qa'
  } else if (host.includes('.local.') || host.includes('localhost')) {
    return 'local'
  }

  return 'prod'
}

export default getEnvironment
