import React, { useState } from 'react'
import { Box, Block } from 'jsxstyle'
import Button from '../../../components/button'
import {
  Dialog,
  DialogContent,
  DialogButtons
} from '../../../components/dialog'
import Y from '../../../y18n'
import { CardContent } from '../../../components/card'
import ImageCarousel from '../../../components/image-carousel'
import './room-list.css'
import isAdult from '../../../utils/is-adult'

const getTravelerDescription = (travelers, travelerIds) => {
  let adults = travelers.filter(t =>
    travelerIds.find(tId => tId === t.id && isAdult(t.age))
  ).length
  let children = travelers.filter(t =>
    travelerIds.find(tId => tId === t.id && !isAdult(t.age))
  ).length
  return `${adults > 0 ? `${adults} ${Y`adults`}` : ''} ${
    adults > 0 && children > 0 ? Y`and` : ''
  } ${children > 0 ? `${children} ${Y`children`}` : ''}`
}

const RoomsInfoDialog = ({
  show = false,
  rooms = [],
  travelers = [],
  onClose,
  onSelectClose
}) => {
  const [currentImg, setCurrentImg] = useState(rooms.map(() => 1))
  return (
    <Dialog
      show={show}
      onClose={onClose}
      title={Y`About the room combination`}
      style={{ maxWidth: 600 }}
    >
      <DialogContent>
        {rooms.length > 1 && (
          <CardContent className='yellow-bg'>
            {Y`This room combination consists of ${rooms.length} rooms in the same hotel that total accommodates`}
            {travelers.filter(t => isAdult(t.age)).length > 0 &&
              ` ${travelers.filter(t => isAdult(t.age)).length} ${Y`adults`}`}
            {travelers.filter(t => !isAdult(t.age)).length > 0 &&
              ` ${Y`and`} ${
                travelers.filter(t => !isAdult(t.age)).length
              } ${Y`children`}`}
          </CardContent>
        )}
        {rooms.map((room, i) => {
          return (
            <CardContent
              key={i}
              style={{
                borderBottom:
                  i < rooms.length - 1 ? '1px solid rgb(204,204,204)' : ''
              }}
            >
              <h5>
                {room.number} {room.description || room.id}
                {room.squareMeter ? ` - ${room.squareMeter}m²` : ''}
              </h5>
              {rooms[i].imageUrls.length > 0 && (
                <Block
                  className='room-list'
                  width='100%'
                  marginBottom='10px'
                  paddingTop='57.25%'
                  position='relative'
                >
                  <ImageCarousel
                    style={{
                      position: 'absolute',
                      width: '100%',
                      top: 0,
                      left: 0
                    }}
                    afterChange={imgIndex =>
                      setCurrentImg(
                        [...currentImg].map((c, j) =>
                          i === j ? imgIndex + 1 : c
                        )
                      )
                    }
                  >
                    {rooms[i].imageUrls.map((img, index) => {
                      return (
                        <img
                          key={index}
                          src={`${img}&w=1200&h=930&fit=cover`}
                          alt=''
                        />
                      )
                    })}
                  </ImageCarousel>
                  {rooms[i].imageUrls.length > 0 && (
                    <Block
                      position='absolute'
                      right='0'
                      bottom='0'
                      padding='.2rem .4687rem'
                      background='rgba(0,0,0,0.7)'
                      color='#fff'
                      fontSize='0.8rem'
                    >
                      {Y`Image`} {currentImg[i]}/{rooms[i].imageUrls.length}
                    </Block>
                  )}
                </Block>
              )}
              <p>{room.information}</p>
              <Box component='p' className='grey-bg' padding='.4687rem'>
                {Y`Room for`}{' '}
                {getTravelerDescription(travelers, room.travelerIds)}
              </Box>
            </CardContent>
          )
        })}
      </DialogContent>
      <div style={{ minHeight: '62px' }}>
        <DialogButtons>
          <Button small onClick={onClose}>{Y`Close`}</Button>
          <Button
            small
            onClick={onSelectClose}
            primary
            fullWidth
            style={{ marginLeft: '.9375rem' }}
          >{Y`Choose room`}</Button>
        </DialogButtons>
      </div>
    </Dialog>
  )
}

export default RoomsInfoDialog
