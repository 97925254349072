import React, { Component } from 'react'
import { connect } from 'react-redux'
import getEnvironment from '../../get-environment'
import { Page, Layout } from '../../components/layout'
import Search from '../../containers/search'
import * as qs from 'qs'

const noUrl = 'http://www.alpereiser.no'
const fiUrl = 'http://www.alppimatkat.fi'
const seUrl = 'http://www.alpresor.se'

const mapStateToProps = state => ({
  market: state.market.market
})

class StartPage extends Component {
  constructor (props) {
    super(props)

    const env = getEnvironment()
    if (env === 'prod' && !props.location.search.includes('redirect')) {
      if (props.market.id === 'SE') {
        window.location.href = seUrl
      } else if (props.market.id === 'NO') {
        window.location.href = noUrl
      } else if (props.market.id === 'FI') {
        window.location.href = fiUrl
      }
    }
  }
  render () {
    let nordicChoiceClubParam = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).NCC
    if (nordicChoiceClubParam) {
      sessionStorage.setItem('NCC', '1')
    }
    return (
      <Page>
        <Layout>
          <div style={{ maxWidth: 1140, margin: '40px auto' }}>
            <Search
              onSubmitted={res => {
                this.props.history.push(`/proposal/${res.reservationId}`)
              }}
            />
          </div>
        </Layout>
      </Page>
    )
  }
}

export default connect(mapStateToProps)(StartPage)
