import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'jsxstyle'
import * as qs from 'qs'
import { SkeletonTheme } from '../../components/skeleton'
import { Card } from '../../components/card'
import Button from '../../components/button'
import { Icon } from '../../components/icon'
import CharterTypeSelect from './charter-type-select'
import AirportSelect from './airport-select'
import DestinationSelect from './destination-select'
import CharterPeriodSelect from './charter-period-select'
import CustomerCategoryRoomsInput from './customer-category-rooms-dialog/customer-category-rooms-input'
import OfferCodeInput from './offer-code-input'
import Y from '../../y18n'
import './search.css'
import * as reservationActions from '../../store/ducks/reservation'
import TravelTypeButtons from './travel-type-buttons'
import FlightTravelersInput from './flight-travelers-dialog/flight-travelers-input'

const mapStateToProps = state => ({
  customerCategory: state.customerCategories,
  reservation: state.reservation,
  proposal: state.proposal
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

const size = 992

class Search extends Component {
  reservationId = null
  state = { isSmallWidth: null }

  componentDidMount () {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
    this.reservationId = this.props.match.params.reservationId
    if (this.reservationId) {
      this.props.reservationActions.queryReservationSummary(this.reservationId)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = () => {
    let width = window.innerWidth
    if (width > size && this.state.isSmallWidth)
      this.setState({ ...this.state, isSmallWidth: false })
    else if (width <= size && !this.state.isSmallWidth)
      this.setState({ ...this.state, isSmallWidth: true })
  }

  formatCommand (command) {
    if (command.travelType === 'Flight') {
      delete command.rooms
    } else {
      delete command.flightTravelers
    }
    delete command.charterPeriodId
    delete command.travelType

    return command
  }

  async handleCodes (command, reservationId) {
    try {
      let referralCode = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }).referral

      if (command.offerCode) {
        await this.props.reservationActions.submitSetOfferCode({
          reservationId: reservationId,
          offerCode: command.offerCode
        })
      }

      if (referralCode) {
        await this.props.reservationActions.submitSetReferralCode({
          reservationId: reservationId,
          referralCode: referralCode
        })
      }

      this.props.onSubmitted({ ...command, reservationId })
    } catch (error) {
      this.props.onSubmitted({ ...command, reservationId })
    }
  }

  handleSubmitForm = e => {
    e.preventDefault()

    const search = this.props.reservation.search
    let periods = search.charterPeriodId.split(';')
    let rooms = search.rooms

    if (this.reservationId) {
      const command = {
        ...search,
        reservationId: this.reservationId,
        outwardDateFrom: moment.parseZone(periods[0]).format(),
        outwardDateTo: moment.parseZone(periods[0]).add(23, 'hours').format(),
        homewardDateFrom: moment.parseZone(periods[1]).format(),
        homewardDateTo: moment.parseZone(periods[1]).add(23, 'hours').format()
      }

      this.props.reservationActions
        .submitSetSearchCriteria(command)
        .then(res => {
          this.handleCodes(command, res.data.command.reservationId)
        })
    } else {
      const command = this.formatCommand({
        ...search,
        salesChannelId: 'Web',
        rooms: rooms,
        outwardDate: periods[0],
        homewardDate: periods[1]
      })

      this.props.reservationActions
        .submitCreateReservation(command)
        .then(res => {
          this.handleCodes(command, res.data.reservationId)
        })
    }
  }

  handleCustomerCategoryDialogSearch = () => {
    const btn = this.form.querySelector('.btn')
    btn && btn.click()
  }

  isButtonDisabled = () => {
    const { search } = this.props.reservation
    const { charterTypeId, destinationId, charterPeriodId, airportId } = search

    switch (search.travelType) {
      case 'Charter':
        return !(charterTypeId && destinationId && charterPeriodId)
      case 'Flight':
        return !(airportId && charterTypeId)
      case 'Hotel':
        return !(charterTypeId && destinationId && charterPeriodId)
      default:
        return true // By default, if the travelType is not defined, disable the button
    }
  }

  render () {
    return (
      <Card className='search'>
        {!this.props.hideTravelType && <TravelTypeButtons />}
        <form onSubmit={this.handleSubmitForm} ref={form => (this.form = form)}>
          <SkeletonTheme color='rgb(255, 236, 120)'>
            {this.state.isSmallWidth ? (
              <Row
                justifyContent='center'
                alignItems='center'
                flexDirection='column'
                padding='0 .9375rem'
                boxSizing='border-box'
                maxWidth='1140px'
                margin='0 auto'
              >
                <Col width='100%'>
                  <CharterTypeSelect />
                  <Row
                    width='100%'
                    alignItems='center'
                    justifyContent='space-between'
                    className='selects'
                  >
                    <AirportSelect />
                    <Icon>arrow_forward</Icon>
                    <DestinationSelect />
                  </Row>
                </Col>
                <Row width='100%'>
                  <CharterPeriodSelect />
                  {this.props.reservation.search.travelType === 'Flight' ? (
                    <FlightTravelersInput
                      onSearch={this.handleCustomerCategoryDialogSearch}
                    />
                  ) : (
                    <CustomerCategoryRoomsInput
                      onSearch={this.handleCustomerCategoryDialogSearch}
                    />
                  )}
                </Row>
                <OfferCodeInput />

                <Button
                  type='submit'
                  primary
                  disabled={this.isButtonDisabled()}
                >
                  {Y`Search trip`} <Icon>arrow_forward</Icon>
                </Button>
              </Row>
            ) : (
              <Row
                className='lg'
                justifyContent='center'
                padding='.9375rem 0 .9375rem .9375rem'
                boxSizing='border-box'
                maxWidth='1140px'
                width='100%'
                margin='0 auto'
              >
                <Col width='100%' marginRight='.9375rem'>
                  <Row>
                    <CharterTypeSelect />
                    <AirportSelect />
                    <Icon style={{ marginTop: '7px' }}>arrow_forward</Icon>
                    <DestinationSelect />
                    <CharterPeriodSelect />
                    {this.props.reservation.search.travelType === 'Flight' ? (
                      <FlightTravelersInput
                        onSearch={this.handleCustomerCategoryDialogSearch}
                      />
                    ) : (
                      <CustomerCategoryRoomsInput
                        onSearch={this.handleCustomerCategoryDialogSearch}
                      />
                    )}
                  </Row>
                  {this.props.reservation.summary &&
                  this.props.reservation.summary.status !== 'Pending' ? null : (
                    <OfferCodeInput />
                  )}
                </Col>
                <Button
                  type='submit'
                  primary
                  disabled={this.isButtonDisabled()}
                >
                  {Y`Search trip`} <Icon>arrow_forward</Icon>
                </Button>
              </Row>
            )}
          </SkeletonTheme>
        </form>
      </Card>
    )
  }
}

Search = connect(mapStateToProps, mapDispatchToProps)(Search)
export default withRouter(Search)
