import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'
import { QueryParamProvider } from 'use-query-params'
import ScrollMemory from './components/scroll-memory'
import CreatePage from './pages/create-page'
import StartPage from './pages/start-page'
import ProposalPage from './pages/proposal-page'
import RoomProposalPage from './pages/room-proposal-page'
import TravelersPage from './pages/travelers-page'
import AddonsPage from './pages/addons-page'
import PaymentProcessPage from './pages/payment-process-page'
import ReservationDetailsPage from './pages/reservation-details-page'
import UserDetails from './containers/user-details'
import NotFoundPage from './pages/not-found-page'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import asyncComponent from './components/async-component'
import Toast from './components/toast'
import { ApiCacheProvider } from './store/api'
import * as marketActions from './store/ducks/market'
import 'react-toastify/dist/ReactToastify.css'

smoothscroll.polyfill()

const AsyncMyPage = asyncComponent(() => import(/* webpackChunkName: "mypage" */ './pages/my-page/my-page'))
const AsyncReservationLoginPage = asyncComponent(() =>
  import(/* webpackChunkName: "bookinglogin" */ './pages/reservation-login-page')
)
const AsyncErrorTestPage = asyncComponent(() =>
  import(/* webpackChunkName: "errortestpage" */ './pages/error-test-page')
)
const AsyncConfirmPage = asyncComponent(() => import(/* webpackChunkName: "confirmpage" */ './pages/confirm-page'))

const AsyncLastMinutePage = asyncComponent(() =>
  import(/* webpackChunkName: "lastminutepage" */ './pages/last-minute-page')
)

class App extends Component {
  componentDidMount () {
    this.props.marketActions.setMarket()
  }

  render () {
    if (!this.props.state.locale) return null

    return (
      <React.Fragment>
        <ApiCacheProvider>
          <UserDetails />
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <ScrollMemory />

              <Switch>
                <Route exact path='/' component={StartPage} />
                <Route path='/new' component={CreatePage} />
                <Route path='/proposal/:reservationId' component={ProposalPage} />
                <Route path='/room-proposal/:reservationId' component={RoomProposalPage} />
                <Route path='/travelers/:reservationId' component={TravelersPage} />
                <Route path='/addons/:reservationId' component={AddonsPage} />
                <Route path='/confirm/:reservationId' component={AsyncConfirmPage} />
                <Route path='/payment/:reservationId/:id/processing/bank' component={PaymentProcessPage} />
                <Route path='/payment/:reservationId/:id/processing' component={PaymentProcessPage} />
                <Route path='/reservation/:id' component={ReservationDetailsPage} />
                <Route path='/mypage' component={AsyncMyPage} />
                <Route path='/booking' component={AsyncReservationLoginPage} />
                <Route path='/campaign' component={AsyncLastMinutePage} />
                <Route path='/minresa' component={AsyncReservationLoginPage} />
                <Route path='/minreise' component={AsyncReservationLoginPage} />
                <Route path='/matkani' component={AsyncReservationLoginPage} />
                <Route path='/error-test' component={AsyncErrorTestPage} />
                <Route component={NotFoundPage} />
              </Switch>
            </QueryParamProvider>
          </Router>
          <Toast />
        </ApiCacheProvider>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  state: state.locales
})
const mapDispatchToProps = dispatch => ({
  marketActions: bindActionCreators(marketActions, dispatch)
})

App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App)

export default App
