import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Box } from 'jsxstyle'
import moment from 'moment-timezone'
import { Icon, IconButton } from '../../components/icon'
import { List, ListItem } from '../../components/list'
import Currency from '../../containers/currency'
import Y from '../../y18n'
import Button from '../../components/button'
import { Dialog, DialogContent, DialogButtons } from '../../components/dialog'
import './reservation-summary.css'

const mapStateToProps = state => ({
  reservation: state.reservation.summary
})

const smallWidth = { sm: 'screen and (max-width: 992px)' }

class ReservationSummary extends Component {
  state = { show: {}, showDiscountDialog: {} }

  handleToggleDiscountDialog (index) {
    this.setState({
      ...this.state,
      showDiscountDialog: {
        ...this.state.showDiscountDialog,
        [index]: !this.state.showDiscountDialog[index]
      }
    })
  }

  renderTravelTime (arrival, departure) {
    const duration = moment.duration(moment(arrival).diff(moment(departure)))
    const hours = parseInt(duration.asHours(), 10)
    const minutes = parseInt(duration.asMinutes(), 10) - hours * 60
    return `${hours > 0 ? `${hours} h` : ''} ${
      minutes > 0 ? `${minutes} m` : ''
    }`
  }

  renderBus (bus, i) {
    return (
      <Box padding='0 .4687rem' key={i} style={{ paddingTop: 10 }}>
        <Box marginLeft='16px'>
          <Row>
            <h5>
              {moment
                .parseZone(bus.departureDateTime)
                .format('ddd D MMMM YYYY')}
            </h5>
          </Row>
          <p className='caption' style={{ marginTop: -8, marginBottom: 8 }}>
            {bus.airlineName}
          </p>
        </Box>

        <List key={i} className='no-odd flight-time'>
          <ListItem className='dep'>
            <p className='nomargin'>
              {moment.parseZone(bus.departureDateTime).format('HH:mm')},{' '}
              {bus.originAirport}
            </p>
          </ListItem>
          {bus.stops &&
            bus.stops.map(s => (
              <ListItem className='dep'>
                <p className='caption' style={{ margin: '.9375rem 0' }}>
                  {s.description}
                </p>
              </ListItem>
            ))}
          {/* <ListItem className='dur'>
            <p className='caption nomargin'>
              {Y`Travel time`}:{' '}
              {this.renderTravelTime(
                bus.arrivalDateTime,
                bus.departureDateTime
              )}
            </p>
          </ListItem> */}

          <Box marginLeft='16px' marginTop={bus.stops ? 0 : '17px'}>
            <Row>
              <h5>
                {moment
                  .parseZone(bus.arrivalDateTime)
                  .format('ddd D MMMM YYYY')}
              </h5>
            </Row>
            <p className='caption' style={{ marginTop: -8, marginBottom: 8 }}>
              {bus.airlineName}
            </p>
          </Box>

          <ListItem className='dep'>
            <p className='nomargin'>
              {moment.parseZone(bus.arrivalDateTime).format('HH:mm')},{' '}
              {bus.destinationAirport}
            </p>
          </ListItem>
        </List>
      </Box>
    )
  }

  renderFlight (flight, i) {
    return (
      <Box padding='0 .4687rem' key={i} style={{ paddingTop: 10 }}>
        <Row>
          <Icon
            style={
              flight.direction === 'Homeward' ? { transform: 'scaleX(-1)' } : {}
            }
          >
            flight_takeoff
          </Icon>
          <h5 style={{ marginLeft: '4px' }}>
            {moment
              .parseZone(flight.departureDateTime)
              .format('ddd D MMMM YYYY')}
          </h5>
        </Row>
        <p
          className='caption'
          style={{ marginLeft: 28, marginTop: -8, marginBottom: 8 }}
        >
          {flight.airlineName} / {flight.flightNumber}
        </p>

        <List key={i} className='no-odd flight-time'>
          <ListItem className='dep'>
            <p className='nomargin'>
              {moment.parseZone(flight.departureDateTime).format('HH:mm')},{' '}
              {flight.originAirport}
            </p>
          </ListItem>
          <ListItem className='dur'>
            <p className='caption nomargin'>
              {Y`Travel time`}:{' '}
              {this.renderTravelTime(
                flight.arrivalDateTime,
                flight.departureDateTime
              )}
            </p>
          </ListItem>
          <ListItem className='dep'>
            <p className='nomargin'>
              {moment.parseZone(flight.arrivalDateTime).format('HH:mm')},{' '}
              {flight.destinationAirport}
            </p>
          </ListItem>
        </List>
      </Box>
    )
  }

  renderFlightInfo (reservation) {
    if (!reservation.charter || !reservation.status === 'Cancelled') return null

    return (
      <div className='res-summary'>
        <h4>
          {reservation.transportationType === 'Bus'
            ? 'Transportinformation'
            : Y`Flight information`}
        </h4>
        <Row justifyContent='center'>
          <Col>
            {reservation.charter.flights
              .filter(flight => flight.direction === 'Outward')
              .map((flight, i) =>
                reservation.transportationType === 'Bus'
                  ? this.renderBus(flight, i)
                  : this.renderFlight(flight, i)
              )}
          </Col>
          <Col>
            {reservation.charter.flights
              .filter(flight => flight.direction === 'Homeward')
              .map((flight, i) =>
                reservation.transportationType === 'Bus'
                  ? this.renderBus(flight, i)
                  : this.renderFlight(flight, i)
              )}
          </Col>
        </Row>
      </div>
    )
  }

  renderHotelInfo (reservation) {
    if (!reservation.hotels.length || reservation.status === 'Cancelled')
      return null

    return (
      <div className='res-summary'>
        <h4>{Y`Destination and hotel`}</h4>
        <List className='dense'>
          <ListItem>
            <Row alignItems='center'>
              <Box
                component='h5'
                minWidth='150px'
                className='nomargin'
              >{Y`Destination`}</Box>
              <p className='nomargin'>
                {reservation.hotels[0].destinationDescription}
              </p>
            </Row>
          </ListItem>
          <ListItem>
            <Row alignItems='center'>
              <Box
                component='h5'
                minWidth='150px'
                className='nomargin'
              >{Y`Hotel`}</Box>
              <p className='nomargin'>{reservation.hotels[0].name}</p>
            </Row>
          </ListItem>
          <ListItem>
            <Row alignItems='center'>
              <Box component='h5' minWidth='150px' className='nomargin'>
                Check in / Check out
              </Box>
              <p className='nomargin' cy-id={`hotelinfo-dates`}>
                {moment
                  .parseZone(reservation.hotels[0].checkinDate)
                  .format('YYYY-MM-DD')}{' '}
                /{' '}
                {moment
                  .parseZone(reservation.hotels[0].checkoutDate)
                  .format('YYYY-MM-DD')}
              </p>
            </Row>
          </ListItem>
          <ListItem>
            <Row
              alignItems={
                reservation.packages.length > 1 ? 'flex-start' : 'center'
              }
            >
              <Box
                component='h5'
                minWidth='150px'
                className='nomargin'
              >{Y`Package`}</Box>
              <Box>
                {reservation.packages.map(p => (
                  <p
                    className='nomargin'
                    key={p.id}
                    style={{
                      paddingBottom:
                        reservation.packages.length > 1 ? '.9375rem' : 0
                    }}
                  >
                    {p.description} - {p.shortInfo}
                  </p>
                ))}
              </Box>
            </Row>
          </ListItem>
        </List>
      </div>
    )
  }

  renderNotExistingAddon (reservation, key, title, description, redirectTo) {
    return (
      <div key={key}>
        <Row
          padding='.4687rem'
          className='res-summary-box'
          marginTop='.4687rem'
        >
          <Box component='h5' flex='1' className='nomargin'>
            {title}
          </Box>
          {reservation.isEditable && (
            <Button
              link
              to={redirectTo}
              style={{ fontSize: '0.8rem', color: '#47a404' }}
            >
              {Y`Add`}
            </Button>
          )}
        </Row>
      </div>
    )
  }

  renderAddon (
    reservation,
    key,
    title,
    articles,
    redirectTo = null
  ) {
    return (
      <div key={key}>
        <Row
          padding='.4687rem'
          className='res-summary-box'
          marginTop='.4687rem'
        >
          <Box flex='1'>
            <h5 className='nomargin'>{title}</h5>
            <Button
              link
              style={{ fontSize: '0.8rem', color: '#858585' }}
              onClick={() =>
                this.setState({
                  ...this.state,
                  show: { ...this.state.show, [key]: !this.state.show[key] }
                })
              }
            >
              {this.state.show[key] ? Y`Hide details` : Y`Show details`}
            </Button>

            {reservation.isEditable && redirectTo && (
              <Button
                link
                to={redirectTo}
                style={{ fontSize: '0.8rem', marginLeft: '.9375rem' }}
              >
                {Y`Edit`}
              </Button>
            )}
          </Box>
          <Box textAlign='right'>
            <h5 className='nomargin'>
              {articles.map(a => a.price).reduce((a, b) => a + b) === 0 ? (
                Y`Included`
              ) : (
                <Currency>
                  {articles.map(a => a.price).reduce((a, b) => a + b)}
                </Currency>
              )}
            </h5>
          </Box>
        </Row>
        {this.state.show[key] && (
          <List className='res-summary-list dense no-odd border'>
            {articles.map((a, i) => {
              let traveler = reservation.travelers.find(
                t => t.id === a.travelerId
              )

              const isChild = traveler.age !== null && traveler.age < 18

              return (
                <ListItem key={i} style={i === 0 ? { borderTop: 'none' } : {}}>
                  <Row justifyContent='space-between'>
                    <Box>
                      <Box component='p' className='nomargin'>
                        {/^\s+$/.test(a.travelerName)
                          ? isChild
                            ? Y`Child` + ' ' + traveler.id
                            : Y`Adult` + ' ' + traveler.id
                          : a.travelerName}
                      </Box>
                      <Box component='p' className='nomargin grey'>
                        {a.description}{' '}
                        {a.numberOfDays
                          ? ` - ${a.numberOfDays} ${Y`days`}`
                          : ''}
                      </Box>
                    </Box>
                    <Box maxWidth='85px' textAlign='right' className='nomargin'>
                      <Box component='p'>
                        {a.price === 0 ? (
                          Y`Included`
                        ) : (
                          <Currency>{a.price}</Currency>
                        )}
                      </Box>
                      {a.depositFee > 0 && (
                        <Box component='p' className='caption'>
                          {`${Y`Including`} ${Y`deposit`}`}{' '}
                          <Currency>{a.depositFee}</Currency>
                        </Box>
                      )}
                    </Box>
                  </Row>
                </ListItem>
              )
            })}
          </List>
        )}
      </div>
    )
  }

  renderDestinationArticles (reservation, key) {
    const destArticles = [...reservation.destinationArticles]

    let groups = destArticles.reduce((groups, article) => {
      let group = groups[article.addonType]
      if (!group) group = groups[article.addonType] = []
      group.push(article)
      return groups
    }, {})

    let groupedArticles = []
    const articles = groups[key]

    if (articles) {

      let title = ''

      let redirectTo = null
      if (key === 'SkiRental') {
        title = Y`SkiRental`
        redirectTo = `/travelers/${reservation.id}#SkiRental`
      } else if (key === 'SkiSchool') {
        title = Y`SkiSchool`
        redirectTo = `/travelers/${reservation.id}#SkiSchool`
      } else if (key === 'SkiPass') {
        title = Y`SkiPass`
        redirectTo = `/travelers/${reservation.id}#SkiPass`
      } else if (key === 'Excursion') {
        title = Y`Excursions`
        redirectTo = `/addons/${reservation.id}#Excursion`
      } else if (key === 'Hiking') {
        title = Y`Hikes`
        redirectTo = `/travelers/${reservation.id}#Hiking`
      }

      groupedArticles.push(
        this.renderAddon(
          reservation,
          key,
          title,
          articles,
          redirectTo
        )
      )
    }

    return groupedArticles
  }

  renderTransfer (reservation) {
    if (!reservation.transfers.length) return null

    return this.renderAddon(
      reservation,
      'Transfer',
      Y`Transfer`,
      reservation.transfers,
      `/travelers/${reservation.id}#Transfer`
    )
  }

  renderSkiLuggages (reservation) {
    if (!reservation.skiLuggages.length) return

    return this.renderAddon(
      reservation,
      'SkiLuggage',
      Y`Ski luggage`,
      reservation.skiLuggages,
      `/travelers/${reservation.id}#SkiLuggage`
    )
  }

  renderGolfBags (reservation) {
    if (!reservation.golfBags.length) return null

    return this.renderAddon(
      reservation,
      'GolfBag',
      'Golfbag',
      reservation.golfBags,
      `/travelers/${reservation.id}#GolfBag`
    )
  }

  renderFlightTaxes (reservation) {
    if (!reservation.flightTaxes.length) return null
    return this.renderAddon(
      reservation,
      'FlightTax',
      reservation.flightTaxes[0].description,
      reservation.flightTaxes
    )
  }

  renderFlightMeals (reservation) {
    if (!reservation.flightMeals.length) return null

    return this.renderAddon(
      reservation,
      'FlightMeal',
      Y`Meals and business class on the flight`,
      reservation.flightMeals,
      `/travelers/${reservation.id}#FlightMeal`
    )
  }

  renderCancellationInsurances (reservation) {
    if (!reservation.cancellationInsurances.length) return null
    return this.renderAddon(
      reservation,
      'CancellationInsurance',
      Y`Cancellation Insurance`,
      reservation.cancellationInsurances
    )
  }

  renderFullRefundInsurances (reservation) {
    if (!reservation.fullRefundInsurances.length) return null
    return this.renderAddon(
      reservation,
      'Full Refund Insurance',
      Y`Full Refund Insurance`,
      reservation.fullRefundInsurances
    )
  }

  renderHotelSupplements (reservation) {
    if (!reservation.hotelSupplements.length) return null
    const redirect = reservation.hotelSupplements.some(s => s.price > 0) ? `/addons/${reservation.id}#Halfboard` : null
    return this.renderAddon(
      reservation,
      'HotelSupplemenets',
      Y`Hotel supplements`,
      reservation.hotelSupplements,
      redirect
    )
  }

  renderTravelInsurances (reservation) {
    if (!reservation.travelInsurances.length) return null

    return this.renderAddon(
      reservation,
      'TravelInsurance',
      Y`Travel insurance`,
      reservation.travelInsurances,
      `/addons/${reservation.id}#TravelInsurance`
    )
  }

  renderPackageInfo (reservation) {
    let packages = reservation.packages
    if (!packages || !packages.length) return null

    return packages.map(pkge => {
      const hasDiscount =
        pkge.ordinaryPrice - pkge.price !== 0 ||
        reservation.travelers
          .filter(traveler => traveler.packageId === pkge.id)
          .some(
            traveler => traveler.offerDiscount > 0 || traveler.bedDiscount > 0
          )

      let key = pkge.id

      return (
        <div key={key}>
          <Row
            alignItems='flex-start'
            padding='.4687rem'
            className='res-summary-box'
            marginTop='.4687rem'
          >
            <Box flex='1'>
              <h5 className='nomargin'>{pkge.description}</h5>
              <p className='caption nomargin'>{pkge.shortInfo}</p>
              <Button
                link
                style={{ fontSize: '0.8rem', color: '#858585' }}
                onClick={() =>
                  this.setState({
                    ...this.state,
                    show: { ...this.state.show, [key]: !this.state.show[key] }
                  })
                }
              >
                {this.state.show[key] ? Y`Hide details` : Y`Show details`}
              </Button>
            </Box>
            <Row>
              {hasDiscount && (
                <Box textAlign='right'>
                  <p className='nomargin caption bold'>
                    {Y`Total package discount`}:
                  </p>
                  <h5 className='nomargin warn'>
                    <Currency>
                      {pkge.price -
                        pkge.ordinaryPrice -
                        reservation.travelers
                          .filter(t => t.packageId === pkge.id)
                          .map(t => t.bedDiscount)
                          .reduce((a, b) => a + b)}
                    </Currency>
                  </h5>
                </Box>
              )}
              {hasDiscount && (
                <Box margin='0 .9375rem' borderRight='1px solid #ebebeb' />
              )}
              <Box textAlign='right'>
                <p className='nomargin caption bold'>
                  {hasDiscount ? `${Y`Your price`}:` : ''}
                </p>
                <h5 className='nomargin'>
                  <Currency>{pkge.price}</Currency>
                </h5>
              </Box>
            </Row>
          </Row>
          {this.state.show[key] && (
            <List className='res-summary-list dense no-odd border'>
              {reservation.travelers
                .filter(traveler => traveler.packageId === pkge.id)
                .map((traveler, i) => {
                  const travelerHasDiscount =
                    traveler.ordinaryPackagePrice !== traveler.packagePrice ||
                    traveler.offerDiscount > 0 ||
                    traveler.bedDiscount > 0

                  const travelerHasOfferCodeWithoutDiscount =
                    reservation.offerCode && traveler.offerDiscount <= 0

                  const isChild = traveler.age !== null && traveler.age < 18

                  return (
                    <ListItem
                      key={i}
                      style={i === 0 ? { borderTop: 'none' } : {}}
                    >
                      <Row justifyContent='space-between'>
                        <Box>
                          <Box component='p' className='nomargin'>
                            {!traveler.firstName
                              ? isChild
                                ? Y`Child` + ' ' + traveler.id
                                : Y`Adult` + ' ' + traveler.id
                              : `${traveler.firstName} ${traveler.lastName}`}
                          </Box>
                          <Box component='p' className='nomargin grey'>
                            {traveler.roomDescription}
                          </Box>
                        </Box>
                        {travelerHasDiscount && (
                          <Box
                            textAlign='right'
                            flex='1'
                            maxWidth='75px'
                            marginRight='.9375rem'
                          >
                            <Box
                              component='p'
                              className='nomargin caption'
                            >{Y`Total discount`}</Box>
                            <Row>
                              <Box component='p' className='nomargin warn'>
                                <Currency>
                                  {traveler.packagePrice +
                                    traveler.offerDiscount -
                                    traveler.ordinaryPackagePrice -
                                    traveler.offerDiscount -
                                    traveler.bedDiscount}
                                </Currency>
                              </Box>
                              <IconButton
                                size='1rem'
                                color='rgb(8, 154, 208)'
                                onClick={() =>
                                  this.handleToggleDiscountDialog(i)
                                }
                              >
                                info
                              </IconButton>
                            </Row>
                            <Box margin='0 .9375rem' />
                            {this.state.showDiscountDialog[i] && (
                              <Dialog
                                title={`${Y`Discounts for`} ${
                                  !traveler.firstName
                                    ? isChild
                                      ? Y`Child` + ' ' + traveler.id
                                      : Y`Adult` + ' ' + traveler.id
                                    : `${traveler.firstName} ${traveler.lastName}`
                                }`}
                                show
                                onClose={() =>
                                  this.handleToggleDiscountDialog(i)
                                }
                              >
                                <DialogContent>
                                  <List className='dense no-odd'>
                                    <Box
                                      mediaQueries={smallWidth}
                                      smDisplay='none'
                                    >
                                      <ListItem>
                                        <Row justifyContent='space-between'>
                                          <Box
                                            component='h5'
                                            flex={1}
                                            className='nomargin'
                                          >{Y`Type`}</Box>
                                          <Box
                                            component='h5'
                                            flex='1'
                                            className='nomargin'
                                            textAlign='right'
                                          >{Y`Ordinary price`}</Box>
                                          <Box
                                            component='h5'
                                            flex='1'
                                            className='nomargin'
                                            textAlign='right'
                                          >{Y`Price`}</Box>
                                          <Box
                                            component='h5'
                                            flex='1'
                                            className='nomargin'
                                            textAlign='right'
                                          >{Y`Discount`}</Box>
                                        </Row>
                                      </ListItem>
                                    </Box>
                                    {traveler.ordinaryPackagePrice !==
                                      traveler.packagePrice +
                                        traveler.offerDiscount && (
                                      <ListItem
                                        style={{
                                          borderBottom:
                                            '1px solid rgb(238, 238, 238)'
                                        }}
                                      >
                                        <Box
                                          mediaQueries={smallWidth}
                                          smDisplay='block'
                                          display='none'
                                        >
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Type`}</Box>
                                            <p className='nomargin'>{Y`Price reduction package`}</p>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Ordinary price`}</Box>
                                            <Box
                                              component='p'
                                              textDecoration='line-through'
                                              className='nomargin'
                                            >
                                              <Currency>
                                                {traveler.ordinaryPackagePrice}
                                              </Currency>
                                            </Box>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Price`}</Box>
                                            <p className='nomargin warn'>
                                              {' '}
                                              <Currency>
                                                {traveler.packagePrice}
                                              </Currency>
                                            </p>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Discount`}</Box>
                                            <p className='nomargin warn'>
                                              <Currency>
                                                {traveler.ordinaryPackagePrice -
                                                  traveler.packagePrice}
                                              </Currency>
                                            </p>
                                          </Row>
                                        </Box>

                                        <Row
                                          justifyContent='space-between'
                                          mediaQueries={smallWidth}
                                          smDisplay='none'
                                        >
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          >
                                            {Y`Price reduction package`}
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                            textAlign='right'
                                            textDecoration='line-through'
                                          >
                                            <Currency>
                                              {traveler.ordinaryPackagePrice}
                                            </Currency>
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            textAlign='right'
                                            className='nomargin warn'
                                          >
                                            <Currency>
                                              {traveler.packagePrice}
                                            </Currency>
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            textAlign='right'
                                            className='nomargin warn'
                                          >
                                            <Currency>
                                              {traveler.ordinaryPackagePrice -
                                                traveler.packagePrice}
                                            </Currency>
                                          </Box>
                                        </Row>
                                      </ListItem>
                                    )}
                                    {traveler.offerDiscount > 0 && (
                                      <ListItem>
                                        <Box
                                          mediaQueries={smallWidth}
                                          smDisplay='block'
                                          display='none'
                                        >
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Type`}</Box>
                                            <p className='nomargin'>
                                              {reservation.offerDescription}
                                            </p>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Ordinary price`}</Box>
                                            <p className='nomargin' />
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Price`}</Box>
                                            <p className='nomargin' />
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Discount`}</Box>
                                            <p className='nomargin warn'>
                                              <Currency>
                                                {traveler.offerDiscount}
                                              </Currency>
                                            </p>
                                          </Row>
                                        </Box>

                                        <Row
                                          justifyContent='space-between'
                                          mediaQueries={smallWidth}
                                          smDisplay='none'
                                        >
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          >
                                            {reservation.offerDescription}
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          />
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          />
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin warn'
                                            textAlign='right'
                                          >
                                            <Currency>
                                              {traveler.offerDiscount}
                                            </Currency>
                                          </Box>
                                        </Row>
                                      </ListItem>
                                    )}
                                    {travelerHasOfferCodeWithoutDiscount && (
                                      <ListItem>
                                        <Box
                                          mediaQueries={smallWidth}
                                          smDisplay='block'
                                          display='none'
                                        >
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Type`}</Box>
                                            <p className='nomargin'>
                                              {Y`Offer code '${reservation.offerCode}' does not apply to already discounted trips`}
                                            </p>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Discount`}</Box>
                                            <p className='nomargin'>
                                              <Currency>
                                                {traveler.offerDiscount}
                                              </Currency>
                                            </p>
                                          </Row>
                                        </Box>

                                        <Row
                                          justifyContent='space-between'
                                          mediaQueries={smallWidth}
                                          smDisplay='none'
                                        >
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          >
                                            {Y`Offer code '${reservation.offerCode}' does not apply to already discounted trips`}
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                            textAlign='right'
                                          >
                                            <Currency>
                                              {traveler.offerDiscount}
                                            </Currency>
                                          </Box>
                                        </Row>
                                      </ListItem>
                                    )}
                                    {traveler.bedDiscount > 0 && (
                                      <ListItem>
                                        <Box
                                          mediaQueries={smallWidth}
                                          smDisplay='block'
                                          display='none'
                                        >
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Type`}</Box>
                                            <p className='nomargin'>{Y`Extra bed discount`}</p>
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Ordinary price`}</Box>
                                            <p className='nomargin' />
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Price`}</Box>
                                            <p className='nomargin' />
                                          </Row>
                                          <Row
                                            alignItems='center'
                                            marginBottom='.4687rem'
                                          >
                                            <Box
                                              component='h5'
                                              minWidth='100px'
                                              className='nomargin'
                                            >{Y`Discount`}</Box>
                                            <p className='nomargin warn'>
                                              <Currency>
                                                {traveler.bedDiscount}
                                              </Currency>
                                            </p>
                                          </Row>
                                        </Box>

                                        <Row
                                          justifyContent='space-between'
                                          mediaQueries={smallWidth}
                                          smDisplay='none'
                                        >
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          >
                                            {Y`Extra bed discount`}
                                          </Box>
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          />
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin'
                                          />
                                          <Box
                                            component='p'
                                            flex='1'
                                            className='nomargin warn'
                                            textAlign='right'
                                          >
                                            <Currency>
                                              {traveler.bedDiscount}
                                            </Currency>
                                          </Box>
                                        </Row>
                                      </ListItem>
                                    )}
                                  </List>
                                </DialogContent>
                                <DialogButtons>
                                  <Row
                                    justifyContent='space-between'
                                    width='100%'
                                    alignItems='center'
                                  >
                                    <Button
                                      small
                                      onClick={() =>
                                        this.handleToggleDiscountDialog(i)
                                      }
                                    >{Y`Close`}</Button>
                                    <Row alignItems='center'>
                                      <h5 className='nomargin'>{Y`Total discount`}</h5>
                                      <Box
                                        marginLeft='.9375rem'
                                        className='nomargin price warn'
                                      >
                                        <Currency>
                                          {(traveler.packagePrice +
                                            traveler.offerDiscount -
                                            traveler.ordinaryPackagePrice -
                                            traveler.offerDiscount -
                                            traveler.bedDiscount) *
                                            -1}
                                        </Currency>
                                      </Box>
                                    </Row>
                                  </Row>
                                </DialogButtons>
                              </Dialog>
                            )}
                          </Box>
                        )}
                        <Box maxWidth='75px' textAlign='right'>
                          {travelerHasDiscount && (
                            <Box
                              component='p'
                              textDecoration='line-through'
                              className='nomargin caption'
                            >
                              <Currency>
                                {traveler.ordinaryPackagePrice +
                                  traveler.bedDiscount}
                              </Currency>
                            </Box>
                          )}
                          <Box
                            component='p'
                            className={`nomargin ${
                              travelerHasDiscount ? 'warn' : ''
                            }`}
                          >
                            <Currency>{traveler.packagePrice}</Currency>
                          </Box>
                        </Box>
                      </Row>
                    </ListItem>
                  )
                })}
            </List>
          )}
        </div>
      )
    })
  }

  renderOffer (reservation) {
    if (!reservation.offerCode) return null
    let key = 'Offer'

    return (
      <div>
        <Row
          padding='.4687rem'
          className='res-summary-box'
          marginTop='.4687rem'
        >
          <Box flex='1'>
            <h5 className='nomargin'>{reservation.offerDescription}</h5>
            <p className='caption nomargin'>{reservation.offerCode}</p>
          </Box>
          <Box textAlign='right'>
            <h5 className='nomargin'>
              <Currency>{reservation.package.offerDiscount}</Currency>
            </h5>
            <Button
              link
              style={{ fontSize: '0.9rem' }}
              onClick={() =>
                this.setState({
                  ...this.state,
                  show: { ...this.state.show, [key]: !this.state.show[key] }
                })
              }
            >
              {this.state.show[key] ? Y`Hide details` : Y`Show details`}
            </Button>
          </Box>
        </Row>
        {this.state.show[key] && (
          <List className='res-summary-list dense no-odd border'>
            {reservation.travelers.map((traveler, i) => {
              const isChild = traveler.age !== null && traveler.age < 18
              return (
                <ListItem key={i} style={i === 0 ? { borderTop: 'none' } : {}}>
                  <Row justifyContent='space-between'>
                    <Box component='p' className='nomargin'>
                      {!traveler.firstName
                        ? isChild
                          ? Y`Child` + ' ' + traveler.id
                          : Y`Adult` + ' ' + traveler.id
                        : `${traveler.firstName} ${traveler.lastName}`}
                    </Box>
                    <Box
                      component='p'
                      maxWidth='75px'
                      textAlign='right'
                      className='nomargin'
                    >
                      <Currency>{traveler.offerDiscount}</Currency>
                    </Box>
                  </Row>
                </ListItem>
              )
            })}
          </List>
        )}
      </div>
    )
  }

  renderTravelers (reservation) {
    if (reservation.status === 'Cancelled') return null

    return (
      <div className='res-summary'>
        <h4>{Y`Travelers`}</h4>
        <List className='dense'>
          <Box mediaQueries={smallWidth} smDisplay='none'>
            <ListItem>
              <Row justifyContent='space-between'>
                <Box component='h5' flex='1' className='nomargin'>
                  {Y`Name`}
                </Box>
                <Box
                  component='h5'
                  flex='1'
                  className='nomargin'
                >{Y`Room`}</Box>
                <Box
                  component='h5'
                  flex='1'
                  className='nomargin'
                >{Y`Type traveler`}</Box>
                <Box
                  component='h5'
                  flex='1'
                  className='nomargin'
                >{Y`Birth date`}</Box>
                <Box
                  component='h5'
                  flex='1'
                  className='nomargin'
                >{Y`Gender`}</Box>
              </Row>
            </ListItem>
          </Box>
          {reservation.travelers.map((traveler, i) => {
            const isChild = traveler.age !== null && traveler.age < 18
            return (
              <ListItem key={i} style={i === 0 ? { borderTop: 'none' } : {}}>
                <Box mediaQueries={smallWidth} smDisplay='block' display='none'>
                  <Row alignItems='center' marginBottom='.4687rem'>
                    <Box
                      component='h5'
                      minWidth='100px'
                      className='nomargin'
                    >{Y`Name`}</Box>
                    <p className='nomargin'>
                      {!traveler.firstName
                        ? isChild
                          ? Y`Child` + ' ' + traveler.id
                          : Y`Adult` + ' ' + traveler.id
                        : `${traveler.firstName} ${traveler.lastName}`}
                    </p>
                  </Row>
                  <Row alignItems='center' marginBottom='.4687rem'>
                    <Box
                      component='h5'
                      minWidth='100px'
                      className='nomargin'
                    >{Y`Room`}</Box>
                    <p className='nomargin'>{traveler.roomDescription}</p>
                  </Row>
                  <Row alignItems='center' marginBottom='.4687rem'>
                    <Box
                      component='h5'
                      minWidth='100px'
                      className='nomargin'
                    >{Y`Type`}</Box>
                    <p className='nomargin'>{isChild ? Y`Child` : Y`Adult`}</p>
                  </Row>
                  <Row alignItems='center' marginBottom='.4687rem'>
                    <Box
                      component='h5'
                      minWidth='100px'
                      className='nomargin'
                    >{Y`Birth date`}</Box>
                    <p className='nomargin'>
                      {moment.utc(traveler.birthDate).format('YYYY-MM-DD')}
                    </p>
                  </Row>
                  <Row alignItems='center'>
                    <Box
                      component='h5'
                      minWidth='100px'
                      className='nomargin'
                    >{Y`Gender`}</Box>
                    <p className='nomargin'>
                      {traveler.genderCode === 'M'
                        ? isChild
                          ? Y`Boy`
                          : Y`Male`
                        : isChild
                        ? Y`Girl`
                        : Y`Female`}
                    </p>
                  </Row>
                </Box>

                <Row
                  justifyContent='space-between'
                  mediaQueries={smallWidth}
                  smDisplay='none'
                >
                  <Box
                    component='p'
                    smFontWeight='bold'
                    flex='1'
                    className='nomargin'
                    mediaQueries={smallWidth}
                  >
                    {!traveler.firstName
                      ? isChild
                        ? Y`Child` + ' ' + traveler.id
                        : Y`Adult` + ' ' + traveler.id
                      : `${traveler.firstName} ${traveler.lastName}`}
                  </Box>
                  <Box component='p' flex='1' className='nomargin'>
                    {traveler.roomDescription}
                  </Box>
                  <Box component='p' flex='1' className='nomargin'>
                    {isChild ? Y`Child` : Y`Adult`}
                  </Box>
                  <Box component='p' flex='1' className='nomargin'>
                    {moment.utc(traveler.birthDate).format('YYYY-MM-DD')}
                  </Box>
                  <Box component='p' flex='1' className='nomargin'>
                    {traveler.genderCode === 'M'
                      ? isChild
                        ? Y`Boy`
                        : Y`Male`
                      : isChild
                      ? Y`Girl`
                      : Y`Female`}
                  </Box>
                </Row>
              </ListItem>
            )
          })}
        </List>
      </div>
    )
  }

  renderTotalPrice (reservation) {
    if (!this.props.showTotalPrice) return null

    return (
      <Box textAlign='right'>
        <p className='nomargin caption'>{Y`Total price`}</p>
        <Box className='price nomargin green'>
          <Currency>{reservation.price}</Currency>
        </Box>
      </Box>
    )
  }

  renderPriceAdjustments (reservation) {
    if (!reservation.priceAdjustments.length) return null

    return reservation.priceAdjustments.map((pa, i) => (
      <div key={i}>
        <Row
          padding='.4687rem'
          className='res-summary-box'
          marginTop='.4687rem'
        >
          <Box flex='1'>
            <h5 className='nomargin'>{Y`Price adjustment`}</h5>
            <p className='caption nomargin'>{pa.text}</p>
          </Box>
          <Box textAlign='right'>
            <h5 className={`nomargin ${pa.amount < 0 ? 'warn' : ''}`}>
              <Currency>{pa.amount}</Currency>
            </h5>
          </Box>
        </Row>
      </div>
    ))
  }

  renderPriceSpecification (reservation) {
    if (reservation.status === 'Cancelled') return null

    return (
      <div id='res-summary-details' className='res-summary-details'>
        <Box
          component='h3'
          textAlign='center'
          margin='0'
          marginBottom='.9375rem'
        >{Y`Manage booking`}</Box>
        <Row alignItems='center' justifyContent='space-between'>
          {reservation.status === 'Confirmed' ? (
            <h5>{Y`Add or change your booking`}</h5>
          ) : (
            <h4>{Y`Price specification`}</h4>
          )}
          {this.renderTotalPrice(reservation)}
        </Row>
        {this.renderPackageInfo(reservation)}
        {this.renderPriceAdjustments(reservation)}
        {this.renderHotelSupplements(reservation)}
        {this.renderFlightTaxes(reservation)}
        {this.renderTransfer(reservation)}
        {this.renderFlightMeals(reservation)}
        {this.renderDestinationArticles(reservation, 'Hiking')}
        {this.renderDestinationArticles(reservation, 'SkiPass')}
        {this.renderSkiLuggages(reservation)}
        {this.renderGolfBags(reservation)}
        {this.renderDestinationArticles(reservation, 'SkiRental')}
        {this.renderDestinationArticles(reservation, 'SkiSchool')}
        {this.renderDestinationArticles(reservation, 'Excursion')}
        {this.renderCancellationInsurances(reservation)}
        {this.renderFullRefundInsurances(reservation)}
        {this.renderTravelInsurances(reservation)}
        {this.renderAvailableArticleTypes(reservation)}
        {/* {this.renderOffer(reservation)} */}
      </div>
    )
  }

  renderAvailableArticleTypes (reservation) {
    if (
      !reservation.availableArticleTypes ||
      !reservation.availableArticleTypes.length
    )
      return null

    let articles = []

    reservation.availableArticleTypes.forEach(t => {
      if (t === 'SkiRental') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'SkiRental',
            Y`SkiRental`,
            Y`SkiRental text`,
            `/travelers/${reservation.id}#SkiRental`
          )
        )
      }
      if (t === 'SkiSchool') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'SkiSchool',
            Y`SkiSchool`,
            Y`SkiSchool text`,
            `/travelers/${reservation.id}#SkiSchool`
          )
        )
      }
      if (t === 'SkiPass') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'SkiPass',
            Y`SkiPass`,
            Y`SkiPass text`,
            `/travelers/${reservation.id}#SkiPass`
          )
        )
      }
      if (t === 'Excursion') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'Excursion',
            Y`Excursions`,
            Y`Excursions text`,
            `/addons/${reservation.id}#Excursion`
          )
        )
      }
      if (t === 'Hiking') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'Hiking',
            Y`Hikes`,
            Y`Hikes text`,
            `/travelers/${reservation.id}#Hiking`
          )
        )
      }
      if (t === 'Transfer') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'Transfer',
            Y`Transfer`,
            Y`Transfer text`,
            `/travelers/${reservation.id}#Transfer`
          )
        )
      }
      if (t === 'SkiLuggage') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'SkiLuggage',
            Y`Ski luggage`,
            Y`Ski luggage text`,
            `/travelers/${reservation.id}#SkiLuggage`
          )
        )
      }
      if (t === 'GolfBag') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'GolfBag',
            'Golfbag',
            Y`Golfbag text`,
            `/travelers/${reservation.id}#GolfBag`
          )
        )
      }
      if (t === 'FlightMeal') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'FlightMeal',
            Y`Meals and business class on the flight`,
            Y`Meals and business class on the flight text`,
            `/travelers/${reservation.id}#FlightMeal`
          )
        )
      }
      if (t === 'TravelInsurance') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'TravelInsurance',
            Y`Travel insurance`,
            Y`Travel insurance text`,
            `/addons/${reservation.id}#TravelInsurance`
          )
        )
      }
      if (t === 'HotelSupplement') {
        articles.push(
          this.renderNotExistingAddon(
            reservation,
            'HotelSupplement',
            Y`Hotel supplements`,
            null,
            `/addons/${reservation.id}#Halfboard`
          )
        )
      }
    })

    return articles
  }

  render () {
    const { reservation, showTravelers } = this.props
    if (!reservation) return null

    return (
      <div>
        <Row
          justifyContent='space-between'
          mediaQueries={smallWidth}
          smFlexDirection='column'
        >
          {this.renderFlightInfo(reservation)}
          {this.renderHotelInfo(reservation)}
        </Row>
        {showTravelers && this.renderTravelers(reservation)}
        {this.renderPriceSpecification(reservation)}
      </div>
    )
  }
}

ReservationSummary = connect(mapStateToProps)(ReservationSummary)
export default ReservationSummary
