import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment-timezone'
import Select from '../../components/select'
import * as reservationActions from '../../store/ducks/reservation'

const mapStateToProps = state => ({
  travelType: state.reservation.search.travelType,
  airportId: state.reservation.search.airportId,
  destinationId: state.reservation.search.destinationId,
  charterPeriodId: state.reservation.search.charterPeriodId,
  quikbook: state.reservation.quikbook
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch)
})

class CharterPeriodSelect extends Component {
  renderDisabledSelect () {
    return <Select hideLabel label='Week' disabled value='' />
  }

  render () {
    let destinationGroups = null

    if (this.props.travelType === 'Hotel') {
      if (!this.props.destinationId) return this.renderDisabledSelect()
      destinationGroups = this.props.quikbook.destinationGroups
    } else {
      if (!this.props.quikbook.airports || !this.props.airportId || !this.props.destinationId) {
        return this.renderDisabledSelect()
      }

      const selectedAirport = this.props.quikbook.airports.find(a => a.airportId === this.props.airportId)
      if (!selectedAirport) return this.renderDisabledSelect()
      destinationGroups = selectedAirport.destinationGroups
    }

    if (!destinationGroups) return this.renderDisabledSelect()

    const selectedDestinationGroup = destinationGroups.find(dg =>
      dg.destinations.some(d => d.id === this.props.destinationId)
    )
    if (!selectedDestinationGroup) return this.renderDisabledSelect()
    const selectedDestination = selectedDestinationGroup.destinations.find(d => d.id === this.props.destinationId)
    if (!selectedDestination) return this.renderDisabledSelect()

    return (
      <Select
        label='Week'
        cy-id='week-select'
        hideLabel
        onChange={value => this.props.reservationActions.setSearchValue('charterPeriodId', value)}
        value={this.props.charterPeriodId}
      >
        {selectedDestination.periods.map((cp, i) => {
          let out = moment.parseZone(cp.out)
          let home = moment.parseZone(cp.home)
          let week = home.week()
          let id = `${out.format()};${home.format()}`

          return (
            <option key={i} value={id}>{`V.${week} (${out.format('D MMM')} - ${home.format('D MMM YYYY')})`}</option>
          )
        })}
      </Select>
    )
  }
}

CharterPeriodSelect = connect(
  mapStateToProps,
  mapDispatchToProps
)(CharterPeriodSelect)
export default CharterPeriodSelect
