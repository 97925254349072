import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const fullRefundInsurancesSlice = createSlice({
  name: 'fullRefundInsurances',
  initialState,
  reducers: {
    QUERY_FULL_REFUND_INSURANCE: state => {
      state.loading = true
      state.error = null
    },
    QUERY_FULL_REFUND_INSURANCE_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_FULL_REFUND_INSURANCE: (state, action) => {
      state.items = state.items.map((traveler, i) =>
        traveler.id === action.payload.travelerId
          ? {
              ...traveler,
              fullRefundInsurance: {
                ...traveler.fullRefundInsurance,
                reserved: action.payload.reserved
              }
            }
          : traveler
      )
    }
  }
})

export const {
  QUERY_FULL_REFUND_INSURANCE,
  QUERY_FULL_REFUND_INSURANCE_SUCCESS,
  SET_FULL_REFUND_INSURANCE
} = fullRefundInsurancesSlice.actions

export default fullRefundInsurancesSlice.reducer

// thunks
export const queryFullRefundInsurance = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/fullRefundInsurance', { reservationId })
      .then(res => dispatch(QUERY_FULL_REFUND_INSURANCE_SUCCESS(res.data)))
  }
}

export const submitAddFullRefundInsurance = command => {
  return dispatch => {
    dispatch(SET_FULL_REFUND_INSURANCE({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/addFullRefundInsurance', command)
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}

export const submitRemoveFullRefundInsurance = command => {
  return dispatch => {
    dispatch(SET_FULL_REFUND_INSURANCE({ ...command, reserved: false }))
    let cmd = api.sendCommand(
      'travelBooking/removeFullRefundInsurance',
      command
    )
    cmd.then(() => dispatch(queryReservationSummary(command.reservationId)))
    return cmd
  }
}
