import { createSlice } from '@reduxjs/toolkit'
import api from '../api'

const initialState = {
  items: []
}

const generalFacilitiesSlice = createSlice({
  name: 'generalFacilities',
  initialState,
  reducers: {
    QUERY_FACILITIES: (state, action) => {
      state.items = action.payload
    }
  }
})

export const { QUERY_FACILITIES } = generalFacilitiesSlice.actions

export default generalFacilitiesSlice.reducer


// thunks
export const queryFacilities = () => {
  return dispatch => {
    api.query('travelBooking/generalFacilities').then(res => dispatch(QUERY_FACILITIES(res.data)))
  }
}
