import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { setSearchValue } from './reservation'

const initialState = {
  error: null,
  loading: false,
  selectedCharterPeriod: {},
  data: []
}

const charterPeriodsSlice = createSlice({
  name: 'charterPeriods',
  initialState,
  reducers: {
    QUERY_CHARTER_PERIODS: (state) => {
      state.loading = true
      state.error = null
    },
    QUERY_CHARTER_PERIODS_SUCCESS: (state, action) => {
      state.loading = false
      state.data = action.payload
    }
  }
})

export const { QUERY_CHARTER_PERIODS, QUERY_CHARTER_PERIODS_SUCCESS } = charterPeriodsSlice.actions

export default charterPeriodsSlice.reducer


// thunks
export const queryCharterPeriods = (
  destinationId,
  charterTypeId,
  airportId,
  dontSetDefault = false,
  travelType
) => {
  return (dispatch, getState) => {
    const reservation = getState().reservation
    dispatch(QUERY_CHARTER_PERIODS())
    let query
    if (travelType === 'Hotel' || reservation.search.travelType === 'Hotel') {
      query = api.query('hotel/charterPeriod/periods', {
        destinationId,
        charterTypeId: charterTypeId || reservation.search.charterTypeId
      })
    } else if (
      travelType === 'Flight' ||
      reservation.search.travelType === 'Flight'
    ) {
      query = api.query('flightDeparture/charter/airportPeriods', {
        airportId,
        destinationId,
        charterTypeId: charterTypeId || reservation.search.charterTypeId
      })
    } else {
      query = api.query('flightDeparture/charter/periods', {
        airportId,
        destinationId,
        charterTypeId: charterTypeId || reservation.search.charterTypeId
      })
    }

    query.then(res => {
      res.data.forEach(p => (p.id = `${p.out};${p.home}`))
      dispatch(QUERY_CHARTER_PERIODS_SUCCESS(res.data))

      if (res.data.length && !dontSetDefault && !reservation.reservationId) {
        dispatch(setPeriod(res.data[0].id))
      }
    })

    return query
  }
}

export const setPeriod = periodId => {
  return dispatch => {
    dispatch(setSearchValue('charterPeriodId', periodId))
  }
}
