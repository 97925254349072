import React from 'react'
import PropTypes from 'prop-types'
import './input.css'

const Input = ({
  label = null,
  hideLabel = false,
  placeholder = null,
  autoFocus = false,
  required = false,
  onChange,
  style,
  ...rest
}) => {
  let id = Math.random().toString(36).substring(7)
  let requiredLabel = required ? ' *' : ''
  let inputLabel = label
    ? <label className={hideLabel ? 'hidden' : ''} htmlFor={id}>
      {label}{requiredLabel}
    </label>
    : null

  const handleChange = e => {
    if (typeof onChange === 'function') onChange(e.target.value, e)
  }

  return (
    <div className='input' style={style}>
      {inputLabel}
      <input
        id={id}
        placeholder={placeholder}
        autoFocus={autoFocus}
        required={required}
        onChange={handleChange}
        {...rest}
      />
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.any,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  style: PropTypes.any,
  onChange: PropTypes.func
}

export default Input
