import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import * as cancellationInsuranceActions from '../../../store/ducks/cancellation-insurances'
import isAdult from '../../../utils/is-adult'

const CancellationInsuranceList = () => {
  const dispatch = useDispatch()
  const reservationId = useSelector(state => state.reservation.reservationId)
  const reservationSummary = useSelector(state => state.reservation.summary)
  const cancellationInsurance = useSelector(
    state => state.cancellationInsurances.items
  )

  const prevProps = useRef(null)

  useEffect(() => {
    prevProps.current = reservationSummary
    dispatch(
      cancellationInsuranceActions.queryCancellationInsurance(reservationId)
    )
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (
      !prevProps.current ||
      prevProps.current.price !== reservationSummary.price
    ) {
      dispatch(
        cancellationInsuranceActions.queryCancellationInsurance(reservationId)
      )
      prevProps.current = reservationSummary
    }
    // eslint-disable-next-line
  }, [reservationSummary])

  const handleSelectCacellationInsurance = (value, travelerId, index) => {
    if (value) {
      dispatch(
        cancellationInsuranceActions.submitAddCancellationInsurance({
          reservationId,
          travelerId
        })
      )
      if (index === 0) {
        cancellationInsurance
          .filter((t, i) => i !== 0 && !t.cancellationInsurance.reserved)
          .forEach(traveler => {
            dispatch(
              cancellationInsuranceActions.submitAddCancellationInsurance({
                reservationId,
                travelerId: traveler.id
              })
            )
          })
      }
    } else
      dispatch(
        cancellationInsuranceActions.submitRemoveCancellationInsurance({
          reservationId,
          travelerId
        })
      )
  }

  const CancellationInsuranceText = ({ marketId }) => {
    return (
      <p>
        {Y`Cancellation Insurance text`}{' '}
        <>
          {marketId === 'SE' ? (
            <>
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.erv.se/globalassets/gemensamt/sweden/villkor-m.m/avbestallningsskydd/produktfaktablad/produktfaktablad_avbestallningsskydd.pdf'
              >{Y`Cancellation Insurance product text link`}</a>{' '}
              {Y`or`}{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.erv.se/globalassets/gemensamt/sweden/villkor-m.m/avbestallningsskydd/villkor/villkor_avbestallningsskydd.pdf'
              >{Y`Cancellation Insurance text link`}</a>
              .
            </>
          ) : marketId === 'NO' ? (
            <>
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.erv.se/globalassets/gemensamt/sweden/villkor-m.m/avbestallningsskydd/produktfaktablad/produktfaktablad_avbestallningsskydd_no.pdf'
              >{Y`Cancellation Insurance product text link`}</a>{' '}
              {Y`and`}{' '}
              <a
                rel='noopener noreferrer'
                target='_blank'
                href='https://www.erv.se/globalassets/gemensamt/sweden/villkor-m.m/avbestallningsskydd/villkor/villkor_avbestallningsskydd_no.pdf'
              >{Y`Cancellation Insurance text link`}</a>
              .
            </>
          ) : (
            ''
          )}
        </>
      </p>
    )
  }

  if (!cancellationInsurance.length) return null
  if (!reservationSummary) return null

  return (
    <Card id='CancellationInsurance'>
      <CardHeader>
        <CardTitle>{Y`Cancellation Insurance`}</CardTitle>
        <CancellationInsuranceText marketId={reservationSummary.marketId} />
      </CardHeader>
      <List className='no-odd border'>
        {cancellationInsurance.map((traveler, i) => (
          <ListItem key={traveler.id}>
            <Row justifyContent='space-between' alignItems='center'>
              <div>
                <Row width='100%'>
                  <h3 className='nomargin'>
                    {/^\s+$/.test(traveler.name)
                      ? isAdult(traveler.age)
                        ? Y`Adult` + ' ' + traveler.id
                        : Y`Child` + ' ' + traveler.id
                      : traveler.name}
                    -{' '}
                  </h3>
                  <p style={{ margin: '0px 5px' }}>
                    {' '}
                    <Currency>
                      {traveler.cancellationInsurance.priceAmount}
                    </Currency>
                  </p>
                </Row>
                <p className='caption'>
                  {traveler.cancellationInsurance.description}
                </p>
              </div>

              <Switch
                disabled={traveler.cancellationInsurance.blocked}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor='#b4b4b4'
                onChange={value =>
                  handleSelectCacellationInsurance(value, traveler.id, i)
                }
                checked={traveler.cancellationInsurance.reserved}
                onColor='#47a404'
                id='normal-switch'
                height={22}
                width={48}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              />
            </Row>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

export default CancellationInsuranceList
