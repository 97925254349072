import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../icon'
import './checkbox.css'

const Checkbox = ({ name, value = false, disabled = false, label = '', onChange, ...rest }) => {
  let id = Math.random()
    .toString(36)
    .substring(7)

  const handleOnChange = e => {
    if (typeof onChange === 'function') onChange(e.target.checked)
  }

  return (
    <div className='checkbox' {...rest}>
      <input
        checked={value}
        disabled={disabled}
        onChange={handleOnChange}
        type='checkbox'
        id={`${id}`}
        name={`${name}`}
      />
      <label htmlFor={`${id}`}>
        <Icon size='30px' color={value ? '#47a404' : '#969696'}>
          {value ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
        {label}
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any
}

export default Checkbox
