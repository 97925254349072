import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Row, Box, Col } from 'jsxstyle'
import Button from '../../../components/button'
import { Icon } from '../../../components/icon'
import { List, ListItem, ListItemBox } from '../../../components/list'
import Currency from '../../../containers/currency'
import RoomInfoDialog from './rooms-info-dialog'
import Y from '../../../y18n'
import { submitSelectProposal } from '../../../store/ducks/reservation'

const mapStateToProps = state => ({
  market: state.market.market,
  reservationId: state.reservation.reservationId,
  reservation: state.reservation.summary,
  roomProposalsState: state.roomProposal.item,
  searchInfo: state.roomProposal.searchInfo
})

const initialState = { showDialog: false, rooms: [], selectedRooms: [] }

class RoomList extends Component {
  state = initialState

  componentDidMount () {
    if (this.props.reservation && this.props.reservation.hotels.length) {
      this.setState({
        ...this.state,
        selectedRooms: this.props.reservation.hotels[
          this.props.reservation.hotels.length - 1
        ].rooms.map(r => r.categoryId)
      })
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.reservation && !this.props.reservation) {
      this.setState({
        ...this.state,
        selectedRooms: nextProps.reservation.hotels[
          nextProps.reservation.hotels.length - 1
        ].rooms.map(r => r.categoryId)
      })
    }
  }

  handleSelectRoom (e, rooms) {
    if (
      e &&
      (e.target.classList.contains('link') ||
        e.target.classList.contains('thumb'))
    )
      return

    this.setState({ ...this.state, selectedRooms: rooms.map(r => r.id) })

    let command = {
      reservationId: this.props.reservationId,
      packageId: this.props.roomProposalsState.package.id,
      hotel: {
        charterPeriodId: this.props.roomProposalsState.hotel.charterPeriodId,
        rooms: rooms.map(room => {
          return {
            id: room.id,
            travelerIds: room.travelerIds
          }
        })
      }
    }
    if (this.props.roomProposalsState.flightCharter) {
      command.flightCharter = {
        id: this.props.roomProposalsState.flightCharter.id
      }
    }
    this.props.dispatch(submitSelectProposal(command)).then(() => {
      document
        .querySelector('#next')
        .scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  }

  handleOpenDialog (e, rooms) {
    this.setState({ ...this.state, showDialog: true, rooms: rooms })
  }

  handleCloseDialog () {
    return new Promise((resolve, reject) => {
      this.setState({ ...this.state, showDialog: false, rooms: [] }, () =>
        resolve()
      )
    })
  }

  getGroupedRooms (rooms) {
    let groupedRooms = []
    rooms.forEach(room => {
      let existingIndex = groupedRooms.findIndex(x => x.id === room.id)
      if (existingIndex > -1) {
        groupedRooms[existingIndex].number =
          groupedRooms[existingIndex].number + 1
      } else {
        groupedRooms.push({ ...room, number: 1 })
      }
    })

    return groupedRooms
  }

  renderMoreButton (rooms, hasImages) {
    return (
      <Button
        style={{ marginLeft: 36, marginTop: 15 }}
        type='button'
        link
        onClick={e => this.handleOpenDialog(e, rooms)}
      >
        {hasImages
          ? Y`View images and read more`
          : rooms.length > 1
          ? Y`More about this combination`
          : Y`Read more about this room`}{' '}
        »
      </Button>
    )
  }

  renderRoomDescriptions (rooms) {
    const groupedRooms = this.getGroupedRooms(rooms)
    const isSameTypeOfRoom = groupedRooms.length === 1

    return (
      <div>
        <h5>
          {groupedRooms.map(
            (room, i) =>
              `${
                !isSameTypeOfRoom || room.number > 1 ? `${room.number} x ` : ''
              } ${room.description || room.id}${
                room.squareMeter ? ` - ${room.squareMeter}m²` : ''
              }${i < groupedRooms.length - 1 ? ', ' : ''}`
          )}
        </h5>
        {groupedRooms.map(room => (
          <p
            key={room.id}
            style={{
              fontSize: '.85rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3,
              lineHeight: 1.3,
              maxHeight: '53.04px',
              maxWidth: '700px'
            }}
          >
            {room.information}
          </p>
        ))}
      </div>
    )
  }

  isSelected (rc) {
    let selectedRooms = [...this.state.selectedRooms]
    let rcRooms = [...rc.rooms.map(r => r.id)]
    selectedRooms.sort()
    rcRooms.sort()

    if (selectedRooms.length !== rcRooms.length) return false
    for (let i = selectedRooms.length; i--; ) {
      if (selectedRooms[i] !== rcRooms[i]) return false
    }
    return true
  }

  render () {
    const { roomProposalsState, searchInfo, reservation, market } = this.props
    if (!roomProposalsState || !reservation) return null
    const roomProposals = roomProposalsState.hotel.roomProposals
    const pckge = roomProposalsState.package.description
    const summaryElement = document.querySelector('.summary')

    return (
      <div style={{ marginTop: '.9375rem', position: 'relative' }}>
        <div
          id='rooms'
          style={{
            position: 'absolute',
            top: `-${summaryElement ? summaryElement.offsetHeight : 88}px`
          }}
        />
        <Box
          component='h2'
          mediaQueries={{ sm: 'screen and (max-width: 910px)' }}
          smFontSize='1.25rem'
          textAlign='center'
          marginBottom='.9375rem'
        >
          {Y`Rooms on`} {roomProposalsState.hotel.info.name}
        </Box>
        <List>
          {roomProposals.map((rc, i) => {
            const hasImages =
              rc.rooms.length > 0 &&
              rc.rooms[0].imageUrls &&
              rc.rooms.filter(r => r.imageUrls.length > 0).length > 0

            return (
              <ListItem
                className='clickable'
                cy-id='room-item'
                key={i}
                onClick={e => this.handleSelectRoom(e, rc.rooms)}
              >
                <ListItemBox noPadding>
                  <Row alignItems='flex-start'>
                    {this.isSelected(rc) ? (
                      <Icon color='#47a404' size={30}>
                        check_circle
                      </Icon>
                    ) : (
                      <Icon color='rgb(194,194,194)' size={30}>
                        radio_button_unchecked
                      </Icon>
                    )}
                    <Box flex={1} marginLeft='.4rem'>
                      {this.renderRoomDescriptions(rc.rooms)}
                    </Box>
                    {hasImages && !this.props.isSmallLayout && (
                      <Box>
                        <img
                          alt={
                            rc.rooms.find(r => r.imageUrls.length > 0)
                              .description
                          }
                          className='thumb'
                          onClick={e => this.handleOpenDialog(e, rc.rooms)}
                          src={`${
                            rc.rooms.find(r => r.imageUrls.length > 0)
                              .imageUrls[0]
                          }&w=320`}
                        />
                      </Box>
                    )}
                    <Col alignItems='flex-end' paddingLeft='.4687rem'>
                      <Box
                        component='p'
                        className='subtitle nomargin'
                        whiteSpace='nowrap'
                        paddingTop='4px'
                      >
                        {market.id === 'NO' ? (
                          <React.Fragment>
                            {Y`Price`}{' '}
                            <Box component='span' textTransform='lowercase'>
                              {pckge}
                            </Box>
                          </React.Fragment>
                        ) : (
                          `${pckge}-${Y`price`}`
                        )}
                      </Box>
                      <p
                        className={`price ${
                          rc.price === rc.ordinaryPrice ? 'green' : 'warn'
                        }`}
                      >
                        <Currency>{rc.price}</Currency>
                      </p>
                      {rc.price !== rc.ordinaryPrice && (
                        <p className='old-price'>
                          <Currency>{rc.ordinaryPrice}</Currency>
                        </p>
                      )}
                    </Col>
                  </Row>
                  {this.renderMoreButton(rc.rooms, hasImages)}
                </ListItemBox>
              </ListItem>
            )
          })}
        </List>
        {this.state.showDialog && (
          <RoomInfoDialog
            rooms={this.state.rooms}
            travelers={[].concat(...searchInfo.rooms.map(r => r.travelers))}
            show={this.state.showDialog}
            onClose={() => this.handleCloseDialog()}
            onSelectClose={() => {
              let rooms = [...this.state.rooms]
              this.handleCloseDialog().then(() => {
                this.handleSelectRoom(null, rooms)
              })
            }}
          />
        )}
      </div>
    )
  }
}

RoomList = connect(mapStateToProps)(RoomList)
export default withRouter(RoomList)
