import { createSlice } from '@reduxjs/toolkit'
import api from '../api'
import { queryReservationSummary } from './reservation'

const initialState = {
  error: null,
  loading: false,
  items: []
}

const transfersSlice = createSlice({
  name: 'transfers',
  initialState,
  reducers: {
    QUERY_TRANSFER: state => {
      state.loading = true
      state.error = null
    },
    QUERY_TRANSFER_SUCCESS: (state, action) => {
      state.loading = false
      state.items = action.payload
    },
    SET_TRANSFER: (state, action) => {
      state.items = state.items.map((traveler, i) =>
        traveler.id === action.payload.travelerId
          ? {
              ...traveler,
              transfers: traveler.transfers.map(transfer =>
                transfer.transferId === action.payload.transferId
                  ? { ...transfer, reserved: action.payload.reserved }
                  : { ...transfer, reserved: false }
              )
            }
          : traveler
      )
    }
  }
})

export const { QUERY_TRANSFER, QUERY_TRANSFER_SUCCESS, SET_TRANSFER } =
  transfersSlice.actions

export default transfersSlice.reducer

// thunks
export const queryTransfers = reservationId => {
  return dispatch => {
    api
      .query('travelbooking/transfer', { reservationId })
      .then(res => dispatch(QUERY_TRANSFER_SUCCESS(res.data)))
  }
}

export const submitSelectTransfer = command => {
  return dispatch => {
    dispatch(SET_TRANSFER({ ...command, reserved: true }))
    let cmd = api.sendCommand('travelBooking/selectTransfer', command)
    cmd.then(() =>
      dispatch(
        queryReservationSummary(command.reservationId)
      )
    )
    return cmd
  }
}

export const submitRemoveTransfer = command => {
  return dispatch => {
    dispatch(SET_TRANSFER({ ...command, reserved: false }))
    let cmd = api.sendCommand('travelBooking/removeTransfer', command)
    cmd.then(() =>
      dispatch(
        queryReservationSummary(command.reservationId)
      )
    )
    return cmd
  }
}
