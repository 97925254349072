import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import Select from '../../components/select'
import { setSearchValue } from '../../store/ducks/reservation'

const DestinationSelect = () => {
  const dispatch = useDispatch()
  const travelType = useSelector(state => state.reservation.search.travelType)
  const airportId = useSelector(state => state.reservation.search.airportId)
  const destinationId = useSelector(
    state => state.reservation.search.destinationId
  )
  const quikbook = useSelector(state => state.reservation.quikbook)

  const renderDisabledSelect = () => (
    <Select hideLabel label='Destination' disabled value='' />
  )

  let destinationGroups = null

  if (travelType === 'Hotel') {
    destinationGroups = quikbook.destinationGroups
  } else {
    if (!quikbook.airports || !airportId) {
      return renderDisabledSelect()
    }
    const selectedAirport = quikbook.airports.find(
      a => a.airportId === airportId
    )
    if (!selectedAirport) {
      return renderDisabledSelect()
    }
    destinationGroups = selectedAirport.destinationGroups
  }

  if (!destinationGroups) {
    return renderDisabledSelect()
  }

  const noDestinationsAvailible =
    destinationGroups && destinationGroups.length === 0

  return (
    <Select
      label='Destination'
      cy-id='destination-select'
      hideLabel
      value={destinationId}
      onChange={value => {
        const destGroup = destinationGroups.find(dg =>
          dg.destinations.some(d => d.id === value)
        )
        const dest = destGroup.destinations.find(d => d.id === value)
        const firstPeriod = dest.periods[0]

        dispatch(setSearchValue('destinationId', value))
        dispatch(
          setSearchValue(
            'charterPeriodId',
            `${moment.parseZone(firstPeriod.out).format()};${moment
              .parseZone(firstPeriod.home)
              .format()}`
          )
        )
      }}
    >
      {!noDestinationsAvailible ? (
        destinationGroups.map(country => (
          <optgroup key={country.name} label={country.name}>
            {country.destinations.map(destination => (
              <option key={destination.id} value={destination.id}>
                {destination.name}
              </option>
            ))}
          </optgroup>
        ))
      ) : (
        <option value=''>No destinations found</option>
      )}
    </Select>
  )
}

export default DestinationSelect
