import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Icon } from '../../components/icon'
import Y from '../../y18n'
import * as reservationActions from '../../store/ducks/reservation'
import * as charterTypeActions from '../../store/ducks/charter-types'

const mapStateToProps = state => ({
  travelType: state.reservation.search.travelType
})

const mapDispatchToProps = dispatch => ({
  reservationActions: bindActionCreators(reservationActions, dispatch),
  charterTypeActions: bindActionCreators(charterTypeActions, dispatch)
})

class TravelTypeButtons extends Component {
  handleClick (id) {
    this.props.reservationActions.setSearchValue('travelType', id)
    this.props.charterTypeActions.queryCharterTypes(id)
  }

  render () {
    const { travelType } = this.props
    const travelTypes = [
      { id: 'Charter', description: Y`Package trip`, icon: 'star' },
      { id: 'Flight', description: Y`Only flight`, icon: 'flight' },
      { id: 'Hotel', description: Y`Only hotel`, icon: 'hotel' }
    ]

    return (
      <div className='travel-type'>
        {travelTypes.map(tt => (
          <button key={tt.id} className={travelType === tt.id ? 'active' : ''} onClick={() => this.handleClick(tt.id)}>
            <Icon size='.9375rem'>{tt.icon}</Icon> {tt.description}
          </button>
        ))}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TravelTypeButtons)
