import React from 'react'
import PropTypes from 'prop-types'
import Icon from './icon'
import './icon-button.css'

export const IconButton = ({ onClick, size, color, children, label, ...rest }) => {
  return (
    <button className='icon-button' onClick={onClick} {...rest}>
      <Icon size={size} color={color}>
        {children}
      </Icon>
      {label}
    </button>
  )
}

IconButton.propTypes = {
  label: PropTypes.any,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default Icon
