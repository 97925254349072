import React from 'react'
import PropTypes from 'prop-types'
import './callouts.css'

const Callouts = ({ src = [], max = null }) => (
  <div className='callout'>{src && src.filter((_, i) => (max ? i < max : true)).map(co => <p key={co}>{co}</p>)}</div>
)

Callouts.propTypes = {
  src: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default Callouts
