import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { Row, Box } from 'jsxstyle'
import Button from '../../../components/button'
import {
  Card,
  CardTitle,
  CardSubtitle,
  CardHeader
} from '../../../components/card'
import './flight-card.css'
import { Icon, IconSvg } from '../../../components/icon'
import Y from '../../../y18n'
import Currency from '../../../containers/currency'
import FlightInfo from '../flight-info'
import { submitSelectProposal } from '../../../store/ducks/reservation'

const mapStateToProps = state => ({
  reservationId: state.reservation.reservationId
})

const smallWidth = 992

class FlightCard extends Component {
  state = { isSmallLayout: null }

  componentDidMount () {
    this.setLayout(true)
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount () {
    window.clearTimeout(this.timeout)
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleWindowResize = e => this.setLayout()

  setLayout (init) {
    window.clearTimeout(this.timeout)

    this.timeout = window.setTimeout(
      () => {
        let windowWidth = window.innerWidth
        if (
          (windowWidth <= smallWidth && this.state.isSmallLayout === true) ||
          (windowWidth > smallWidth && this.state.isSmallLayout === false)
        ) {
        } else {
          if (windowWidth <= smallWidth) {
            this.setState({ isSmallLayout: true })
          } else {
            this.setState({ isSmallLayout: false })
          }
        }
      },
      init ? 0 : 20
    )
  }

  travelLength (flightCharter) {
    const firstFlight = moment
      .parseZone(flightCharter.outwardFlights[0].departureDateTime)
      .startOf('day')
    const lastFlight = moment
      .parseZone(
        flightCharter.homewardFlights[flightCharter.homewardFlights.length - 1]
          .arrivalDateTime
      )
      .startOf('day')
    return (
      lastFlight.startOf('day').diff(firstFlight.startOf('day'), 'days') + 1
    ) // =1
  }

  handleSelectProposal = proposal => {
    const command = {
      packageId: proposal.package.id,
      reservationId: this.props.reservationId,
      flightCharter: {
        id: proposal.flightCharter.id,
        travelerIds: proposal.flightCharter.tickets.map(t => t.travelerId)
      }
    }
    this.props
      .dispatch(submitSelectProposal(command))
      .then(() =>
        this.props.history.push(`/travelers/${this.props.reservationId}`)
      )
  }

  render () {
    const { proposal } = this.props
    if (!proposal.flightCharter) return null

    return (
      <Card
        onClick={() =>
          this.state.isSmallLayout && this.handleSelectProposal(proposal)
        }
        className='flight-card'
      >
        <CardHeader>
          <Row alignItems='center' justifyContent='space-between'>
            <Box>
              <CardTitle>{proposal.destination.description}</CardTitle>
              <CardSubtitle style={{ display: 'flex', alignItems: 'center' }}>
                {proposal.flightCharter.outwardFlights[0].originAirport}{' '}
                <Icon>arrow_right_alt</Icon> {proposal.destination.description},{' '}
                {this.travelLength(proposal.flightCharter)} {Y`days`}
              </CardSubtitle>
            </Box>
            <FlightInfo flight={proposal.flightCharter} />
          </Row>
        </CardHeader>
        <Row
          alignItems='center'
          className='grey-bg'
          padding='.4687rem .9375rem .4687rem .9375rem'
        >
          <Row
            mediaQueries={{ lg: 'screen and (min-width: 992px)' }}
            justifyContent='space-between'
            alignItems='center'
            flex={1}
            lgFlexDirection='column'
            lgAlignItems='flex-start'
          >
            <p className='subtitle nomargin'>{Y`Only flight`}</p>
            <Box
              component='p'
              textAlign='right'
              className={`price ${
                proposal.ordinaryPrice !== proposal.price ? 'warn' : 'green'
              } nomargin`}
            >
              <Currency>{proposal.price}</Currency>
            </Box>
          </Row>
          <IconSvg
            className='next'
            color='rgba(133, 133, 133, .25)'
            style={{
              marginRight: '-10px'
            }}
          >
            chevron-right
          </IconSvg>
          <Button onClick={() => this.handleSelectProposal(proposal)} primary>
            {Y`Select`} »
          </Button>
        </Row>

        {/* <Row
          className='grey-bg'
          alignItems='center'
          justifyContent='space-between'
          padding='.4687rem .9375rem .4687rem .9375rem'
        >
          <p className='subtitle nomargin'>{Y`Only flight`}</p>
          <Row alignItems='center'>
            <Box
              component='p'
              mediaQueries={{ sm: 'screen and (max-width: 992px)' }}
              smTextAlign='right'
              className={`price ${proposal.ordinaryPrice !== proposal.price ? 'warn' : 'green'} nomargin`}
            >
              <Currency>{proposal.price}</Currency>
            </Box>
            <IconSvg
              className='next'
              color='rgba(133, 133, 133, .25)'
              style={{
                marginRight: '-10px'
              }}
            >
              chevron-right
            </IconSvg>
            <Button primary>{Y`Select`} »</Button>
          </Row>
        </Row> */}
      </Card>
    )
  }
}

FlightCard = connect(mapStateToProps)(FlightCard)
export default withRouter(FlightCard)
