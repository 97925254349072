import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row } from 'jsxstyle'
import Switch from 'react-switch'
import { Card, CardTitle, CardHeader } from '../../../components/card'
import { List, ListItem } from '../../../components/list'
import Currency from '../../../containers/currency'
import Y from '../../../y18n'
import * as fullRefundInsuranceActions from '../../../store/ducks/full-refund-insurances'

const FullRefundInsuranceList = () => {
  const dispatch = useDispatch()
  const reservationId = useSelector(state => state.reservation.reservationId)
  const fullRefundInsurance = useSelector(
    state => state.fullRefundInsurances.items
  )

  useEffect(() => {
    dispatch(fullRefundInsuranceActions.queryFullRefundInsurance(reservationId))
    // eslint-disable-next-line
  }, [])

  const handleSelectFullRefundInsurance = (value, travelerId, index) => {
    if (value) {
      dispatch(
        fullRefundInsuranceActions.submitAddFullRefundInsurance({
          reservationId,
          travelerId
        })
      )
      if (index === 0) {
        fullRefundInsurance
          .filter((t, i) => i !== 0 && !t.fullRefundInsurance.reserved)
          .forEach(traveler => {
            dispatch(
              fullRefundInsuranceActions.submitAddFullRefundInsurance({
                reservationId,
                travelerId: traveler.id
              })
            )
          })
      }
    } else
      dispatch(
        fullRefundInsuranceActions.submitRemoveFullRefundInsurance({
          reservationId,
          travelerId
        })
      )
  }
  if (!fullRefundInsurance.length) return null

  return (
    <Card id='FullRefundInsurance'>
      <CardHeader>
        <CardTitle>{Y`Full Refund Insurance`}</CardTitle>
        <p>{Y`Full Refund Insurance text`}</p>
      </CardHeader>
      <List className='no-odd border'>
        {fullRefundInsurance.map((traveler, i) => (
          <ListItem key={traveler.id}>
            <Row justifyContent='space-between' alignItems='center'>
              <div>
                <Row width='100%'>
                  <h3 className='nomargin'>
                    {/^\s+$/.test(traveler.name)
                      ? traveler.age !== null && traveler.age < 18
                        ? Y`Child` + ' ' + traveler.id
                        : Y`Adult` + ' ' + traveler.id
                      : traveler.name}
                    -{' '}
                  </h3>
                  <p style={{ margin: '0px 5px' }}>
                    {' '}
                    <Currency>
                      {traveler.fullRefundInsurance.priceAmount}
                    </Currency>
                  </p>
                </Row>
                <p className='caption'>
                  {traveler.fullRefundInsurance.description}
                </p>
              </div>

              <Switch
                disabled={traveler.fullRefundInsurance.blocked}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor='#b4b4b4'
                onChange={value =>
                  handleSelectFullRefundInsurance(value, traveler.id, i)
                }
                checked={traveler.fullRefundInsurance.reserved}
                onColor='#47a404'
                id='normal-switch'
                height={22}
                width={48}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              />
            </Row>
          </ListItem>
        ))}
      </List>
    </Card>
  )
}

export default FullRefundInsuranceList
