import React from 'react'
import PropTypes from 'prop-types'
import './list-item.css'

export const ListItem = ({ children, className = '', ...rest }) => {
  return (
    <li className={`list-item ${className}`} {...rest}>
      {children}
    </li>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default ListItem
